export const min_state_inst = [
    {
        Ministry: "STATE HOUSE",
        State_Departments: "State House",
        Institutions: "None"
    },
    {
        Ministry: "OFFICE OF THE DEPUTY PRESIDENT",
        State_Departments: "State Department for Cabinet Affairs Office of the Deputy President",
        Institutions: "None"
    },
    {
        Ministry: "OFFICE OF THE DEPUTY PRESIDENT",
        State_Departments: "State Department for Devolution Office of the Deputy President",
        Institutions: "None"
    },
    {
        Ministry: "OFFICE OF THE PRIME CABINET SECRETARY & MINISTRY OF FOREIGN AND DIASPORA AFFAIRS",
        State_Departments: "STATE DEPARTMENT FOR FOREIGN AFFAIRS",
        Institutions: "Kenya Foreign Service Institute"
    },
    {
        Ministry: "OFFICE OF THE PRIME CABINET SECRETARY & MINISTRY OF FOREIGN AND DIASPORA AFFAIRS",
        State_Departments: "STATE DEPARTMENT FOR FOREIGN AFFAIRS",
        Institutions: "None"
    },
    {
        Ministry: "OFFICE OF THE PRIME CABINET SECRETARY & MINISTRY OF FOREIGN AND DIASPORA AFFAIRS",
        State_Departments: "STATE DEPARTMENT FOR DIASPORA AFFAIRS",
        Institutions: "None"
    },
    {
        Ministry: "OFFICE OF THE PRIME CABINET SECRETARY & MINISTRY OF FOREIGN AND DIASPORA AFFAIRS",
        State_Departments: "STATE DEPARTMENT FOR PARLIAMENTARY AFFAIRS",
        Institutions: "None"
    },
    {
        Ministry: "MINISTRY OF INTERIOR AND NATIONAL ADMINISTRATION",
        State_Departments: "STATE DEPARTMENT FOR INTERNAL SECURITY AND NATIONAL ADMINISTRATION",
        Institutions: "National Crime Research Centre"
    },
    {
        Ministry: "MINISTRY OF INTERIOR AND NATIONAL ADMINISTRATION",
        State_Departments: "STATE DEPARTMENT FOR INTERNAL SECURITY AND NATIONAL ADMINISTRATION",
        Institutions: "NGO Co-ordination Board"
    },
    {
        Ministry: "MINISTRY OF INTERIOR AND NATIONAL ADMINISTRATION",
        State_Departments: "STATE DEPARTMENT FOR INTERNAL SECURITY AND NATIONAL ADMINISTRATION",
        Institutions: "National Authority for the Campaign Against Alcohol and Drug Abuse (NACADA)"
    },
    {
        Ministry: "MINISTRY OF INTERIOR AND NATIONAL ADMINISTRATION",
        State_Departments: "STATE DEPARTMENT FOR INTERNAL SECURITY AND NATIONAL ADMINISTRATION",
        Institutions: "Private Security Regulatory Authority"
    },
    {
        Ministry: "MINISTRY OF INTERIOR AND NATIONAL ADMINISTRATION",
        State_Departments: "STATE DEPARTMENT FOR INTERNAL SECURITY AND NATIONAL ADMINISTRATION",
        Institutions: "Kenya Coast Guard Service"
    },
    {
        Ministry: "MINISTRY OF INTERIOR AND NATIONAL ADMINISTRATION",
        State_Departments: "STATE DEPARTMENT FOR INTERNAL SECURITY AND NATIONAL ADMINISTRATION",
        Institutions: "Prisons Enterprise Fund"
    },
    {
        Ministry: "MINISTRY OF INTERIOR AND NATIONAL ADMINISTRATION",
        State_Departments: "STATE DEPARTMENT FOR INTERNAL SECURITY AND NATIONAL ADMINISTRATION",
        Institutions: "National Cohesion and Integration Commission"
    },
    {
        Ministry: "MINISTRY OF INTERIOR AND NATIONAL ADMINISTRATION",
        State_Departments: "STATE DEPARTMENT FOR CORRECTIONAL SERVICES",
        Institutions: "None"
    },
    {
        Ministry: "MINISTRY OF INTERIOR AND NATIONAL ADMINISTRATION",
        State_Departments: "STATE DEPARTMENT FOR IMMIGRATION AND CITIZEN SERVICES",
        Institutions: "None"
    },
    {
        Ministry: "MINISTRY OF DEFENCE",
        State_Departments: "STATE DEPARTMENT FOR DEFENCE",
        Institutions: "National Defence University of Kenya;"
    },
    {
        Ministry: "MINISTRY OF DEFENCE",
        State_Departments: "STATE DEPARTMENT FOR DEFENCE",
        Institutions: "Kenya Ordinance Factory Corporation"
    },
    {
        Ministry: "MINISTRY OF DEFENCE",
        State_Departments: "STATE DEPARTMENT FOR DEFENCE",
        Institutions: "The Kenya Space Agency"
    },
    {
        Ministry: "MINISTRY OF DEFENCE",
        State_Departments: "STATE DEPARTMENT FOR DEFENCE",
        Institutions: "Kenya Meat Commission"
    },
    {
        Ministry: "MINISTRY OF DEFENCE",
        State_Departments: "STATE DEPARTMENT FOR DEFENCE",
        Institutions: "Kenya Shipyards Limited"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Central Bank of Kenya"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Kenya Revenue Authority"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Capital Markets Authority"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Competition Authority of Kenya"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Insurance Regulatory Authority"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Retirement Benefits Authority"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Unclaimed Financial Assets Authority"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Kenya Deposit Insurance Corporation"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Nairobi International Financial Centre"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Privatization Commission"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Public Procurement Regulatory Authority"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Kenya Reinsurance Corporation (K) Ltd"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Financial Reporting Centre"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Kenya Institute of Supplies Management"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Kenya Trade Network Agency"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Policy Holders Compensation Fund"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Local Authorities Provident Fund"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Consolidated Bank of Kenya"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "CBL Bancassurance Intermediary Limited (subsidiary of consolidated Bank)"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Development Bank of Kenya"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Kenya Post Office Savings Bank"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Industrial and Commercial Development Corporation"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Kenya Accountants and Secretaries National Examination Board (KASNEB)"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Registration of Certified Public Secretaries Board"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Institute of Certified Public Accountants"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Institute of Certified Investment and Financial Analysts"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "National Informal Sector Pensions Ltd"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Investor Compensation Fund Board"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Kenya Institute of Supplies Examination Board"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Kenya National Entrepreneurs Savings Trust "
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Public Service Superannuation Fund Board"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Registration of Certified Public Accountants Board"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Kenya National Assurance Company (under liquidation)"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "KENATCO TAXIS Kltd (under receivership)"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Fresh Pick Processors Ltd"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "National Lottery"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "THE NATIONAL TREASURY",
        Institutions: "Kenya Institute of Supplies Examination Board (KISEB)"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "STATE DEPARTMENT FOR ECONOMIC PLANNING",
        Institutions: "Kenya Institute of Public Policy Research and Analysis (KIPPRA)"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "STATE DEPARTMENT FOR ECONOMIC PLANNING",
        Institutions: "National Government Constituency Development Fund"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "STATE DEPARTMENT FOR ECONOMIC PLANNING",
        Institutions: "Kenya National Bureau of Statistics"
    },
    {
        Ministry: "THE NATIONAL TREASURY AND ECONOMIC PLANNING",
        State_Departments: "STATE DEPARTMENT FOR ECONOMIC PLANNING",
        Institutions: "National Co-ordinating Agency for Population and Development"
    },
    {
        Ministry: "MINISTRY OF PUBLIC SERVICE AND HUMAN CAPITAL DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC SERVICE",
        Institutions: "Kenya School of Government"
    },
    {
        Ministry: "MINISTRY OF PUBLIC SERVICE AND HUMAN CAPITAL DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC SERVICE",
        Institutions: "National Youth Service"
    },
    {
        Ministry: "MINISTRY OF PUBLIC SERVICE AND HUMAN CAPITAL DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC SERVICE",
        Institutions: "Institute of Human Resource Management"
    },
    {
        Ministry: "MINISTRY OF PUBLIC SERVICE AND HUMAN CAPITAL DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC SERVICE",
        Institutions: "Institute of Human Resource Management Professionals Examinations Board"
    },
    {
        Ministry: "MINISTRY OF PUBLIC SERVICE AND HUMAN CAPITAL DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR HUMAN CAPITAL DEVELOPMENT",
        Institutions: "None"
    },
    {
        Ministry: "MINISTRY OF ROADS AND TRANSPORT",
        State_Departments: "STATE DEPARTMENT FOR ROADS",
        Institutions: "Kenya Roads Board"
    },
    {
        Ministry: "MINISTRY OF ROADS AND TRANSPORT",
        State_Departments: "STATE DEPARTMENT FOR ROADS",
        Institutions: "Kenya National Highways Authority"
    },
    {
        Ministry: "MINISTRY OF ROADS AND TRANSPORT",
        State_Departments: "STATE DEPARTMENT FOR ROADS",
        Institutions: "Kenya Urban Roads Authority"
    },
    {
        Ministry: "MINISTRY OF ROADS AND TRANSPORT",
        State_Departments: "STATE DEPARTMENT FOR ROADS",
        Institutions: "Kenya Rural Roads Authority"
    },
    {
        Ministry: "MINISTRY OF ROADS AND TRANSPORT",
        State_Departments: "STATE DEPARTMENT FOR ROADS",
        Institutions: "Engineers Board of Kenya"
    },
    {
        Ministry: "MINISTRY OF ROADS AND TRANSPORT",
        State_Departments: "STATE DEPARTMENT FOR ROADS",
        Institutions: "Kenya Engineering Technologists Registration Board"
    },
    {
        Ministry: "MINISTRY OF ROADS AND TRANSPORT",
        State_Departments: "STATE DEPARTMENT FOR TRANSPORT",
        Institutions: "Kenya Railways Corporation"
    },
    {
        Ministry: "MINISTRY OF ROADS AND TRANSPORT",
        State_Departments: "STATE DEPARTMENT FOR TRANSPORT",
        Institutions: "LAPSSET Development Authority"
    },
    {
        Ministry: "MINISTRY OF ROADS AND TRANSPORT",
        State_Departments: "STATE DEPARTMENT FOR TRANSPORT",
        Institutions: "Kenya Airports Authority"
    },
    {
        Ministry: "MINISTRY OF ROADS AND TRANSPORT",
        State_Departments: "STATE DEPARTMENT FOR TRANSPORT",
        Institutions: "Kenya Civil Authority"
    },
    {
        Ministry: "MINISTRY OF ROADS AND TRANSPORT",
        State_Departments: "STATE DEPARTMENT FOR TRANSPORT",
        Institutions: "Kenya Ports Authority"
    },
    {
        Ministry: "MINISTRY OF ROADS AND TRANSPORT",
        State_Departments: "STATE DEPARTMENT FOR TRANSPORT",
        Institutions: "National Transport and Safety Authority"
    },
    {
        Ministry: "MINISTRY OF ROADS AND TRANSPORT",
        State_Departments: "STATE DEPARTMENT FOR TRANSPORT",
        Institutions: "The Nairobi Metropolitan Area Transport Authority"
    },
    {
        Ministry: "MINISTRY OF ROADS AND TRANSPORT",
        State_Departments: "STATE DEPARTMENT FOR TRANSPORT",
        Institutions: "The Railway City Development Authority"
    },
    {
        Ministry: "MINISTRY OF LANDS, PUBLIC WORKS, HOUSING AND URBAN DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR LANDS AND PHYSICAL PLANNING",
        Institutions: "Lands Limited (subsidiary of ADC)"
    },
    {
        Ministry: "MINISTRY OF LANDS, PUBLIC WORKS, HOUSING AND URBAN DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR LANDS AND PHYSICAL PLANNING",
        Institutions: "Land Surveyors Board"
    },
    {
        Ministry: "MINISTRY OF LANDS, PUBLIC WORKS, HOUSING AND URBAN DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR LANDS AND PHYSICAL PLANNING",
        Institutions: "Physical Planners Registration Board"
    },
    {
        Ministry: "MINISTRY OF LANDS, PUBLIC WORKS, HOUSING AND URBAN DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR HOUSING AND URBAN DEVELOPMENT",
        Institutions: "National Housing Corporation"
    },
    {
        Ministry: "MINISTRY OF LANDS, PUBLIC WORKS, HOUSING AND URBAN DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR HOUSING AND URBAN DEVELOPMENT",
        Institutions: "Estate Agents Registration Board"
    },
    {
        Ministry: "MINISTRY OF LANDS, PUBLIC WORKS, HOUSING AND URBAN DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR HOUSING AND URBAN DEVELOPMENT",
        Institutions: "Board of Registered Architects and Quantity Surveyors (BO- RAQS)"
    },
    {
        Ministry: "MINISTRY OF LANDS, PUBLIC WORKS, HOUSING AND URBAN DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC WORKS",
        Institutions: "National Construction Authority"
    },
    {
        Ministry: "MINISTRY OF LANDS, PUBLIC WORKS, HOUSING AND URBAN DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC WORKS",
        Institutions: "Building Surveyors Registration Board"
    },
    {
        Ministry: "MINISTRY OF INFORMATION, COMMUNICATIONS AND THE DIGITAL ECONOMY",
        State_Departments: "STATE DEPARTMENT FOR BROADCASTING AND TELECOMMUNICATIONS",
        Institutions: "Communications Authority of Kenya"
    },
    {
        Ministry: "MINISTRY OF INFORMATION, COMMUNICATIONS AND THE DIGITAL ECONOMY",
        State_Departments: "STATE DEPARTMENT FOR BROADCASTING AND TELECOMMUNICATIONS",
        Institutions: "Kenya Broadcasting Corporation"
    },
    {
        Ministry: "MINISTRY OF INFORMATION, COMMUNICATIONS AND THE DIGITAL ECONOMY",
        State_Departments: "STATE DEPARTMENT FOR BROADCASTING AND TELECOMMUNICATIONS",
        Institutions: "The Postal Corporation of Kenya"
    },
    {
        Ministry: "MINISTRY OF INFORMATION, COMMUNICATIONS AND THE DIGITAL ECONOMY",
        State_Departments: "STATE DEPARTMENT FOR BROADCASTING AND TELECOMMUNICATIONS",
        Institutions: "Media Council of Kenya"
    },
    {
        Ministry: "MINISTRY OF INFORMATION, COMMUNICATIONS AND THE DIGITAL ECONOMY",
        State_Departments: "STATE DEPARTMENT FOR BROADCASTING AND TELECOMMUNICATIONS",
        Institutions: "Kenya Institute of Mass Communication (KIMC)"
    },
    {
        Ministry: "MINISTRY OF INFORMATION, COMMUNICATIONS AND THE DIGITAL ECONOMY",
        State_Departments: "STATE DEPARTMENT FOR BROADCASTING AND TELECOMMUNICATIONS",
        Institutions: "Kenya Yearbook Editorial Board"
    },
    {
        Ministry: "MINISTRY OF INFORMATION, COMMUNICATIONS AND THE DIGITAL ECONOMY",
        State_Departments: "STATE DEPARTMENT FOR BROADCASTING AND TELECOMMUNICATIONS",
        Institutions: "Telkom Kenya Limited"
    },
    {
        Ministry: "MINISTRY OF INFORMATION, COMMUNICATIONS AND THE DIGITAL ECONOMY",
        State_Departments: "STATE DEPARTMENT FOR ICT AND DIGITAL ECONOMY",
        Institutions: "Office of the Data Protection Commissioner"
    },
    {
        Ministry: "MINISTRY OF INFORMATION, COMMUNICATIONS AND THE DIGITAL ECONOMY",
        State_Departments: "STATE DEPARTMENT FOR ICT AND DIGITAL ECONOMY",
        Institutions: "KONZA Technopolis Development Authority"
    },
    {
        Ministry: "MINISTRY OF INFORMATION, COMMUNICATIONS AND THE DIGITAL ECONOMY",
        State_Departments: "STATE DEPARTMENT FOR ICT AND DIGITAL ECONOMY",
        Institutions: "Kenya ICT Authority"
    },
    {
        Ministry: "MINISTRY OF INFORMATION, COMMUNICATIONS AND THE DIGITAL ECONOMY",
        State_Departments: "STATE DEPARTMENT FOR ICT AND DIGITAL ECONOMY",
        Institutions: "Kenya Advanced Institute of Science and Technology (KAIST)"
    },
    {
        Ministry: "MINISTRY OF INFORMATION, COMMUNICATIONS AND THE DIGITAL ECONOMY",
        State_Departments: "STATE DEPARTMENT FOR ICT AND DIGITAL ECONOMY",
        Institutions: "The East African Marine Cable System Limited (TEAMS)"
    },
    {
        Ministry: "MINISTRY OF INFORMATION, COMMUNICATIONS AND THE DIGITAL ECONOMY",
        State_Departments: "STATE DEPARTMENT FOR ICT AND DIGITAL ECONOMY",
        Institutions: "None"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR MEDICAL SERVICES",
        Institutions: "National Health Insurance Fund (NHIF)"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR MEDICAL SERVICES",
        Institutions: "Social Health Authority"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR MEDICAL SERVICES",
        Institutions: "Kenya Medical Research Institute (KEMRI)"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR MEDICAL SERVICES",
        Institutions: "Kenya Medical Supplies Authority (KEMSA)"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR MEDICAL SERVICES",
        Institutions: "Kenya BioVax Institute Limited"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR MEDICAL SERVICES",
        Institutions: "Kenyatta National  Hospital"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR MEDICAL SERVICES",
        Institutions: "Moi Teaching and Referral Hospital"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR MEDICAL SERVICES",
        Institutions: "Kenyatta University Teaching, Referral and Research Hospital"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR MEDICAL SERVICES",
        Institutions: "Mathari National Teaching and Referral Mental Hospital"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR MEDICAL SERVICES",
        Institutions: "The National Cancer Institute of Kenya"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR MEDICAL SERVICES",
        Institutions: "Kenya Tissue and Transplant Authority"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR MEDICAL SERVICES",
        Institutions: "National Syndemic Disease Control Council"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR MEDICAL SERVICES",
        Institutions: "Kenya Hospital Authority Trust Fund"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR MEDICAL SERVICES",
        Institutions: "Occupational Therapy Council of Kenya"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC HEALTH AND PROFESSIONAL STANDARDS",
        Institutions: "Kenya Medical Practitioners  and Dentist Council"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC HEALTH AND PROFESSIONAL STANDARDS",
        Institutions: "Kenya Health Professionals Oversight Authority"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC HEALTH AND PROFESSIONAL STANDARDS",
        Institutions: "Kenya Medical Training College (KMTC)"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC HEALTH AND PROFESSIONAL STANDARDS",
        Institutions: "Kenya National Public Health Institute"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC HEALTH AND PROFESSIONAL STANDARDS",
        Institutions: "Counselors and Psychologists Board"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC HEALTH AND PROFESSIONAL STANDARDS",
        Institutions: "Clinical Officers Council of Kenya"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC HEALTH AND PROFESSIONAL STANDARDS",
        Institutions: "Kenya Medical Laboratory Technicians and Technologists Board"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC HEALTH AND PROFESSIONAL STANDARDS",
        Institutions: "Nursing Council of Kenya"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC HEALTH AND PROFESSIONAL STANDARDS",
        Institutions: "National Quality Control Laboratories"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC HEALTH AND PROFESSIONAL STANDARDS",
        Institutions: "Health Records and Information Managers Board"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC HEALTH AND PROFESSIONAL STANDARDS",
        Institutions: "Pharmacy and Poisons Board"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC HEALTH AND PROFESSIONAL STANDARDS",
        Institutions: "Public Health Officers and Technicians Council"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC HEALTH AND PROFESSIONAL STANDARDS",
        Institutions: "Kenya Nuclear Regulatory Authority (Previously Known as Radiation Protection Board"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC HEALTH AND PROFESSIONAL STANDARDS",
        Institutions: "Kenya Institute of Primate Research"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR PUBLIC HEALTH AND PROFESSIONAL STANDARDS",
        Institutions: "Kenya Health Human Resource Advisory Council"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR BASIC EDUCATION",
        Institutions: "Kenya National Examinations Council"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR BASIC EDUCATION",
        Institutions: "Kenya Institute of Curriculum Development"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR BASIC EDUCATION",
        Institutions: "Kenya Literature Bureau"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR BASIC EDUCATION",
        Institutions: "Centre for Mathematics,Science and Technology in Africa (CEMASTEA)"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR BASIC EDUCATION",
        Institutions: "Kenya National Commission for UNESCO"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR BASIC EDUCATION",
        Institutions: "Kenya Education Management Institute (KEMI)"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR BASIC EDUCATION",
        Institutions: "School Equipment Production Unit"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR BASIC EDUCATION",
        Institutions: "Jomo Kenyatta Foundation"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR BASIC EDUCATION",
        Institutions: "National Commission for Nomadic Education (NACONEC)"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR TECHNICAL, VOCATIONAL EDUCATION AND TRAINING (TVET)",
        Institutions: "Technical and Vocational Education and Training Authority"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR TECHNICAL, VOCATIONAL EDUCATION AND TRAINING (TVET)",
        Institutions: "Kenya National Qualifications Authority"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR TECHNICAL, VOCATIONAL EDUCATION AND TRAINING (TVET)",
        Institutions: "Kenya School of TVET"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR TECHNICAL, VOCATIONAL EDUCATION AND TRAINING (TVET)",
        Institutions: "Kabete National Polytechnic"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR TECHNICAL, VOCATIONAL EDUCATION AND TRAINING (TVET)",
        Institutions: "Kenya Coast National Polytechnic"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR TECHNICAL, VOCATIONAL EDUCATION AND TRAINING (TVET)",
        Institutions: "Kisii National Polytechnic"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR TECHNICAL, VOCATIONAL EDUCATION AND TRAINING (TVET)",
        Institutions: "Kisumu National Polytechnic"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR TECHNICAL, VOCATIONAL EDUCATION AND TRAINING (TVET)",
        Institutions: "Kitale National Polytechnic"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR TECHNICAL, VOCATIONAL EDUCATION AND TRAINING (TVET)",
        Institutions: "Meru National Polytechnic"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR TECHNICAL, VOCATIONAL EDUCATION AND TRAINING (TVET)",
        Institutions: "North Eastern National Polytechnic"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR TECHNICAL, VOCATIONAL EDUCATION AND TRAINING (TVET)",
        Institutions: "Nyandarua National Polytechnic"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR TECHNICAL, VOCATIONAL EDUCATION AND TRAINING (TVET)",
        Institutions: "Nyeri National Polytechnic"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR TECHNICAL, VOCATIONAL EDUCATION AND TRAINING (TVET)",
        Institutions: "Sigalagala National Polytechnic"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR TECHNICAL, VOCATIONAL EDUCATION AND TRAINING (TVET)",
        Institutions: "The Eldoret National Polytechnic"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR TECHNICAL, VOCATIONAL EDUCATION AND TRAINING (TVET)",
        Institutions: "Technical and Vocational Education and Training Curriculum"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR TECHNICAL, VOCATIONAL EDUCATION AND TRAINING (TVET)",
        Institutions: "Development Assessment & Certification Council (TVETCDACC)"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Commission for University Education Kenya Universities and Colleges Placement Service"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Open University of Kenya"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Kenya Universities and Colleges Placement Service"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Higher Education Loans Board"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "National Research Fund"
    },
    {
        Ministry: "MINISTRY OF HEALTH",
        State_Departments: "STATE DEPARTMENT FOR MEDICAL SERVICES",
        Institutions: "National Commission for Science Technology and Innovation"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Egerton University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Egerton University Investment Enterprise"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Jomo Kenyatta University of Agriculture and Technology"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Jomo Kenyatta University Enterprises Ltd."
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "JKUAT Industrial Park Limited"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Kenyatta University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Maseno University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Masinde Muliro University of Science & Technology"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Moi University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "University of Nairobi"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "University of Nairobi Enterprises and Services Limited (Subsidiary of University of Nairobi)"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "University of Nairobi Press (Subsidiary of University of Nairobi)"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Chuka University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Cooperative University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Dedan Kimathi University of Science and Technology"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Embu University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Garissa University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Jaramogi Oginga Odinga University of Science & Technology"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Karatina University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Kibabii University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Kirinyaga University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Kisii University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Laikipia University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Maasai Mara University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Machakos University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Meru University of Science and Technology"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Multi-Media University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Muranga University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Pwani University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Rongo University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "South Eastern University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Taita Taveta University"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Technical University of Kenya"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Technical University of Mombasa"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "University Kabianga"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "University of Eldoret"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Alupe University College"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Bomet University College"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Kaimosi Friends University College"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Koitale Arap Samoei University College"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Mama Ngina University College"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Tharaka University College"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Tom Mboya University College"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Turkana University College"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Kenya School of Monetary Studies"
    },
    {
        Ministry: "MINISTRY OF EDUCATION",
        State_Departments: "STATE DEPARTMENT FOR HIGHER EDUCATION AND RESEARCH",
        Institutions: "Kenya Advanced Institute of Science and Technology"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Agriculture and Food Authority (AFA)"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Tea Board of Kenya"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Agriculture Finance Corporation"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Agricultural Development Corporation"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Kenya Seed Company"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Simlaw Seeds Kenya Ltd"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Kenya Plant Health Inspectorate Services (KEPHIS)"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Nyayo Tea Zones Development Corporation"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Gatitu Tea Factory"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Kipchabo Tea Factory"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "South Nyanza Sugar Company"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Nzoia Sugar Company"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Bukura Agricultural College"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "National Cereals and Produce Board"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Agro-Chemical and Food company"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Pyrethrum Processing Company of Kenya"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Kenya Agricultural and Livestock Research Organization (KALRO)"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "National Biosafety Authority"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Chemelil Sugar Company"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Fertilizers and Animal Foodstuffs Board of Kenya"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Western Kenya Rice Mills Ltd"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Agricultural Information Resource Centre (AIRC)"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Agri and Cooperative Training & Consultancy Services Ltd"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Ahero Rice Meals"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Miwani Sugar Company (under receivership)"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Muhoroni Sugar Factory"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Mwea Rice Mills"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Tea Research Foundation"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR LIVESTOCK DEVELOPMENT",
        Institutions: "Kenya Veterinary Vaccine Production Institute"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR LIVESTOCK DEVELOPMENT",
        Institutions: "Kenya Veterinary Board"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR LIVESTOCK DEVELOPMENT",
        Institutions: "Veterinary Medicine Directorate"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR LIVESTOCK DEVELOPMENT",
        Institutions: "Animal Technicians Council"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR LIVESTOCK DEVELOPMENT",
        Institutions: "Kenya Leather Development Council"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR LIVESTOCK DEVELOPMENT",
        Institutions: "Kenya Dairy Board"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR LIVESTOCK DEVELOPMENT",
        Institutions: "Kenya Animal Genetics Resource Centre"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR LIVESTOCK DEVELOPMENT",
        Institutions: "Kenya Tsetse and Trypanosomiasis Eradication Council"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Pest Control Product Board"
    },
    {
        Ministry: "MINISTRY OF AGRICULTURE AND LIVESTOCK DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR AGRICULTURE",
        Institutions: "Commodities Fund"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR INVESTMENTS PROMOTION",
        Institutions: "Kenya Investment Authority"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR INVESTMENTS PROMOTION",
        Institutions: "Export Processing Zones Authority"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR INVESTMENTS PROMOTION",
        Institutions: "Special Economic Zones Authority"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR INVESTMENTS PROMOTION",
        Institutions: "Kenya Development Corporation (KDC)"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR TRADE",
        Institutions: "Kenya National Trading Corporation"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR TRADE",
        Institutions: "Kenya Export Promotion and Branding Agency (KEPROBA)"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR TRADE",
        Institutions: "Warehouse Receipt Systems Council"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR TRADE",
        Institutions: "Kenya National Multi-Commodities Exchange Limited"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR TRADE",
        Institutions: "Trade Remedies Agency"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR INDUSTRY",
        Institutions: "Kenya Bureau of Standards"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR INDUSTRY",
        Institutions: "Kenya National Accreditation Services"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR INDUSTRY",
        Institutions: "Rivatex"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR INDUSTRY",
        Institutions: "East African Portland Cement Co. Ltd"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR INDUSTRY",
        Institutions: "Numerical Machining Complex (NMC)"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR INDUSTRY",
        Institutions: "Kenya Industrial Research and Development Institute (KIRDI)"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR INDUSTRY",
        Institutions: "Kenya Industrial Property Institute (KIPI)"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR INDUSTRY",
        Institutions: "Scrap Metal Council"
    },
    {
        Ministry: "MINISTRY OF INVESTMENTS, TRADE AND INDUSTRY",
        State_Departments: "STATE DEPARTMENT FOR INDUSTRY",
        Institutions: "Anti-Counterfeit Authority"
    },
    {
        Ministry: "MINISTRY OF CO-OPERATIVES AND MICRO, SMALL AND MEDIUM ENTERPRISES (MSMEs) DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR CO-OPERATIVES",
        Institutions: "Sacco Societies Regulatory Authority (SASRA)"
    },
    {
        Ministry: "MINISTRY OF CO-OPERATIVES AND MICRO, SMALL AND MEDIUM ENTERPRISES (MSMEs) DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR CO-OPERATIVES",
        Institutions: "New Kenya Planters Cooperative  Union"
    },
    {
        Ministry: "MINISTRY OF CO-OPERATIVES AND MICRO, SMALL AND MEDIUM ENTERPRISES (MSMEs) DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR CO-OPERATIVES",
        Institutions: "New Kenya Co-operative Creameries"
    },
    {
        Ministry: "MINISTRY OF CO-OPERATIVES AND MICRO, SMALL AND MEDIUM ENTERPRISES (MSMEs) DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR MICRO, SMALL, MEDIUM ENTERPRISES (MSMEs) DEVELOPMENT",
        Institutions: "Kenya Industrial Estates"
    },
    {
        Ministry: "MINISTRY OF CO-OPERATIVES AND MICRO, SMALL AND MEDIUM ENTERPRISES (MSMEs) DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR MICRO, SMALL, MEDIUM ENTERPRISES (MSMEs) DEVELOPMENT",
        Institutions: "Micro and Small Enterprise Authority"
    },
    {
        Ministry: "MINISTRY OF YOUTH AFFAIRS, CREATIVE ECONOMY AND SPORTS",
        State_Departments: "STATE DEPARTMENT FOR YOUTH AFFAIRS AND CREATIVE ECONOMY",
        Institutions: "Kenya National Youth Council"
    },
    {
        Ministry: "MINISTRY OF YOUTH AFFAIRS, CREATIVE ECONOMY AND SPORTS",
        State_Departments: "STATE DEPARTMENT FOR YOUTH AFFAIRS AND CREATIVE ECONOMY",
        Institutions: "Youth Advisory Board"
    },
    {
        Ministry: "MINISTRY OF YOUTH AFFAIRS, CREATIVE ECONOMY AND SPORTS",
        State_Departments: "STATE DEPARTMENT FOR YOUTH AFFAIRS AND CREATIVE ECONOMY",
        Institutions: "Kenya Association of Youth Centres"
    },
    {
        Ministry: "MINISTRY OF YOUTH AFFAIRS, CREATIVE ECONOMY AND SPORTS",
        State_Departments: "STATE DEPARTMENT FOR YOUTH AFFAIRS AND CREATIVE ECONOMY",
        Institutions: "Kenya National Innovation Agency"
    },
    {
        Ministry: "MINISTRY OF YOUTH AFFAIRS, CREATIVE ECONOMY AND SPORTS",
        State_Departments: "STATE DEPARTMENT FOR YOUTH AFFAIRS AND CREATIVE ECONOMY",
        Institutions: "The Regional Youth Forum Secretariat of the International Conference on the Great Lakes Region (ICGLR)"
    },
    {
        Ministry: "MINISTRY OF YOUTH AFFAIRS, CREATIVE ECONOMY AND SPORTS",
        State_Departments: "STATE DEPARTMENT FOR YOUTH AFFAIRS AND CREATIVE ECONOMY",
        Institutions: "The President\u00c3\u00a2\u00e2\u201a\u00ac\u00e2\u201e\u00a2s Award-Kenya"
    },
    {
        Ministry: "MINISTRY OF YOUTH AFFAIRS, CREATIVE ECONOMY AND SPORTS",
        State_Departments: "STATE DEPARTMENT FOR YOUTH AFFAIRS AND CREATIVE ECONOMY",
        Institutions: "Youth Enterprise Development Fund (YEDF)"
    },
    {
        Ministry: "MINISTRY OF YOUTH AFFAIRS, CREATIVE ECONOMY AND SPORTS",
        State_Departments: "STATE DEPARTMENT FOR YOUTH AFFAIRS AND CREATIVE ECONOMY",
        Institutions: "Kenya Film Classification Board"
    },
    {
        Ministry: "MINISTRY OF YOUTH AFFAIRS, CREATIVE ECONOMY AND SPORTS",
        State_Departments: "STATE DEPARTMENT FOR YOUTH AFFAIRS AND CREATIVE ECONOMY",
        Institutions: "Kenya Film Commission"
    },
    {
        Ministry: "MINISTRY OF YOUTH AFFAIRS, CREATIVE ECONOMY AND SPORTS",
        State_Departments: "STATE DEPARTMENT FOR YOUTH AFFAIRS AND CREATIVE ECONOMY",
        Institutions: "Kenya National Theatre"
    },
    {
        Ministry: "MINISTRY OF YOUTH AFFAIRS, CREATIVE ECONOMY AND SPORTS",
        State_Departments: "STATE DEPARTMENT FOR SPORTS",
        Institutions: "Sports Kenya"
    },
    {
        Ministry: "MINISTRY OF YOUTH AFFAIRS, CREATIVE ECONOMY AND SPORTS",
        State_Departments: "STATE DEPARTMENT FOR SPORTS",
        Institutions: "Kenya Anti-Doping Agency Sports Kenya"
    },
    {
        Ministry: "MINISTRY OF YOUTH AFFAIRS, CREATIVE ECONOMY AND SPORTS",
        State_Departments: "STATE DEPARTMENT FOR SPORTS",
        Institutions: "Sports Arts and Social Development Fund"
    },
    {
        Ministry: "MINISTRY OF YOUTH AFFAIRS, CREATIVE ECONOMY AND SPORTS",
        State_Departments: "STATE DEPARTMENT FOR SPORTS",
        Institutions: "Kenya Academy of Sports Registrarof Sports"
    },
    {
        Ministry: "MINISTRY OF ENVIRONMENT, CLIMATE CHANGE AND FORESTRY",
        State_Departments: "STATE DEPARTMENT FOR ENVIRONMENT AND CLIMATE CHANGE",
        Institutions: "National Environmental Management Authority"
    },
    {
        Ministry: "MINISTRY OF ENVIRONMENT, CLIMATE CHANGE AND FORESTRY",
        State_Departments: "STATE DEPARTMENT FOR ENVIRONMENT AND CLIMATE CHANGE",
        Institutions: "Kenya Water Towers Agency"
    },
    {
        Ministry: "MINISTRY OF ENVIRONMENT, CLIMATE CHANGE AND FORESTRY",
        State_Departments: "STATE DEPARTMENT FOR ENVIRONMENT AND CLIMATE CHANGE",
        Institutions: "National Environment Trust Fund"
    },
    {
        Ministry: "MINISTRY OF ENVIRONMENT, CLIMATE CHANGE AND FORESTRY",
        State_Departments: "STATE DEPARTMENT FOR FORESTRY",
        Institutions: "Kenya Forest Service"
    },
    {
        Ministry: "MINISTRY OF ENVIRONMENT, CLIMATE CHANGE AND FORESTRY",
        State_Departments: "STATE DEPARTMENT FOR FORESTRY",
        Institutions: "Kenya Forestry Research Institute"
    },
    {
        Ministry: "MINISTRY OF TOURISM AND WILDLIFE",
        State_Departments: "STATE DEPARTMENT FOR TOURISM",
        Institutions: "Tourism Regulatory Authority"
    },
    {
        Ministry: "MINISTRY OF TOURISM AND WILDLIFE",
        State_Departments: "STATE DEPARTMENT FOR TOURISM",
        Institutions: "Kenya Utalii College"
    },
    {
        Ministry: "MINISTRY OF TOURISM AND WILDLIFE",
        State_Departments: "STATE DEPARTMENT FOR TOURISM",
        Institutions: "The Tourism Fund"
    },
    {
        Ministry: "MINISTRY OF TOURISM AND WILDLIFE",
        State_Departments: "STATE DEPARTMENT FOR TOURISM",
        Institutions: "Tourism Research Institute"
    },
    {
        Ministry: "MINISTRY OF TOURISM AND WILDLIFE",
        State_Departments: "STATE DEPARTMENT FOR TOURISM",
        Institutions: "Kenya Tourism Board"
    },
    {
        Ministry: "MINISTRY OF TOURISM AND WILDLIFE",
        State_Departments: "STATE DEPARTMENT FOR TOURISM",
        Institutions: "Kenyatta International Convention Centre"
    },
    {
        Ministry: "MINISTRY OF TOURISM AND WILDLIFE",
        State_Departments: "STATE DEPARTMENT FOR TOURISM",
        Institutions: "Sunset Hotel"
    },
    {
        Ministry: "MINISTRY OF TOURISM AND WILDLIFE",
        State_Departments: "STATE DEPARTMENT FOR TOURISM",
        Institutions: "Kenya Safari Lodges and Hotels"
    },
    {
        Ministry: "MINISTRY OF TOURISM AND WILDLIFE",
        State_Departments: "STATE DEPARTMENT FOR TOURISM",
        Institutions: "Mt Elgon lodge"
    },
    {
        Ministry: "MINISTRY OF TOURISM AND WILDLIFE",
        State_Departments: "STATE DEPARTMENT FOR TOURISM",
        Institutions: "Golf Hotel Kakamega"
    },
    {
        Ministry: "MINISTRY OF TOURISM AND WILDLIFE",
        State_Departments: "STATE DEPARTMENT FOR TOURISM",
        Institutions: "Kabarnet Hotel"
    },
    {
        Ministry: "MINISTRY OF TOURISM AND WILDLIFE",
        State_Departments: "STATE DEPARTMENT FOR TOURISM",
        Institutions: "Tourism Finance Corporation (Defunct)"
    },
    {
        Ministry: "MINISTRY OF TOURISM AND WILDLIFE",
        State_Departments: "STATE DEPARTMENT FOR TOURISM",
        Institutions: "Tourism Promotion Fund"
    },
    {
        Ministry: "MINISTRY OF TOURISM AND WILDLIFE",
        State_Departments: "STATE DEPARTMENT FOR WILDLIFE",
        Institutions: "Kenya Wildlife Service"
    },
    {
        Ministry: "MINISTRY OF TOURISM AND WILDLIFE",
        State_Departments: "STATE DEPARTMENT FOR WILDLIFE",
        Institutions: "Kenya Wildlife Research and Training Institute"
    },
    {
        Ministry: "MINISTRY OF GENDER, CULTURE, THE ARTS & HERITAGE",
        State_Departments: "STATE DEPARTMENT FOR GENDER AND AFFIRMATIVE ACTION",
        Institutions: "The National Government Affirmative Action Fund"
    },
    {
        Ministry: "MINISTRY OF GENDER, CULTURE, THE ARTS & HERITAGE",
        State_Departments: "STATE DEPARTMENT FOR GENDER AND AFFIRMATIVE ACTION",
        Institutions: "Anti-Female Genital Mutilation Board"
    },
    {
        Ministry: "MINISTRY OF GENDER, CULTURE, THE ARTS & HERITAGE",
        State_Departments: "STATE DEPARTMENT FOR CULTURE, THE ARTS AND HERITAGE",
        Institutions: "The National Heroes Council"
    },
    {
        Ministry: "MINISTRY OF GENDER, CULTURE, THE ARTS & HERITAGE",
        State_Departments: "STATE DEPARTMENT FOR CULTURE, THE ARTS AND HERITAGE",
        Institutions: "National Museums of Kenya"
    },
    {
        Ministry: "MINISTRY OF GENDER, CULTURE, THE ARTS & HERITAGE",
        State_Departments: "STATE DEPARTMENT FOR CULTURE, THE ARTS AND HERITAGE",
        Institutions: "Bomas of Kenya"
    },
    {
        Ministry: "MINISTRY OF GENDER, CULTURE, THE ARTS & HERITAGE",
        State_Departments: "STATE DEPARTMENT FOR CULTURE, THE ARTS AND HERITAGE",
        Institutions: "Kenya Copyright Board"
    },
    {
        Ministry: "MINISTRY OF GENDER, CULTURE, THE ARTS & HERITAGE",
        State_Departments: "STATE DEPARTMENT FOR CULTURE, THE ARTS AND HERITAGE",
        Institutions: "Kenya Cultural Centre"
    },
    {
        Ministry: "MINISTRY OF GENDER, CULTURE, THE ARTS & HERITAGE",
        State_Departments: "STATE DEPARTMENT FOR CULTURE, THE ARTS AND HERITAGE",
        Institutions: "Kenya National Library Services"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR WATER AND SANITATION",
        Institutions: "Water Services Regulatory Board"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR WATER AND SANITATION",
        Institutions: "Kenya Water Institute"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR WATER AND SANITATION",
        Institutions: "Athi Water Works Development Agency"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR WATER AND SANITATION",
        Institutions: "Tana Water Works Development Agency"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR WATER AND SANITATION",
        Institutions: "Tanathi Water Works Development Agency"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR WATER AND SANITATION",
        Institutions: "Lake Victoria North Water Works Development Agency"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR WATER AND SANITATION",
        Institutions: "Lake Victoria South Works Development Agency"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR WATER AND SANITATION",
        Institutions: "Central Rift Valley Water Works Development Agency"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR WATER AND SANITATION",
        Institutions: "North Rift Valley Water Works Development Agency"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR WATER AND SANITATION",
        Institutions: "Coast Water Works Development Agency"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR WATER AND SANITATION",
        Institutions: "Northern Water Works Development"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR WATER AND SANITATION",
        Institutions: "Water Sector Trust Fund"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR WATER AND SANITATION",
        Institutions: "Regional Centre on Ground Water Resources Education, Training and Research"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR WATER AND SANITATION",
        Institutions: "Water Resources Authority"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR WATER AND SANITATION",
        Institutions: "Hydrologists Registration Board"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR IRRIGATION",
        Institutions: "National Water Harvesting and Storage Authority"
    },
    {
        Ministry: "MINISTRY OF WATER, SANITATION AND IRRIGATION",
        State_Departments: "STATE DEPARTMENT FOR IRRIGATION",
        Institutions: "National Irrigation Authority"
    },
    {
        Ministry: "MINISTRY OF ENERGY AND PETROLEUM",
        State_Departments: "STATE DEPARTMENT FOR ENERGY",
        Institutions: "Energy and Petroleum  Regulatory Authority"
    },
    {
        Ministry: "MINISTRY OF ENERGY AND PETROLEUM",
        State_Departments: "STATE DEPARTMENT FOR ENERGY",
        Institutions: "Kenya Power and Lighting Company Ltd"
    },
    {
        Ministry: "MINISTRY OF ENERGY AND PETROLEUM",
        State_Departments: "STATE DEPARTMENT FOR ENERGY",
        Institutions: "Keny Electricity Generating Company Limited"
    },
    {
        Ministry: "MINISTRY OF ENERGY AND PETROLEUM",
        State_Departments: "STATE DEPARTMENT FOR ENERGY",
        Institutions: "Kenya Electricity Transmission Company Limited"
    },
    {
        Ministry: "MINISTRY OF ENERGY AND PETROLEUM",
        State_Departments: "STATE DEPARTMENT FOR ENERGY",
        Institutions: "Rural Electrification and Renewable Energy"
    },
    {
        Ministry: "MINISTRY OF ENERGY AND PETROLEUM",
        State_Departments: "STATE DEPARTMENT FOR ENERGY",
        Institutions: "Nuclear Power and Energy Agency"
    },
    {
        Ministry: "MINISTRY OF ENERGY AND PETROLEUM",
        State_Departments: "STATE DEPARTMENT FOR ENERGY",
        Institutions: "Geothermal Development Company"
    },
    {
        Ministry: "MINISTRY OF ENERGY AND PETROLEUM",
        State_Departments: "STATE DEPARTMENT FOR PETROLEUM",
        Institutions: "National Oil Corporation of Kenya"
    },
    {
        Ministry: "MINISTRY OF ENERGY AND PETROLEUM",
        State_Departments: "STATE DEPARTMENT FOR PETROLEUM",
        Institutions: "Kenya Pipeline Company"
    },
    {
        Ministry: "MINISTRY OF ENERGY AND PETROLEUM",
        State_Departments: "STATE DEPARTMENT FOR PETROLEUM",
        Institutions: "Kenya Petroleum Refineries"
    },
    {
        Ministry: "MINISTRY OF LABOUR AND SOCIAL PROTECTION",
        State_Departments: "STATE DEPARTMENT FOR LABOUR AND SKILLS DEVELOPMENT",
        Institutions: "National Social Security Fund (NSSF)"
    },
    {
        Ministry: "MINISTRY OF LABOUR AND SOCIAL PROTECTION",
        State_Departments: "STATE DEPARTMENT FOR LABOUR AND SKILLS DEVELOPMENT",
        Institutions: "National Employment Authority"
    },
    {
        Ministry: "MINISTRY OF LABOUR AND SOCIAL PROTECTION",
        State_Departments: "STATE DEPARTMENT FOR LABOUR AND SKILLS DEVELOPMENT",
        Institutions: "National Industrial Training Authority"
    },
    {
        Ministry: "MINISTRY OF LABOUR AND SOCIAL PROTECTION",
        State_Departments: "STATE DEPARTMENT FOR SOCIAL PROTECTION AND SENIOR CITIZEN AFFAIRS",
        Institutions: "National Council for Persons with Disabilities"
    },
    {
        Ministry: "MINISTRY OF LABOUR AND SOCIAL PROTECTION",
        State_Departments: "STATE DEPARTMENT FOR SOCIAL PROTECTION AND SENIOR CITIZEN AFFAIRS",
        Institutions: "National Council for Children Services"
    },
    {
        Ministry: "MINISTRY OF LABOUR AND SOCIAL PROTECTION",
        State_Departments: "STATE DEPARTMENT FOR SOCIAL PROTECTION AND SENIOR CITIZEN AFFAIRS",
        Institutions: "Street Families Rehabilitation Trust Fund"
    },
    {
        Ministry: "MINISTRY OF EAST AFRICAN COMMUNITY (EAC), THE ASALs AND REGIONAL DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR EAST AFRICAN COMMUNITY (EAC) AFFAIRS",
        Institutions: "None"
    },
    {
        Ministry: "MINISTRY OF EAST AFRICAN COMMUNITY (EAC), THE ASALs AND REGIONAL DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR THE ASALS AND REGIONAL DEVELOPMENT",
        Institutions: "National Drought Management Authority"
    },
    {
        Ministry: "MINISTRY OF EAST AFRICAN COMMUNITY (EAC), THE ASALs AND REGIONAL DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR THE ASALS AND REGIONAL DEVELOPMENT",
        Institutions: "Tana and Athi Rivers Development Authority"
    },
    {
        Ministry: "MINISTRY OF EAST AFRICAN COMMUNITY (EAC), THE ASALs AND REGIONAL DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR THE ASALS AND REGIONAL DEVELOPMENT",
        Institutions: "Lake Basin Development Authority"
    },
    {
        Ministry: "MINISTRY OF EAST AFRICAN COMMUNITY (EAC), THE ASALs AND REGIONAL DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR THE ASALS AND REGIONAL DEVELOPMENT",
        Institutions: "Kerio Valley Development Authority"
    },
    {
        Ministry: "MINISTRY OF EAST AFRICAN COMMUNITY (EAC), THE ASALs AND REGIONAL DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR THE ASALS AND REGIONAL DEVELOPMENT",
        Institutions: "Ewaso Nyiro North Development Authority"
    },
    {
        Ministry: "MINISTRY OF EAST AFRICAN COMMUNITY (EAC), THE ASALs AND REGIONAL DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR THE ASALS AND REGIONAL DEVELOPMENT",
        Institutions: "Ewaso Nyiro South Development Authority"
    },
    {
        Ministry: "MINISTRY OF EAST AFRICAN COMMUNITY (EAC), THE ASALs AND REGIONAL DEVELOPMENT",
        State_Departments: "STATE DEPARTMENT FOR THE ASALS AND REGIONAL DEVELOPMENT",
        Institutions: "Coast Development Authority"
    },
    {
        Ministry: "MINISTRY OF MINING, BLUE ECONOMY AND MARITIME AFFAIRS",
        State_Departments: "STATE DEPARTMENT FOR MINING",
        Institutions: "National Mining Corporation"
    },
    {
        Ministry: "MINISTRY OF MINING, BLUE ECONOMY AND MARITIME AFFAIRS",
        State_Departments: "STATE DEPARTMENT FOR MINING",
        Institutions: "The Geologists Registration Board"
    },
    {
        Ministry: "MINISTRY OF MINING, BLUE ECONOMY AND MARITIME AFFAIRS",
        State_Departments: "STATE DEPARTMENT FOR THE BLUE ECONOMY & FISHERIES",
        Institutions: "Kenya Marine and Fisheries Research Institute"
    },
    {
        Ministry: "MINISTRY OF MINING, BLUE ECONOMY AND MARITIME AFFAIRS",
        State_Departments: "STATE DEPARTMENT FOR THE BLUE ECONOMY & FISHERIES",
        Institutions: "Kenya Fisheries Service"
    },
    {
        Ministry: "MINISTRY OF MINING, BLUE ECONOMY AND MARITIME AFFAIRS",
        State_Departments: "STATE DEPARTMENT FOR SHIPPING & MARITIME AFFAIRS",
        Institutions: "The Kenya National Shipping Line"
    },
    {
        Ministry: "MINISTRY OF MINING, BLUE ECONOMY AND MARITIME AFFAIRS",
        State_Departments: "STATE DEPARTMENT FOR SHIPPING & MARITIME AFFAIRS",
        Institutions: "Kenya Maritime Authority"
    },
    {
        Ministry: "MINISTRY OF MINING, BLUE ECONOMY AND MARITIME AFFAIRS",
        State_Departments: "STATE DEPARTMENT FOR SHIPPING & MARITIME AFFAIRS",
        Institutions: "Bandari Maritime Academy "
    },
    {
        Ministry: "THE HONOURABLE ATTORNEY-GENERAL",
        State_Departments: "",
        Institutions: "None"
    },
    {
        Ministry: "THE SOLICITOR-GENERAL",
        State_Departments: "",
        Institutions: "None"
    },
    {
        Ministry: "THE STATE LAW OFFICE",
        State_Departments: "THE STATE LAW OFFICE",
        Institutions: "Business Registration Service"
    },
    {
        Ministry: "THE STATE LAW OFFICE",
        State_Departments: "THE STATE LAW OFFICE",
        Institutions: "Council for Legal Education"
    },
    {
        Ministry: "THE STATE LAW OFFICE",
        State_Departments: "THE STATE LAW OFFICE",
        Institutions: "Assets Recovery Agency"
    },
    {
        Ministry: "THE STATE LAW OFFICE",
        State_Departments: "THE STATE LAW OFFICE",
        Institutions: "Kenya School of Law"
    },
    {
        Ministry: "THE STATE LAW OFFICE",
        State_Departments: "THE STATE LAW OFFICE",
        Institutions: "Auctioneers Licensing Board"
    },
    {
        Ministry: "THE STATE LAW OFFICE",
        State_Departments: "THE STATE LAW OFFICE",
        Institutions: "Witness Protection Agency"
    },
    {
        Ministry: "THE STATE LAW OFFICE",
        State_Departments: "THE STATE LAW OFFICE",
        Institutions: "Victims Protection Board"
    },
    {
        Ministry: "THE STATE LAW OFFICE",
        State_Departments: "THE STATE LAW OFFICE",
        Institutions: "Kenya Law Reform Commission"
    },
    {
        Ministry: "THE STATE LAW OFFICE",
        State_Departments: "THE STATE LAW OFFICE",
        Institutions: "National Council for Law Reporting"
    },
    {
        Ministry: "THE STATE LAW OFFICE",
        State_Departments: "THE STATE LAW OFFICE",
        Institutions: "National Coroners Service"
    },
    {
        Ministry: "THE STATE LAW OFFICE",
        State_Departments: "THE STATE LAW OFFICE",
        Institutions: "National Legal Aid Service"
    },
    {
        Ministry: "THE STATE LAW OFFICE",
        State_Departments: "THE STATE LAW OFFICE",
        Institutions: "Nairobi Centre for International Arbitration"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "Commission on Administrative Justice"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "Commission on Revenue Allocation"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "Ethics and Anti-Corruption Commission"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "Independent Electoral and Boundaries Commission"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "Independent Policing Oversight Authority"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "Judicial Service Commission"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "Kenya Law Reform Commission"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "Kenya National Commission on Human Rights"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "National Cohesion and Integration Commission"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "National Gender and Equality Commission"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "National Land Commission"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "National Police Service "
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "National Police Service Commission"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "Office of the Attorney General and Department of Justice"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "Office of the Auditor-General"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "Office of the Controller of Budget"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "Office of the Director of Public Prosecutions"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "Parliamentary Service Commission"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "Public Service Commission"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "Salaries and Remuneration Commission"
    },
    {
        Ministry: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        State_Departments: "CONSTITUTIONAL COMMISSIONS AND INDEPENDENT OFFICES",
        Institutions: "Teachers Service Commission"
    }
]