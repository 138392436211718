import React from "react";
import * as yup from "yup";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { useFormik } from "formik";
import Div from "@jumbo/shared/Div";
import axios from "axios";
import { backend_url } from "app/services/constants";
import { format } from "date-fns";
import { useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import "../../../../../styles/leaf.css";

const PSUpdateActivity = ({
  activity,
  // remainingWeight,
  token,
  onClose,
  dir_id,
}) => {
  const Swal = useSwalWrapper();
  const [showErrors, setShowErrors] = useState(false);

  const inputString =
    activity !== undefined && activity.geometry !== null
      ? activity.geometry
      : "SRID=4326;POINT (0.1 0.1)";
  const regex = /POINT \((-?\d+\.\d+) (-?\d+\.\d+)\)/;
  const match = inputString.match(regex);

  const initialMarkerPosition = match
    ? {
        lat: parseFloat(match[1]),
        lng: parseFloat(match[2]),
      }
    : {
        lat: 0.1,
        lng: 0.1,
      };

  const [markerPosition, setMarkerPosition] = useState(initialMarkerPosition);

  const handleMapClick = (event) => {
    const { lat, lng } = event.latlng;
    setMarkerPosition({ lat, lng });
  };

  const handleTextFieldChange = (field, value) => {
    setMarkerPosition((prevPosition) => ({
      ...prevPosition,
      [field]: parseFloat(value) || 0, // Parse the value to a float or set to 0 if not valid
    }));
  };

  const MapClickHandler = () => {
    const map = useMapEvents({
      click: handleMapClick,
    });

    return null; // Return null as we don't need to render anything for the MapClickHandler
  };

  const validationSchema = yup.object({
    name: yup.string().required("Please enter the activity name."),
    description: yup.string().required("Please enter the description."),
    weighting: yup
      .number()
      .min(0, "Weighting must be at least 0")
      .max(1, "Weighting must be at most 1")
      .required("Weighting is required"),
    budget_activities: yup.number().required("Please enter the budget"),
    status_activities: yup.string().required("Please enter the status"),
    start_date: yup
      .date()
      .required("You must enter the start date")
      .default(() => new Date()),
    end_date: yup
      .date()
      .required("You must enter end/target date")
      .default(() => new Date()),
  });

  const defaultValues = {
    name: activity !== undefined ? activity.properties.name : "",
    description: activity !== undefined ? activity.properties.description : "",
    weighting: activity !== undefined ? activity.properties.weighting : 0,
    budget_activities:
      activity !== undefined ? activity.properties.budget_activities : "",
    status_activities:
      activity !== undefined ? activity.properties.status_activities : "",
    start_date:
      activity !== undefined ? dayjs(activity.properties.start_date) : "",
    end_date: activity !== undefined ? dayjs(activity.properties.end_date) : "",
    location: `POINT(${markerPosition.lng} ${markerPosition.lat})`,
  };

  const NumberFormat = new Intl.NumberFormat("en-US");


  function submit({
    name,
    description,
    weighting,
    budget_activities,
    status_activities,
    start_date,
    end_date,
    location,
  }) {
    const activityUpdate = {
      id: activity.id,
      directive_no: dir_id,
      name,
      description,
      weighting,
      budget_activities,
      status_activities,
      start_date: format(new Date(start_date), "yyyy-MM-dd"),
      end_date: format(new Date(end_date), "yyyy-MM-dd"),
      // location: "POINT(-4.9565 39.5561)",
      location,
    };
    axios
      .put(
        `${backend_url}/ppi/activity/`,
        activityUpdate,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        onClose();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Activity updated successfully.",
          showConfirmButton: false,
          timer: 4500,
        });
        window.location.reload();
        // activity.push(res.data);
      })
      .catch((err) => {
        console.log(err);
        onClose();
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: err.response.data?.details,
          showConfirmButton: false,
          timer: 4500,
        });
      });
  }

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema,
    onSubmit: submit,
  });

  const handleInputChange = (event) => {
    const inputValue = parseFloat(event.target.value);
    // const clampedValue = Math.min(remainingWeight, Math.max(0, inputValue)); // Clamp the value between 0 and 1
    const clampedValue = Math.min(1, Math.max(0, inputValue)); // Clamp the value between 0 and 1
    formik.setFieldValue("weighting", clampedValue);
  };

  const handleFieldErrors = () => {
    setShowErrors(true);
    // formik.validateForm().then(() => {
    //   // Log the fields with errors
    //   const fieldsWithErrors = Object.keys(formik.errors);
    //   console.log("Fields with errors:", fieldsWithErrors);
    // });
  };
  return (
    activity.properties.name !== undefined && (
      <Div
        sx={{
          "& .MuiTextField-root": {
            mb: 3,
          },
        }}
      >
        <Typography variant={"h2"} mb={3}>
          Update activity detail
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth>
            <Div sx={{ mb: 3, mt: 1 }}>
              <TextField
                fullWidth
                id="name"
                name="name"
                type="text"
                label="Activity Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                error={
                  (showErrors || formik.touched.name) &&
                  Boolean(formik.errors.name)
                }
                helperText={
                  (showErrors || formik.touched.name) && formik.errors.name
                }
              />
            </Div>
          </FormControl>

          <FormControl fullWidth>
            <Div sx={{ mb: 3, mt: 1 }}>
              <TextField
                fullWidth
                id="description"
                name="description"
                type="text"
                label="Description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                error={
                  (showErrors || formik.touched.description) &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  (showErrors || formik.touched.description) &&
                  formik.errors.description
                }
              />
            </Div>
          </FormControl>

          <FormControl fullWidth>
            <Div sx={{ mb: 3, mt: 1 }}>
              <TextField
                fullWidth
                id="weighting"
                name="weighting"
                type="number"
                // InputProps={{ inputProps: { min: 0, max: 1 } }}
                // InputProps={{
                //   inputProps: { min: 0, max: remainingWeight, step: 0.01 },
                // }}
                label="Weighting"
                onChange={handleInputChange}
                onBlur={formik.handleBlur}
                value={formik.values.weighting}
                error={
                  (showErrors || formik.touched.weighting) &&
                  Boolean(formik.errors.weighting)
                }
                helperText={
                  (showErrors || formik.touched.weighting) &&
                  formik.errors.weighting
                }
              />
            </Div>
          </FormControl>

          <FormControl fullWidth>
            <Div sx={{ mb: 3, mt: 1 }}>
              {/* <TextField
                fullWidth
                id="budget_activities"
                name="budget_activities"
                type="number"
                label="Budget"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.budget_activities}
                error={
                  (showErrors || formik.touched.budget_activities) &&
                  Boolean(formik.errors.budget_activities)
                }
                helperText={
                  (showErrors || formik.touched.budget_activities) &&
                  formik.errors.budget_activities
                }
              /> */}

              <TextField
                sx={{ mr: 2 }}
                fullWidth
                id="budget_activities"
                name="budget_activities"
                label="Cost (KES)"
                defaultValue={0}
                onChange={(e) => {
                  const rawValue = e.target.value.replace(/,/g, ""); // remove commas
                  if (!isNaN(rawValue)) {
                    formik.setFieldValue("budget_activities", rawValue); // update formik with the raw number
                  }
                }}
                onBlur={(e) => formik.handleBlur(e)}
                value={NumberFormat.format(formik.values.budget_activities)} // format with commas
                error={
                  (showErrors || formik.touched.budget_activities) &&
                  Boolean(formik.errors.budget_activities)
                }
                helperText={
                  (showErrors || formik.touched.budget_activities) && formik.errors.budget_activities
                }
              />
            </Div>
          </FormControl>

          <Div sx={{ mb: 3, mt: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="status_activities">Status of Activity</InputLabel>
              <Select
                labelId="status_activities"
                id="status_activities"
                name="status_activities"
                label="Status of Activity"
                value={formik.values.status_activities}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  (showErrors || formik.touched.status_activities) &&
                  Boolean(formik.errors.status_activities)
                }
              >
                <MenuItem value={"Not_Started"}>Not Started</MenuItem>

                <MenuItem value={"In_Progress"}>In Progress</MenuItem>
                <MenuItem value={"Completed"}>Completed</MenuItem>
                <MenuItem value={"Over_Due"}>Over Due</MenuItem>
              </Select>
              {(showErrors || formik.touched.status_activities) &&
                formik.errors.status_activities && (
                  <FormHelperText>
                    {formik.errors.status_activities}
                  </FormHelperText>
                )}
            </FormControl>
          </Div>

          <Div sx={{ mb: 2, mt: 1, display: "flex" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ mr: 2 }}
                fullWidth
                id="start_date"
                name="start_date"
                label="Start Date"
                value={formik.values.start_date}
                onChange={(date) => formik.setFieldValue("start_date", date)}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      (showErrors || formik.touched.start_date) &&
                      Boolean(formik.errors.start_date)
                    }
                    helperText={
                      (showErrors || formik.touched.start_date) &&
                      formik.errors.start_date
                    }
                  />
                )}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                fullWidth
                id="end_date"
                name="end_date"
                label="End Date"
                value={formik.values.end_date}
                onChange={(date) => formik.setFieldValue("end_date", date)}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      (showErrors || formik.touched.end_date) &&
                      Boolean(formik.errors.end_date)
                    }
                    helperText={
                      (showErrors || formik.touched.end_date) &&
                      formik.errors.end_date
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Div>

          {/* <Div sx={{ mb: 2, mt: 1, display: "flex" }}>
            <TextField
              fullWidth
              sx={{ mr: 2 }}
              id="latitude"
              name="latitude"
              label="Latitude"
              onChange={(e) => handleTextFieldChange("lat", e.target.value)}
              value={markerPosition.lat}
              type="number"
              step="any" // Allow decimal values
            />

            <TextField
              fullWidth
              id="longitude"
              name="longitude"
              label="Longitude"
              onChange={(e) => handleTextFieldChange("lng", e.target.value)}
              value={markerPosition.lng}
              type="number"
              step="any" // Allow decimal values
            />
          </Div>
          <Div sx={{ mb: 2, mt: 1 }}>
            <MapContainer
              center={[0, 37]}
              zoom={6}
              style={{ height: "250px", width: "100%" }}
              onClick={handleMapClick}
              scrollWheelZoom={true}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />

              <MapClickHandler />

              <Marker
                position={[
                  markerPosition.lat === undefined ? 0 : markerPosition.lat,
                  markerPosition.lng === undefined ? 0 : markerPosition.lng,
                ]}
              >
                <Popup>
                  Marker Position: ({markerPosition.lat}, {markerPosition.lng})
                </Popup>
              </Marker>
            </MapContainer>
          </Div> */}

          <Grid container spacing={3} mb={1}>
            <Grid item xs={6}>
              <Button onClick={onClose} variant="contained">
                Cancel
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                color="secondary"
                variant="contained"
                size="large"
                sx={{ mb: 3 }}
                //   loading={loading}
                onClick={() => {
                  // navigate("/");
                  handleFieldErrors();
                  // setLoading(true);
                }}
                // disabled={formik.isValidating || !formik.dirty || !formik.isValid}
              >
                Update Activity
              </Button>
            </Grid>
          </Grid>
        </form>
      </Div>
    )
  );
};
PSUpdateActivity.defaultProps = {
  onSave: () => {},
};
export default PSUpdateActivity;
