import { Button } from "@mui/material";
import React from "react";
import Div from "@jumbo/shared/Div";

export const ThePlan = ({
  manifestoTrackingCategory,
  setManifestoTrackingCategory,
  setCurrentPage,
  navigate
}) => {
  return (
    <Div>
      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Agriculture"}
        size={"small"}
        color="successLight"
        variant={
          manifestoTrackingCategory &&
          manifestoTrackingCategory === "Agriculture"
            ? "contained"
            : "outlined"
        }
        onClick={() => {
          setCurrentPage(1);
          setManifestoTrackingCategory("Agriculture");
        }}
      >
        Agriculture
      </Button>

      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Transforming MSMEs"}
        size={"small"}
        color="successLight"
        variant={
          manifestoTrackingCategory &&
          manifestoTrackingCategory === "Transforming MSMEs"
            ? "contained"
            : "outlined"
        }
        onClick={() => {
          setCurrentPage(1);
          setManifestoTrackingCategory("Transforming MSMEs");
        }}
      >
        Transforming MSMEs
      </Button>

      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Affordable Housing"}
        size={"small"}
        color="successLight"
        variant={
          manifestoTrackingCategory &&
          manifestoTrackingCategory === "Affordable Housing"
            ? "contained"
            : "outlined"
        }
        onClick={() => {
          setManifestoTrackingCategory("Affordable Housing");
          setCurrentPage(1);
        }}
      >
        Affordable Housing
      </Button>

      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Health Care"}
        size={"small"}
        color="successLight"
        variant={
          manifestoTrackingCategory &&
          manifestoTrackingCategory === "Health Care"
            ? "contained"
            : "outlined"
        }
        onClick={() => {
          setCurrentPage(1);
          setManifestoTrackingCategory("Health Care");
        }}
      >
        Health Care
      </Button>

      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Digital Superhighway and Creative Economy"}
        size={"small"}
        color="successLight"
        variant={
          manifestoTrackingCategory &&
          manifestoTrackingCategory ===
            "Digital Superhighway and Creative Economy"
            ? "contained"
            : "outlined"
        }
        onClick={() => {
          setCurrentPage(1);
          setManifestoTrackingCategory(
            "Digital Superhighway and Creative Economy"
          );
        }}
      >
        Digital Superhighway and Creative Economy
      </Button>

      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Infrastructure"}
        size={"small"}
        color="successLight"
        variant={
          manifestoTrackingCategory &&
          manifestoTrackingCategory === "Infrastructure"
            ? "contained"
            : "outlined"
        }
        onClick={() => {
          setCurrentPage(1);
          setManifestoTrackingCategory("Infrastructure");
        }}
      >
        Infrastructure
      </Button>

      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Manufacturing"}
        size={"small"}
        color="successLight"
        variant={
          manifestoTrackingCategory &&
          manifestoTrackingCategory === "Manufacturing"
            ? "contained"
            : "outlined"
        }
        onClick={() => {
          setCurrentPage(1);
          setManifestoTrackingCategory("Manufacturing");
        }}
      >
        Manufacturing
      </Button>

      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Service Economy"}
        size={"small"}
        color="successLight"
        variant={
          manifestoTrackingCategory &&
          manifestoTrackingCategory === "Service Economy"
            ? "contained"
            : "outlined"
        }
        onClick={() => {
          setCurrentPage(1);
          setManifestoTrackingCategory("Service Economy");
        }}
      >
        Service Economy
      </Button>

      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Education"}
        size={"small"}
        color="successLight"
        variant={
          manifestoTrackingCategory && manifestoTrackingCategory === "Education"
            ? "contained"
            : "outlined"
        }
        onClick={() => {
          setCurrentPage(1);
          setManifestoTrackingCategory("Education");
        }}
      >
        Education
      </Button>

      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Environment and Climate Change"}
        size={"small"}
        color="successLight"
        variant={
          manifestoTrackingCategory &&
          manifestoTrackingCategory === "Environment and Climate Change"
            ? "contained"
            : "outlined"
        }
        onClick={() => {
          setCurrentPage(1);
          setManifestoTrackingCategory("Environment and Climate Change");
        }}
      >
        Environment and Climate Change
      </Button>
      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Women"}
        size={"small"}
        color="successLight"
        variant={
          manifestoTrackingCategory && manifestoTrackingCategory === "Women"
            ? "contained"
            : "outlined"
        }
        onClick={() => {
          setCurrentPage(1);
          setManifestoTrackingCategory("Women");
        }}
      >
        Women
      </Button>

      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Social Protection"}
        size={"small"}
        color="successLight"
        variant={
          manifestoTrackingCategory &&
          manifestoTrackingCategory === "Social Protection"
            ? "contained"
            : "outlined"
        }
        onClick={() => {
          setCurrentPage(1);
          setManifestoTrackingCategory("Social Protection");
        }}
      >
        Social Protection
      </Button>

      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Governance"}
        size={"small"}
        color="successLight"
        variant={
          manifestoTrackingCategory &&
          manifestoTrackingCategory === "Governance"
            ? "contained"
            : "outlined"
        }
        onClick={() => {
          setCurrentPage(1);
          setManifestoTrackingCategory("Governance");
        }}
      >
        Governance
      </Button>

      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Devolution"}
        size={"small"}
        color="successLight"
        variant={
          manifestoTrackingCategory &&
          manifestoTrackingCategory === "Devolution"
            ? "contained"
            : "outlined"
        }
        onClick={() => {
          setCurrentPage(1);
          setManifestoTrackingCategory("Devolution");
        }}
      >
        Devolution
      </Button>

      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Security Services"}
        size={"small"}
        color="successLight"
        variant={
          manifestoTrackingCategory &&
          manifestoTrackingCategory === "Security Services"
            ? "contained"
            : "outlined"
        }
        onClick={() => {
          setCurrentPage(1);
          setManifestoTrackingCategory("Security Services");
        }}
      >
        Security Services
      </Button>

      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Diaspora"}
        size={"small"}
        color="successLight"
        variant={
          manifestoTrackingCategory && manifestoTrackingCategory === "Diaspora"
            ? "contained"
            : "outlined"
        }
        onClick={() => {
          setCurrentPage(1);
          setManifestoTrackingCategory("Diaspora");
        }}
      >
        Diaspora
      </Button>
      <Button
        sx={{ m: 1, fontWeight: "medium" }}
        key={"Clear"}
        size={"small"}
        variant={"contained"}
        color="error"
        onClick={() => {
          navigate("/directives?page=1");
          setManifestoTrackingCategory("");
        }}
      >
        Clear Filters
      </Button>
    </Div>
  );
};
