import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Box } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { min_state_inst } from "app/shared/Components/institutions";
import * as yup from "yup";
import _ from "@lodash";
import axios from "axios";
import { backend_url } from "app/services/constants";
import { useFormik } from "formik";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { useNavigate } from "react-router-dom";

export default function LeadAgency({ token, dir_id }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedMinistry, setSelectedMinistry] = React.useState("");
  const [filteredMinistry, setFilteredMinistry] = React.useState([]);
  const [showErrors, setShowErrors] = React.useState(false);

  const Swal = useSwalWrapper();
  const navigate = useNavigate();

  const handleMinistryChange = (newValue) => {
    const selectedMinistry = newValue;

    const filteredMinistries = min_state_inst.filter(
      (item) => item.Ministry === selectedMinistry
    );

    setFilteredMinistry(filteredMinistries);
    setSelectedMinistry(selectedMinistry);
  };

  const ministries = _.uniqWith(
    min_state_inst.map((min) => min.Ministry),
    _.isEqual
  );

  const department =
    filteredMinistry &&
    _.uniqWith(
      filteredMinistry.map((department) => department.State_Departments),
      _.isEqual
    );

  const validationSchema = yup.object({
    State_departments: yup
      .string()
      .required("You must enter your state department")
      .oneOf(
        department !== false ? department : [],
        "Please select the state department"
      ),
  });

  const defaultValues = {
    State_departments: "None",
  };

  function submit({ State_departments }) {
    const dirDetails = {
      directive_no: dir_id,
      lead_agency: State_departments,
    };

    axios
      .put(
        `${backend_url}/ppi/other_agencies/`,
        dirDetails,
        {
          headers: {
            // "Content-Type": "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Agency added successfully.",
          showConfirmButton: false,
          timer: 4500,
        });
        setLoading(false);
        handleClose();
        window.location.reload();
        // navigate("/directives");
        // trip_id === undefined ? window.location.reload() : handleNext();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: err.response.data?.details,
          showConfirmButton: false,
          timer: 4500,
        });
        setLoading(false);
      });
  }

  const formik = useFormik({
    initialValues: { defaultValues },
    validationSchema,
    onSubmit: submit,
  });

  const handleFieldErrors = () => {
    setShowErrors(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button sx={{ ml: 2 }} variant="contained" onClick={handleClickOpen}>
        Add a collaborating agency
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Add a collaborating agency</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            {min_state_inst && (
              <Div sx={{ mb: 2, mt: 1 }}>
                <Autocomplete
                  fullWidth
                  id="ministry"
                  name="ministry"
                  options={Array.from(
                    new Set(min_state_inst.map((item) => item.Ministry))
                  )
                    .map((ministry) => ministry)
                    .sort()}
                  value={formik.values.ministry}
                  onChange={(e, newValue) => {
                    formik.setFieldValue("ministry", newValue);
                    handleMinistryChange(newValue);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ministry"
                      error={
                        (showErrors || formik.touched.ministry) &&
                        Boolean(formik.errors.ministry)
                      }
                      helperText={
                        (showErrors || formik.touched.ministry) &&
                        formik.errors.ministry
                      }
                    />
                  )}
                />
              </Div>
            )}

            {selectedMinistry && (
              <Div sx={{ mb: 2, mt: 1 }}>
                <Autocomplete
                  fullWidth
                  id="State_departments"
                  name="State_departments"
                  options={department.sort()}
                  value={formik.values.State_departments}
                  onChange={(e, newValue) => {
                    formik.setFieldValue("State_departments", newValue);
                    // handleStateChange(newValue);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State Departments"
                      error={
                        (showErrors || formik.touched.State_departments) &&
                        Boolean(formik.errors.State_departments)
                      }
                      helperText={
                        (showErrors || formik.touched.State_departments) &&
                        formik.errors.State_departments
                      }
                    />
                  )}
                />
              </Div>
            )}
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button color="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" color="secondary" variant="outlined">
                Submit
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
