import React from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CardHeader from "@mui/material/CardHeader";
import {
  alpha,
  Button,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import Divider from "@mui/material/Divider";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import Div from "@jumbo/shared/Div";
import { LeafLayers } from "app/pages/maps/LeafLayers";

const PSActivityDetail = ({ contact, onClose, activity }) => {
  return (
    <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={activity.properties.name}
        subheader={`${activity?.properties.user.first_name} ${activity?.properties.user.last_name}`}
        avatar={<Avatar src={contact?.profile_pic} />}
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <List disablePadding>
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15),
              }}
            >
              <CorporateFareIcon sx={{ color: "primary.light" }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={"body1"} color={"text.secondary"} mb={0.5}>
                Description
              </Typography>
            }
            secondary={
              <Typography variant={"h5"} mb={0}>
                {activity.properties.description}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={"li"} />
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15),
              }}
            >
              <CorporateFareIcon sx={{ color: "primary.light" }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={"body1"} color={"text.secondary"} mb={0.5}>
                Status
              </Typography>
            }
            secondary={
              <Typography variant={"h5"} mb={0}>
                {activity.properties.status_activities}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={"li"} />
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15),
              }}
            >
              <MailOutlineIcon sx={{ color: "primary.light" }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={"body1"} color={"text.secondary"} mb={0.5}>
                Start Date
              </Typography>
            }
            secondary={
              <Typography variant={"h5"} mb={0}>
                {activity.properties.start_date}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={"li"} />
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15),
              }}
            >
              <MailOutlineIcon sx={{ color: "primary.light" }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={"body1"} color={"text.secondary"} mb={0.5}>
                End Date
              </Typography>
            }
            secondary={
              <Typography variant={"h5"} mb={0}>
                {activity.properties.end_date}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={"li"} />
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15),
              }}
            >
              <LabelOutlinedIcon sx={{ color: "primary.light" }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={"body1"} color={"text.secondary"} mb={0.5}>
                Budget
              </Typography>
            }
            secondary={
              <Typography component={"div"}>
                <JumboChipsGroup
                  spacing={1}
                  size={"small"}
                  chips={contact?.labels}
                  mapKeys={{ label: "name", color: "color" }}
                />
                {activity.properties.budget_activities}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={"li"} />
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15),
              }}
            >
              <LabelOutlinedIcon sx={{ color: "primary.light" }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={"body1"} color={"text.secondary"} mb={0.5}>
                Weight
              </Typography>
            }
            secondary={
              <Typography component={"div"}>
                <JumboChipsGroup
                  spacing={1}
                  size={"small"}
                  chips={contact?.labels}
                  mapKeys={{ label: "name", color: "color" }}
                />
                {activity.properties.weighting}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={"li"} />

        {activity.properties !== undefined && activity.geometry !== null && (
          <Div sx={{ mt: 4 }}>
            <Typography variant={"h2"} my={2} mx={2}>
              Activity Location
            </Typography>

            <LeafLayers geom={activity.geometry} />
          </Div>
        )}
        <Divider component={"li"} />
      </List>
      <Stack spacing={1} direction={"row"} sx={{ px: 4, py: 2 }}>
        <Grid container spacing={1} mb={1}>
          <Grid item xs={6}>
            {/* <Button
              color="error"
              variant="contained"
            >
              Delete Activity
            </Button> */}
          </Grid>
          <Grid item xs={6}>
            <Div
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              onClick={onClose}
            >
              <Button variant="contained" size="large" color="secondary">
                Close
              </Button>
            </Div>
          </Grid>
        </Grid>
      </Stack>
    </Div>
  );
};

export default PSActivityDetail;
