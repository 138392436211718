import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& .MuiTableCell-root": {
    borderBottom: "none",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  textDecoration: "none",

  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    cursor: "pointer",
  },

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

export default function CountiesInfo({
  countyList,
  counties,
  setCounties,
}) {
  const handleClose = () => {
    setCounties(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={counties}
        // maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Directives per county
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <JumboScrollbar
            autoHeight={400}
            autoHideTimeout={4000}
            autoHeightMin={450}
            autoHide={true}
            hideTracksWhenNotNeeded
          >
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ pl: 3, fontWeight: "bold" }}>
                    County
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ pr: 3, fontWeight: "bold" }}
                    align={"right"}
                  >
                    Directives
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(countyList)
                  .filter(
                    ([county]) => county !== "null" && county !== "National"
                  )
                  .sort((a, b) => a[0].localeCompare(b[0]))
                  .map(([county, count], index) => (
                    <StyledTableRow
                      key={index}
                      component={Link}
                      to={`/directives?county=${county}`}
                    >
                      <TableCell
                        sx={{
                          pl: (theme) => theme.spacing(3),
                        }}
                      >
                        {county}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          pr: (theme) => theme.spacing(3),
                        }}
                      >
                        {count}
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </JumboScrollbar>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
