import { useEffect } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import { backend_url } from "app/services/constants";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

function SignOut(props) {
  const { token } = props;
  const Swal = useSwalWrapper();

  useEffect(() => {
    (async () => {
      try {
        await axios.post(
          `${backend_url}/auth/user/logout/`,
          {
            refresh: localStorage.getItem("refresh_token"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
          { withCredentials: true }
        );
        localStorage.clear();
        axios.defaults.headers.common["Authorization"] = null;
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Signed Out successfully.",
          showConfirmButton: false,
          timer: 4500,
        });
        window.location.href = "/login";
      } catch (e) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: e.response.data?.detail,
          showConfirmButton: false,
          timer: 4500,
        });
      }
    })();
  }, [token, Swal]);

  return (
    <Container component="main" maxWidth="xl">
      <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-32 mt-56">
        <Paper className="flex w-full sm:w-auto min-h-full sm:min-h-auto md:w-full md:max-w-6xl rounded-0 sm:rounded-2xl sm:shadow overflow-hidden">
          <div className="flex items-center w-full sm:w-auto py-32 px-16 sm:p-48 md:p-64 ltr:border-r-1 rtl:border-l-1">
            <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
              <Typography
                variant="p"
                component="p"
                className="pt-16 text-2xl font-extrabold tracking-tight leading-tight"
              >
                You have signed out!
              </Typography>
              <Typography
                variant="p"
                component="p"
                className="flex justify-center mt-2 font-small"
              >
                Redirecting in 5 seconds
              </Typography>

              <Typography
                variant="p"
                component="p"
                className="mt-32 text-md font-small text-center"
                color="text.secondary"
              >
                <span>Go to</span>
                <Link className="ml-4" to="/sign-in">
                  sign in
                </Link>
              </Typography>
            </div>
          </div>
        </Paper>
      </div>
    </Container>
  );
}

export default SignOut;
