import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import "../../../../styles/leaf.css";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { List, ListItem, Typography } from "@mui/material";
import { backend_url } from "app/services/constants";
import Legend from "./Legend";

const spectralColors = [
  "#5E4FA2", // Dark purple
  "#2D9BC1", // Blue
  "#79CD98", // Light green
  "#F8F75A", // Yellow
  "#F1B226", // Orange
  "#D01C8B", // Pink
  "#F46D43", // Red-orange
];

function LocationDirectives({ countyList, token }) {
  const [onSelect, setOnSelect] = useState({});
  const [geoData, setGeoData] = useState(null); // State to store the fetched geo data

  useEffect(() => {
    async function fetchData() {
      fetch(`${backend_url}/ppi/counties/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setGeoData(data);
        })
        .catch((error) => console.error("Error fetching geo data:", error));
    }
    fetchData();
  }, [token]);

  const getLargestCounty = (counts) => {
    if (Object.keys(counts).length === 0) return null;

    return Object.entries(counts).reduce(
      (max, entry) => (entry[1] > max[1] ? entry : max),
      ["", -Infinity]
    );
  };

  const result = getLargestCounty(countyList);
  const maxValue =
    Array.isArray(result) && result.length > 1 && result[1] !== null
      ? result[1]
      : 2;
  const quantileStep = Math.ceil(maxValue / 7);

  const quantileRanges = Array.from({ length: 5 }, (_, i) => ({
    min: i * quantileStep + 1,
    max: (i + 1) * quantileStep,
  }));

  const getColor = (count) => {
    for (let i = 0; i < quantileRanges.length; i++) {
      const { min, max } = quantileRanges[i];
      if (count >= min && count <= max) {
        return spectralColors[i];
      }
    }
    return "grey"; // Default color if count does not fall within any quantile range
  };

  const highlightFeature = (e) => {
    const layer = e.target;
    const { County, name } = e.target.feature.properties;
    const directivesCount = countyList[County];

    setOnSelect({
      County: County,
      name: name,
      directivesCount: directivesCount,
    });

    layer.setStyle({
      weight: 2,
      color: "black",
      fillOpacity: 0.7,
    });
  };

  const resetHighlight = (e) => {
    setOnSelect({});
    e.target.setStyle(getStyle(e.target.feature));
  };

  const getStyle = (feature) => {
    const countyName = feature.properties.County;
    const count = countyList[countyName] || 0;
    const color = getColor(count);

    return {
      fillColor: color,
      weight: 1,
      color: "white",
      dashArray: "3",
      fillOpacity: 0.7,
    };
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
    });
  };

  return (
    countyList && (
      <>
        <JumboCardQuick reverse wrapperSx={{ pb: 0 }}>
          <Typography fontWeight={"500"} variant={"h3"}>
            Directive Issued Locations
          </Typography>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "80vh",
            }}
          >
            {!onSelect.name && (
              <Typography fontWeight={"500"} variant="h4">
                Hover on each county for more details
              </Typography>
            )}
            {onSelect.name && (
              <List>
                <ListItem>
                  <Typography fontWeight={"500"} variant="h4">
                    {onSelect.name}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography fontWeight={"500"} variant="h4">
                    Directives:{" "}
                    {countyList[onSelect.County] !== undefined
                      ? countyList[onSelect.County]
                      : 0}
                  </Typography>
                </ListItem>
              </List>
            )}

            <MapContainer
              style={{
                position: "absolute",
              }}
              center={[0, 37]}
              maxBoundsViscosity={1.0}
              zoom={6}
              zoomControl={false}
              scrollWheelZoom={true}
              dragging={true}
              touchZoom={true}
              doubleClickZoom={true}
              boxZoom={true}
              keyboard={true}
            >
              <Legend
                token={token}
                spectralColors={spectralColors}
                quantileRanges={quantileRanges}
              />

              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png"
              />

              {geoData && (
                <GeoJSON
                  data={geoData}
                  style={getStyle}
                  onEachFeature={onEachFeature}
                />
              )}
            </MapContainer>
          </div>
        </JumboCardQuick>
      </>
    )
  );
}

export default LocationDirectives;

// const spectralColors = [
//   "#5E4FA2",
//   "#4881B6",
//   "#2D9BC1",
//   "#43BCA3",
//   "#79CD98",
//   "#BBDF79",
//   "#F8F75A",
//   "#F3E442",
//   "#F1B226",
//   "#D01C8B",
//   "#762A83",
//   "#7B3294",
//   "#8073AC",
//   "#92C5DE",
//   "#ABDDA4",
//   "#E6F598",
//   "#FFFFBF",
//   "#FEE08B",
//   "#FDAE61",
//   "#F46D43",
// ];
