import React from "react";
import Page from "@jumbo/shared/Page";
import { backend_url } from "./services/constants";
import { Navigate, Route, Routes } from "react-router-dom";
import ProjectsList from "./pages/directives/ProjectsList";
import DirDetails from "./pages/directives/directiveDetails/DirDetails";
import UserProfile from "./pages/users/user-profile/UserProfile";
import LoginPassword from "./pages/auth/login/LoginPassword";
import VerifyOTP from "./pages/auth/login/VerifyOTP";
import Signup from "./pages/auth/signup/Signup";
import PSDashboard from "./pages/dashboards/PS/PSDashboard";
import NewDirective from "./pages/dashboards/pppi/newDirective/newDirective";
import login from "./pages/auth/login";
import NewDirUpload from "./pages/dashboards/pppi/newDirective/NewDirUpload";
import SignOut from "./pages/auth/logout/SignOut";
import PriorityProjects from "./pages/directives/PriorityProjects";
import ReportsMain from "./pages/reports/ReportsMain";
import ForgotPassword from "./pages/auth/login/ForgotPassword";
import ResetPassword from "./pages/auth/login/ResetPassword";
import Confirmation from "./pages/auth/login/Confirmation";
import PasswordConfirmation from "./pages/auth/login/PasswordConfirmation";
import ConfirmRedirect from "./pages/auth/login/ConfirmRedirect";
import FAQPage from "./pages/faqs/FAQPage";
import CollabDirective from "./pages/directives/collabDirectives";
import Terms from "./pages/faqs/Terms";
import NotFound from "./shared/Components/NotFound";
import EditDirective from "./pages/dashboards/pppi/newDirective/editDirective";
import Matrix from "./pages/directives/matrix/Matrix";

const AppRoutes = () => {
  const [user, setUser] = React.useState("");
  const storedToken = localStorage.getItem("access_token");

  React.useEffect(() => {
    fetch(`${backend_url}/auth/user/me/`, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUser(data.user);
        localStorage.getItem("access_token");
        localStorage.setItem("designation", data.user.designation);
        data.code === "token_not_valid" && localStorage.clear();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [storedToken]);

  return (
    <React.Fragment>
      {user !== undefined && storedToken ? (
        <Routes>
          {user && user.designation !== "PS" && (
            <>
              <Route
                path="/summary"
                element={
                  <Page
                    component={ReportsMain}
                    isCS={user.designation === "CS" ? true : false}
                    isPS={user.designation === "PS" ? true : false}
                    ministryy={
                      user.ministry
                        ? user.ministry.replace(/\s/g, "+").toUpperCase()
                        : ""
                    }
                    leadAgency={
                      user.State_departments
                        ? user.State_departments.replace(
                            /\s/g,
                            "+"
                          ).toUpperCase()
                        : ""
                    }
                    user={user}
                    token={storedToken}
                  />
                }
              />

              <Route
                path="/directives"
                element={
                  <Page
                    component={ProjectsList}
                    user={user}
                    token={storedToken}
                  />
                }
              />

              <Route
                path="/priority-projects"
                element={
                  <Page
                    component={PriorityProjects}
                    user={user}
                    token={storedToken}
                  />
                }
              />

              <Route
                path="directive/:id"
                element={
                  <Page
                    component={DirDetails}
                    user={user}
                    token={storedToken}
                  />
                }
              />
              <Route path="/faqs" element={<Page component={FAQPage} />} />
            </>
          )}

          {user &&
            (user.designation === "PPI_1" ||
              user.designation === "PPI_1" ||
              user.designation === "PPI_3") && (
              <Route
                path="/matrix"
                element={
                  <Page component={Matrix} user={user} token={storedToken} />
                }
              />
            )}

          <Route
            path="/directive/new"
            element={
              <Page component={NewDirective} user={user} token={storedToken} />
            }
          />

          <Route
            path="/directive/edit/:id"
            element={
              <Page component={EditDirective} user={user} token={storedToken} />
            }
          />

          <Route
            path="/directive/upload"
            element={
              <Page component={NewDirUpload} user={user} token={storedToken} />
            }
          />

          <Route path="/faqs" element={<Page component={FAQPage} />} />

          {user && user.designation === "PS" && (
            <>
              <Route
                path="/directives"
                element={
                  <Page
                    component={PSDashboard}
                    user={user}
                    token={storedToken}
                  />
                }
              />

              <Route
                path="/collaborating-directives"
                element={
                  <Page
                    component={CollabDirective}
                    user={user}
                    token={storedToken}
                  />
                }
              />

              <Route
                path="directive/:id"
                element={
                  <Page
                    component={DirDetails}
                    user={user}
                    token={storedToken}
                  />
                }
              />

              <Route
                path="/summary"
                element={
                  <Page
                    component={ReportsMain}
                    isCS={user.designation === "CS" ? true : false}
                    isPS={user.designation === "PS" ? true : false}
                    ministryy={
                      user.ministry
                        ? user.ministry.replace(/\s/g, "+").toUpperCase()
                        : ""
                    }
                    leadAgency={
                      user.State_departments
                        ? user.State_departments.replace(
                            /\s/g,
                            "+"
                          ).toUpperCase()
                        : ""
                    }
                    user={user}
                    token={storedToken}
                  />
                }
              />
            </>
          )}

          <Route
            path="profile/"
            element={
              <Page component={UserProfile} user={user} token={storedToken} />
            }
          />

          <Route path="/faqs" element={<Page component={FAQPage} />} />

          <Route
            path="logout/"
            element={
              <Page component={SignOut} user={user} token={storedToken} />
            }
          />
        </Routes>
      ) : (
        <Routes>
          <Route
            path="/signup"
            element={<Page component={Signup} layout={"solo-page"} />}
          />
          <Route
            path="/login"
            element={<Page component={login} layout={"solo-page"} />}
          />
          <Route
            path="/login/password"
            element={<Page component={LoginPassword} layout={"solo-page"} />}
          />
          <Route
            path="/verify-otp"
            element={<Page component={VerifyOTP} layout={"solo-page"} />}
          />
          <Route
            path="/forgot-password"
            element={<Page component={ForgotPassword} layout={"solo-page"} />}
          />
          <Route
            path="/auth/user/password_reset/"
            element={<Page component={ResetPassword} layout={"solo-page"} />}
          />
          <Route
            path="/confirmation"
            element={<Page component={Confirmation} layout={"solo-page"} />}
          />
          <Route
            path="/password-confirmation"
            element={
              <Page component={PasswordConfirmation} layout={"solo-page"} />
            }
          />
          <Route
            path="/auth/user/verify_email"
            element={<Page component={ConfirmRedirect} layout={"solo-page"} />}
          />
          <Route
            path="/terms-and-conditions"
            element={<Page component={Terms} layout={"solo-page"} />}
          />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/:id" element={<Navigate to="/login" />} />
          <Route path=":id" element={<Navigate to="/login" />} />
          <Route path="/directives" element={<Navigate to="/login" />} />
          <Route path="/hops" element={<Navigate to="/login" />} />
          <Route path="/private-trip" element={<Navigate to="/login" />} />
          <Route path="/checking/edit/:id" element={<Navigate to="/login" />} />
          <Route path="/ceo-cs-ps" element={<Navigate to="/login" />} />
          <Route path="/form" element={<Navigate to="/login" />} />
          <Route path="/form-detail" element={<Navigate to="/login" />} />
          <Route path="*" element={<NotFound />} /> {/* Add the 404 route */}
        </Routes>
      )}

      {/* {appRoutes} */}
    </React.Fragment>
  );
};
export default AppRoutes;
