import React, { useState } from "react";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import { backend_url } from "app/services/constants";
import { useFormik } from "formik";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const NewComment = ({ token, directive_no, setUpdated }) => {
  const [loading, setLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const Swal = useSwalWrapper();

  const validationSchema = yup.object({
    comments: yup.string().required("Add a comment."),
  });

  const defaultValues = {
    comments: "",
  };

  function submit({ comments }, { resetForm }) {
    const commentDetails = {
      id: directive_no,
      message: comments,
    };

    axios
      .put(
        `${backend_url}/ppi/comments/`,
        commentDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Comment added successfully.",
          showConfirmButton: false,
          timer: 4500,
        });
        setUpdated(res.data);
        setLoading(false);
        resetForm();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: err.response.data?.details,
          showConfirmButton: false,
          timer: 4500,
        });
        setLoading(false);
      });
  }

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema,
    onSubmit: submit,
  });

  const handleFieldErrors = () => {
    setShowErrors(true);
  };

  return (
    <Div sx={{ p: 4 }}>
      <Typography variant={"h2"} mb={3}>
        Add a comment
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Div sx={{ mb: 2, mt: 1 }}>
          <TextField
            fullWidth
            id="comments"
            name="comments"
            label="Comment"
            multiline={3}
            minRows={2}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.comments}
            error={
              (showErrors || formik.touched.comments) &&
              Boolean(formik.errors.comments)
            }
            helperText={
              (showErrors || formik.touched.comments) && formik.errors.comments
            }
          />
        </Div>

        <Box maxWidth={128}>
          <Button
            fullWidth
            type="submit"
            color="secondary"
            variant="contained"
            size="medium"
            sx={{ my: 2 }}
            loading={loading}
            onClick={() => {
              handleFieldErrors();
            }}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Div>
  );
};

export default NewComment;
