import React from "react";
import List from "@mui/material/List";
import CommentItem from "./CommentItem";

const CommentsList = ({ commentList, user }) => {
  return (
    <List disablePadding>
      {commentList.map((item, index) => (
        <CommentItem user={user} item={item} key={index} />
      ))}
    </List>
  );
};

export default CommentsList;
