import React from "react";
import Countdown from "react-countdown";
import { Typography, Grid, Paper } from "@mui/material";
import Div from "@jumbo/shared/Div";

const CountdownTimer = () => {
  const targetDate = new Date("2027-08-10T00:00:00");

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Typography variant="h4">Countdown expired!</Typography>;
    }

    const currentDate = new Date();
    const remainingMilliseconds = targetDate - currentDate;
    const remainingYears = Math.floor(
      remainingMilliseconds / (365 * 24 * 60 * 60 * 1000)
    );

    const currentYear = currentDate.getFullYear();
    const yearStartDate = new Date(currentYear, 0, 1);
    const totalDaysInYear = Math.floor(
      (new Date(currentYear + 1, 0, 1) - yearStartDate) / (24 * 60 * 60 * 1000)
    );
    const daysRemainingInCurrentYear =
      totalDaysInYear -
      Math.floor((currentDate - yearStartDate) / (24 * 60 * 60 * 1000));

    return (
      <Grid container spacing={2} justifyContent="center">
        {remainingYears > 0 && (
          <Grid item xs={2}>
            <Typography color={"secondary"} variant="h4">
              {remainingYears}
            </Typography>
            <Typography sx={{ fontSize: "14px" }} variant="subtitle1">
              Years
            </Typography>
          </Grid>
        )}
        <Grid item xs={2}>
          <Typography color={"secondary"} variant="h4">
            {daysRemainingInCurrentYear}
          </Typography>
          <Typography sx={{ fontSize: "14px" }} variant="subtitle1">
            Days
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color={"secondary"} variant="h4">
            {hours}
          </Typography>
          <Typography sx={{ fontSize: "14px" }} variant="subtitle1">
            Hours
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color={"secondary"} variant="h4">
            {minutes}
          </Typography>
          <Typography sx={{ fontSize: "14px" }} variant="subtitle1">
            Minutes
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color={"secondary"} variant="h4">
            {seconds}
          </Typography>
          <Typography sx={{ fontSize: "14px" }} variant="subtitle1">
            Seconds
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Div sx={{ px: 4 }}>
      {/* <Typography variant="h3" gutterBottom>
        Countdown Timer
      </Typography> */}
      <Countdown s date={targetDate} renderer={renderer} />
    </Div>
  );
};

export default CountdownTimer;
