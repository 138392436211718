import React from "react";
import ProjectItem from "./ProjectItem";
import {
  Button,
  Chip,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { backend_url } from "app/services/constants";
import { useState } from "react";
import DirectivesFilter from "./directivesFilter";
import Div from "@jumbo/shared/Div";
import { ThePlan } from "./ThePlan";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Pagination from "./Pagination";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useSearchParams } from "react-router-dom/dist";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CollabDirective = ({ token, onSearch, user }) => {
  const [weights, setWeights] = useState([]);
  const [open, setOpen] = useState(false);
  const [collaborating, setCollaborating] = React.useState([]);

  const theme = useTheme();
  let query = useQuery();
  const implementation_status = query.get("implementation_status");
  const cost = query.get("cost");

  const [costMin, setCostMin] = useState(0);
  const [costMax, setCostMax] = useState(cost !== null ? cost : 900000000);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageFromUrl = searchParams.get("page") || 1;
  const searchQueryFromUrl = searchParams.get("search") || "";
  const countyFromUrl = searchParams.get("county") || "";
  const implementationStatusFromUrl = searchParams.get("status") || "";
  const manifestoCategoryFromUrl = searchParams.get("plan") || "";
  const betaPillarFromUrl = searchParams.get("beta") || "";
  const directiveCategoryFromUrl = searchParams.get("cat") || "";
  const issueDateFromUrl = searchParams.get("issue") || "";
  const targetDateFromUrl = searchParams.get("target") || "";
  const ministryFromUrl = searchParams.get("ministry") || "";
  const leadAgencyFromUrl = searchParams.get("state_dep") || "";

  const [searchQuery, setSearchQuery] = useState("");
  const [manifestoTrackingCategory, setManifestoTrackingCategory] =
    useState("");
  const [selectedMinistry, setSelectedMinistry] = useState(ministryFromUrl);
  const [leadAgency, setLeadAgency] = useState(leadAgencyFromUrl);
  const [betaPillar, setBetaPillar] = useState(betaPillarFromUrl);
  const [directiveCategory, setDirectiveCategory] = useState(
    directiveCategoryFromUrl
  );
  const [dateIssue, setDateIssue] = useState(issueDateFromUrl);
  const [targetDate, setTargetDate] = useState(targetDateFromUrl);
  const [implementationStatus, setImplementationStatus] = useState(
    implementationStatusFromUrl
  );
  const [county, setCounty] = useState(countyFromUrl);

  const [costRange, setCostRange] = useState([
    0,
    cost !== null ? cost : 900000000,
  ]);

  // const [minCost, setMinCost] =useState(cost[0])
  // const [maxCost, setMaxCost] =useState(cost !== null ? cost : 900000000)
  // const [costRange, setCostRange] = useState([
  //   0,
  //   cost !== null ? cost : 900000000,
  // ]);

  const page = query.get("page");
  const plan = query.get("plan");
  const ministry = query.get("ministry");
  const state_dep = query.get("state_dep");
  const county1 = query.get("county");
  const betaPillar1 = query.get("beta");
  const directiveCategory1 = query.get("cat");
  const dateIssue1 = query.get("issue");
  const targetDate1 = query.get("target");
  const implementationStatus1 = query.get("status");

  // const url = `${backend_url}/ppi/directive/?${filter}`;

  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = weights.count;
  const links = weights.links;
  const limit = 30;

  const totalPages = Math.ceil(totalItems / 50);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCostChange = (event, newValue) => {
    setCostRange(newValue);
  };

  const url = `${backend_url}/ppi/weights/`;

  useEffect(() => {
    async function fetchData() {
      const offset = (currentPage - 1) * limit;
      const params = new URLSearchParams({ limit: limit, offset: offset });

      const addParam = (key, value) => {
        if (value) params.append(key, value);
      };

      // Add all search/filter parameters to the request
      addParam("summary_name__icontains", searchQuery);
      addParam("page", page);
      addParam("county", county1);
      addParam("implementation_status", implementationStatus1);
      addParam("manifesto_tracking_category", plan);
      addParam("beta_pillar", betaPillar1);
      addParam("ministry", ministry);
      addParam("lead_agency", state_dep);
      addParam("date_issue", dateIssue1);
      addParam("target_date", targetDate1);
      addParam("directive_category", directiveCategory1);

      // Fetch data from the API with the search/filter parameters
      fetch(`${url}?${params.toString()}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setWeights(data));
    }

    // Fetch data when the page or any search/filter parameters change
    fetchData();

    // Update the URL with all the current search/filter parameters
    const updatedSearchParams = new URLSearchParams({
      page: currentPage,
      search: searchQuery || "",
      plan: manifestoTrackingCategory || "",
      ministry: ministry || "",
      state_dep: leadAgency || "",
      county: county || "",
      status: implementationStatus || "",
      beta: betaPillar || "",
      issue: dateIssue || "",
      target: targetDate || "",
      cat: directiveCategory || "",
    });

    // Update the URL with the search parameters
    setSearchParams(updatedSearchParams);
  }, [
    token,
    setWeights,
    currentPage,
    searchQuery,
    betaPillar,
    dateIssue,
    targetDate,
    directiveCategory,
    county,
    manifestoTrackingCategory,
    implementationStatus,
    ministry,
    leadAgency,
    setSearchParams,
    betaPillar1,
    county1,
    dateIssue1,
    directiveCategory1,
    implementationStatus1,
    plan,
    state_dep,
    targetDate1,
    url,
  ]);

  // Set the initial state from the URL parameters (you could also do this inside the component state)
  useEffect(() => {
    setCurrentPage(pageFromUrl);
    setSearchQuery(searchQueryFromUrl);
    setCounty(countyFromUrl);
    setBetaPillar(betaPillarFromUrl);
    setDateIssue(issueDateFromUrl);
    setTargetDate(targetDateFromUrl);
    setDirectiveCategory(directiveCategoryFromUrl);
    setImplementationStatus(implementationStatusFromUrl);
    setManifestoTrackingCategory(manifestoCategoryFromUrl);
    setSelectedMinistry(ministryFromUrl);
    setLeadAgency(leadAgencyFromUrl);
  }, []);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/ppi/other_agencies/?` +
          new URLSearchParams({
            // directive_no: id,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setCollaborating(data));
    }

    fetchData();
  }, [token]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    onSearch(searchQuery);
  };

  return (
    <Div>
      {open !== true && (
        <JumboCardQuick
          title={
            <Div>
              <Typography
                variant={"h2"}
                mb={0}
                sx={{
                  minWidth: 245,
                  [theme.breakpoints.down("md")]: {
                    minWidth: "100%",
                    marginBottom: 2,
                  },
                }}
              >
                The Plan
              </Typography>

              <Stack direction={"row"} spacing={1}>
                {weights.results !== undefined && (
                  <ThePlan
                    manifestoTrackingCategory={manifestoTrackingCategory}
                    setManifestoTrackingCategory={setManifestoTrackingCategory}
                    token={token}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              </Stack>
            </Div>
          }
          wrapperSx={{
            "&:last-child": {
              pb: 0,
            },
            "& .MuiCollapse-entered:last-child": {
              "& .MuiListItemButton-root": {
                borderBottom: 0,
                borderBottomColor: "transparent",
              },
            },
          }}
        />
      )}
      <Div sx={{ my: 1 }} />

      <JumboCardQuick
        title={
          <TextField
            variant="outlined"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            size="small"
            sx={{ mr: 2 }}
            fullWidth
          />
        }
        action={
          <IconButton color="primary" onClick={handleSearch} sx={{ mr: 1 }}>
            <SearchIcon />
          </IconButton>
        }
        headerSx={{
          "& .MuiCardHeader-action": {
            my: -0.75,
          },
        }}
        wrapperSx={{
          p: 0,
          "&:last-child": {
            pb: 0,
          },
          "& .MuiCollapse-entered:last-child": {
            "& .MuiListItemButton-root": {
              borderBottom: 0,
              borderBottomColor: "transparent",
            },
          },
        }}
      />

      <Div sx={{ my: 1 }} />

      <JumboCardQuick
        title={
          <Typography
            component={"div"}
            sx={{
              display: "flex",
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                flexWrap: "wrap",
              },
            }}
          >
            <Typography
              variant={"h3"}
              mb={0}
              sx={{
                minWidth: 245,
                [theme.breakpoints.down("md")]: {
                  minWidth: "100%",
                  marginBottom: 2,
                },
              }}
            >
              Collaborating Directives
              {collaborating !== null ? (
                <Chip
                  sx={{ mx: 2 }}
                  size={"medium"}
                  label={collaborating.count}
                  color="primary"
                />
              ) : (
                <Chip
                  sx={{ mx: 2 }}
                  size={"medium"}
                  label="0"
                  color="primary"
                />
              )}
            </Typography>
          </Typography>
        }
        action={
          <Button
            sx={{ m: 1, fontWeight: "medium" }}
            size={"medium"}
            color={open === true ? "error" : "secondary"}
            variant="contained"
            onClick={() => (open === true ? setOpen(false) : setOpen(true))}
          >
            {open === true ? "Hide Filter Directives" : " Filter Directives"}
          </Button>
        }
        headerSx={{
          "& .MuiCardHeader-action": {
            my: -0.75,
          },
        }}
        wrapperSx={{
          p: 0,
          "&:last-child": {
            pb: 0,
          },
          "& .MuiCollapse-entered:last-child": {
            "& .MuiListItemButton-root": {
              borderBottom: 0,
              borderBottomColor: "transparent",
            },
          },
        }}
      >
        {open === true && weights.results !== undefined && (
          <DirectivesFilter
            costMax={costMax}
            costMin={costMin}
            setCostMax={setCostMax}
            setCostMin={setCostMin}
            costRange={costRange}
            weights={weights}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            open={open}
            setOpen={setOpen}
            token={token}
            setWeights={setWeights}
            setSelectedMinistry={setSelectedMinistry}
            selectedMinistry={selectedMinistry}
            setSelectedState={setLeadAgency}
            selectedState={leadAgency}
            manifestoTrackingCategory={manifestoTrackingCategory}
            setManifestoTrackingCategory={setManifestoTrackingCategory}
            betaPillar={betaPillar}
            setBetaPillar={setBetaPillar}
            directiveCategory={directiveCategory}
            setDirectiveCategory={setDirectiveCategory}
            dateIssue={dateIssue}
            setDateIssue={setDateIssue}
            targetDate={targetDate}
            setTargetDate={setTargetDate}
            implementationStatus={implementationStatus}
            setImplementationStatus={setImplementationStatus}
            county={county}
            setCounty={setCounty}
          />
        )}
      </JumboCardQuick>

      <Div sx={{ my: 1 }} />

      {collaborating.results !== undefined ? (
        collaborating.results.features === undefined &&
        collaborating.results.map(
          (directive) =>
            directive !== undefined && (
              <div key={directive.main_directive_id}>
                <ProjectItem
                user={user}
                  key={directive.main_directive_id}
                  dir_id={directive.main_directive_id}
                  percentage={directive.main_directive_percentage}
                  dir={directive}
                  sub={false}
                  token={token}
                />
              </div>
            )
        )
      ) : (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      )}

      {weights.results !== undefined && (
        <div className="my-16 mx-8">
          <Pagination
            totalItems={totalItems}
            limit={20}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            previousPage={links.previous}
            nextPage={links.next}
          />
        </div>
      )}
    </Div>
  );
};

export default CollabDirective;
