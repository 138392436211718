import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";

const PercentageDistributionChart = ({ planCount, planImplStatus }) => {
  const theme = useTheme();

  const sortedAverages1 = Object.keys(planCount)
    .map((plan) => {
      const totalInPlan = planCount[plan];
      // const inProgress = planImplStatus?.In_Progress?.[plan] || 0;
      const inProgress = planImplStatus?.In_Progress?.[plan] || 0;
      const completed = planImplStatus?.Completed?.[plan] || 0;
      const overDue = planImplStatus?.Over_Due?.[plan] || 0;
      const notStarted = planImplStatus?.Not_Started?.[plan] || 0;

      // Calculate the total completed and in-progress items
      const AverageinProgress = (inProgress / totalInPlan) * 100;
      const Averagecompleted = (completed / totalInPlan) * 100;
      const AverageoverDue = (overDue / totalInPlan) * 100;
      const AveragenotStarted = (notStarted / totalInPlan) * 100;
      // const implementationAverage = (implementedCount / totalInPlan) * 100;

      return {
        plan,
        total: totalInPlan,
        completed_full: completed,
        completed: Averagecompleted,
        overDue_full: overDue,
        overDue: AverageoverDue,
        inProgress_full: inProgress,
        inProgress: AverageinProgress,
        notStarted_full: notStarted,
        notStarted: AveragenotStarted,
      };
    })
    // Sort by highest average implementation percentage
    .sort((a, b) => b.completed - a.completed);

  // useEffect(() => {
  //   // Your code to set up scales and draw the chart
  // }, [data]);

  // useEffect(() => {
  //   // Your code to set up scales and draw the chart
  // }, [data]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (!active) {
      return <div></div>;
    }

    return (
      <div className="tooltip">
        <Card sx={{ m: 2 }}>
          <CardHeader
            title={
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {label}
              </Typography>
            }
          />
          <CardContent>
            <Typography variant="p" sx={{ fontWeight: "bold" }}>
              Total Directives: {payload[0].payload.total}
            </Typography>
            <br />
            <Typography variant="p" sx={{ fontWeight: "bold" }}>
              Completed: {payload[0].payload.completed_full}{" "}
            </Typography>
            <Typography variant="p">
              ( {` ${Math.round(payload[0].payload.completed)}%`})
            </Typography>
            <br />
            <Typography variant="p" sx={{ fontWeight: "bold" }}>
              In Progress: {payload[0].payload.inProgress_full}{" "}
            </Typography>
            <Typography variant="p">
              ( {` ${Math.round(payload[0].payload.inProgress)}%`})
            </Typography>
            <br />
            <Typography variant="p" sx={{ fontWeight: "bold" }}>
              Over Due: {payload[0].payload.overDue_full}{" "}
            </Typography>
            <Typography variant="p">
              ( {` ${Math.round(payload[0].payload.overDue)}%`})
            </Typography>
            <br />
            <Typography variant="p" sx={{ fontWeight: "bold" }}>
              Not Started: {payload[0].payload.notStarted_full}{" "}
            </Typography>
            <Typography variant="p">
              ( {` ${Math.round(payload[0].payload.notStarted)}%`})
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  };

  function yAxis(value) {
    let per = Math.round(value);

    if (per > 0) {
      return per + "%";
    }

    return 0;
  }

  return (
    <JumboCardQuick reverse wrapperSx={{ pb: 0 }}>
      <Typography fontWeight={"500"} variant={"h3"}>
        Percentage Distribution by Plan and Status
      </Typography>
      <ResponsiveContainer width="100%" height={450}>
        <BarChart
          data={sortedAverages1}
          margin={{ top: 20, right: 10, bottom: 150, left: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="plan"
            angle={-45}
            textAnchor="end"
            tickLine={false}
            tick={{ fontSize: 14, fill: "#999999" }}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            tickFormatter={yAxis}
            tick={{ fontSize: 14, fill: "#999999" }}
          />
          <Tooltip
            content={<CustomTooltip />}
            // cursor={<Cursor />}
          />

          <Bar dataKey="completed" fill={theme.palette.successLight.dark} />
          <Bar dataKey="ongoing" fill={theme.palette.primary.main} />
          <Bar dataKey="overDue" fill={theme.palette.primaryLight} />
          <Bar dataKey="notStarted" fill={theme.palette.error.light} />
        </BarChart>
      </ResponsiveContainer>
    </JumboCardQuick>
  );
};

export default PercentageDistributionChart;
