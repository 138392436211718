import { useState } from "react";
import { Button, Paper, Grid, Typography } from "@mui/material";
import CustomBarChart from "./widgets/BarChart";
import RadialPieChart from "./widgets/RadialPieChart";
import Div from "@jumbo/shared/Div";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  ObjectCountOrders,
  ObjectCountQueries,
  ObjectCountRevenue,
  ObjectCountVisits,
  ObjectCountNotStarted,
} from "./widgets/ObjectCountCards";
import PercentageDistributionChart from "./widgets/PercentageDistributionChart ";
import LocationDirectives from "./widgets/LocationDirectives";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { FilterReports } from "./FilterReports";
import { useTheme } from "@emotion/react";
import BudgetsTabs from "./widgets/BudgetsTabs";
import ObjectCounty from "./widgets/ObjectCountCards/ObjectCounty";
import CountiesInfo from "./info/CountiesInfo";
import ObjectCountOverDue from "./widgets/ObjectCountCards/ObjectCountOverDue";

const Reports = ({
  resp,
  isCS,
  isPS,
  counties,
  setCounties,
  token,
  summary,
  selectedMinistry,
  setSelectedMinistry,
  selectedPlan,
  setSelectedPlan,
  selectedCounty,
  setSelectedCounty,
  selectedDepartment,
  setSelectedDepartment,
  dateFilter,
  setDateFilter,
  performanceRange,
  setPerformanceRange,
}) => {
  const [open, setOpen] = useState(false);

  const theme = useTheme();

  const handleMinistryChange = (event) => {
    setSelectedMinistry(event.target.value);
  };

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const handleCountyChange = (event) => {
    setSelectedCounty(event.target.value);
  };

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const handlePerformanceChange = (event) => {
    setPerformanceRange(event.target.value);
  };

  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
  };

  return resp === false ? (
    <Box sx={{ display: "flex" }}>
      <CircularProgress />
    </Box>
  ) : (
    <Div>
      <Div sx={{ mb: 3 }}>
        <JumboCardQuick
          title={
            <Typography
              component={"div"}
              sx={{
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.down("md")]: {
                  flexWrap: "wrap",
                },
              }}
            >
              <Typography
                variant={"h3"}
                mb={0}
                sx={{
                  minWidth: 245,
                  [theme.breakpoints.down("md")]: {
                    minWidth: "100%",
                    marginBottom: 2,
                  },
                }}
              >
                Dashboard
              </Typography>
            </Typography>
          }
          action={
            isCS === false && isPS === false ? (
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setOpen(open === true ? false : true);
                }}
              >
                {open === true ? "Close Filters" : "Filter Reports"}
              </Button>
            ) : (
              <></>
            )
          }
          // sx={{ bgColor: "inherit" }}
          // bgColor={{}}
          noWrapper={true}
          headerSx={{
            "& .MuiCardHeader-action": {
              my: -0.75,
            },
          }}
          wrapperSx={{
            p: 0,
            "&:last-child": {
              pb: 0,
            },
            "& .MuiCollapse-entered:last-child": {
              "& .MuiListItemButton-root": {
                borderBottom: 0,
                borderBottomColor: "transparent",
              },
            },
          }}
        >
          {isCS === false && isPS === false && open === true && (
            <FilterReports
              isCS={isCS}
              isPS={isPS}
              countyList={summary.counies}
              plan_count={summary.plan_count}
              ministries={summary.ministry}
              lead_agency={summary.lead_agency}
              selectedPlan={selectedPlan}
              handlePlanChange={handlePlanChange}
              selectedCounty={selectedCounty}
              handleCountyChange={handleCountyChange}
              selectedMinistry={selectedMinistry}
              handleMinistryChange={handleMinistryChange}
              selectedDepartment={selectedDepartment}
              handleDepartmentChange={handleDepartmentChange}
              dateFilter={dateFilter}
              handleDateFilterChange={handleDateFilterChange}
              performanceRange={performanceRange}
              handlePerformanceChange={handlePerformanceChange}
            />
          )}
        </JumboCardQuick>
      </Div>

      {counties === true && (
        <CountiesInfo
          counties={counties}
          setCounties={setCounties}
          countyList={summary.counies}
        />
      )}

      {
        <>
          <Grid container spacing={3.75}>
            <Grid item xs={12} sm={6} lg={4}>
              <RadialPieChart
                inProgress={summary.In_progress.implementation_status__count}
                completed={summary.completed.implementation_status__count}
                notStarted={summary.Not_started.implementation_status__count}
                overDue={summary.over_due.implementation_status__count}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              lg={8}
              sx={{ height: 400, marginBottom: "96px" }}
            >
              <Div sx={{ mb: 3 }}>
                <Grid container spacing={3.75}>
                  <Grid item xs={12} sm={6} lg={4}>
                    <ObjectCountOrders
                      totalDirectives={summary.Total_main_directives}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <ObjectCountRevenue totalCost={summary.Total_cost} />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <ObjectCounty
                      countyList={summary.counies}
                      setCounties={setCounties}
                    />
                  </Grid>
                </Grid>
              </Div>
              <Div>
                <Grid container spacing={3.75}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <ObjectCountVisits
                      inProgress={
                        summary.In_progress.implementation_status__count
                      }
                      totalDirectives={summary.Total_main_directives}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <ObjectCountQueries
                      completed={summary.completed.implementation_status__count}
                      totalDirectives={summary.Total_main_directives}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <ObjectCountOverDue
                      overDue={summary.over_due.implementation_status__count}
                      totalDirectives={summary.Total_main_directives}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <ObjectCountNotStarted
                      notStarted={
                        summary.Not_started.implementation_status__count
                      }
                      totalDirectives={summary.Total_main_directives}
                    />
                  </Grid>
                </Grid>
              </Div>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Paper>
                <BudgetsTabs
                  leadAgencyBudget={summary.lead_agency}
                  ministryBudget={summary.ministry}
                  planBudget={summary.plan}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} lg={8}>
              <CustomBarChart
                planCount={summary.plan_count}
                planImplStatus={summary.plan_status}
              />
            </Grid>

            <Grid item xs={12}>
              <PercentageDistributionChart
                planCount={summary.plan_count}
                planImplStatus={summary.plan_status}
              />
            </Grid>

            <Grid item xs={12}>
              <LocationDirectives token={token} countyList={summary.counies} />
            </Grid>
          </Grid>
        </>
      }
    </Div>
  );
};

export default Reports;
