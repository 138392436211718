import React from "react";
import Typography from "@mui/material/Typography";
import { Box, LinearProgress } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import PaymentsIcon from "@mui/icons-material/Payments";
import { Link } from "react-router-dom/dist";

const ObjectCountRevenue = ({ totalCost }) => {
  return (
    <JumboCardQuick
      sx={{ height: 222, paddingTop: 3 }}
      title={
        <Box
          component={Link}
          to={`/directives?cost=${totalCost}`}
          sx={{ cursor: "pointer", textDecoration: "none" }}
        >
          <Typography variant={"h3"} color={"secondary"}>
            {totalCost.toLocaleString()}
          </Typography>
          <Div sx={{ my: 3 }} />
          <Typography variant={"h6"} sx={{ fontSize: "16px" }}>
            Total Cost (KES)
          </Typography>
          <Div sx={{ my: 3 }} />
          <LinearProgress
            variant={"determinate"}
            color={"successLight"}
            value={100}
            sx={{
              height: 6,
              borderRadius: 2,
              backgroundColor: "#E9EEEF",
            }}
          />
        </Box>
      }
      action={<PaymentsIcon fontSize={"large"} color="secondary" />}
      wrapperSx={{ textAlign: "center" }}
    ></JumboCardQuick>
  );
};

export default ObjectCountRevenue;
