import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      className="flex flex-col py-64"
      height="100vh"
    >
      <Typography variant="h3">404 - Page Not Found</Typography>
      <br />
      <Typography className="underline text-blue-500" component={Link} to="/" variant="h6">
        PDMIS - Home
      </Typography>
    </Box>
  );
};

export default NotFound;
