import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Div from "@jumbo/shared/Div";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { kenya_counties } from "app/shared/Components/kenya_counties";
import { Autocomplete, Box, FormControl } from "@mui/material";
import _ from "@lodash";
import { useNavigate } from "react-router-dom";
import { min_state_inst } from "app/shared/Components/institutions";

export default function GenerateMatrix() {
  const [open, setOpen] = React.useState(false);
  const [date_from, setDateFrom] = React.useState(null);
  const [date_to, setDateTo] = React.useState(null);
  const [selectedCounty, setSelectedCounty] = React.useState("");
  const [filteredMinistry, setFilteredMinistry] = React.useState([]);
  const [filteredState, setFilteredState] = React.useState(null);
  const [selectedMinistry, setSelectedMinistry] = React.useState(null);
  const [selectedState, setSelectedState] = React.useState("");

  const handleCountyChange = (newValue) => {
    const selectedCounty = newValue;

    const filteredCounty = kenya_counties.filter(
      (item) => item.County === selectedCounty
    );

    setSelectedCounty(filteredCounty);
    setSelectedCounty(selectedCounty);
  };

  const handleMinistryChange = (newValue) => {
    const selectedMinistry = newValue;

    const filteredMinistries = min_state_inst.filter(
      (item) => item.Ministry === selectedMinistry
    );
    setFilteredMinistry(filteredMinistries);
    setSelectedMinistry(selectedMinistry);
  };

  const handleStateChange = (newValue) => {
    const selectedState = newValue;

    const filteredStates =
      selectedState === "None"
        ? min_state_inst.filter((item) => item.Ministry === selectedMinistry)
        : min_state_inst.filter(
            (item) => item.State_Departments === selectedState
          );

    setFilteredState(filteredStates);
    setSelectedState(selectedState);
  };

  const department =
    filteredMinistry &&
    _.uniqWith(
      filteredMinistry.map((department) => department.State_Departments),
      _.isEqual
    );

  const counties = _.uniqWith(
    kenya_counties.map((county) => county.County),
    _.isEqual
  );

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        size={"medium"}
        color={"primary"}
        variant="contained"
        onClick={handleClickOpen}
      >
        Generate Matrix
      </Button>
      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            handleClose();
          },
        }}
      >
        <DialogTitle>Generate Matrix</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select fields to generate the matrix
          </DialogContentText>

          <Div sx={{ mb: 2, mt: 1, display: "flex" }}>
            <Autocomplete
              sx={{ paddingRight: "10px" }}
              fullWidth
              id="county"
              name="county"
              options={Array.from(
                new Set(kenya_counties.map((item) => item.County))
              )
                .map((county) => county)
                .sort()}
              value={selectedCounty}
              onChange={(e, newValue) => {
                handleCountyChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select County"
                  variant="outlined"
                />
              )}
            />
          </Div>

          <Box>
            <FormControl fullWidth>
              {min_state_inst && (
                <Box mb={2}>
                  <Autocomplete
                    fullWidth
                    id="ministry"
                    name="ministry"
                    options={Array.from(
                      new Set(min_state_inst.map((item) => item.Ministry))
                    )
                      .map((ministry) => ministry)
                      .sort()}
                    onChange={(e, newValue) => {
                      handleMinistryChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Ministry"
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
              )}
            </FormControl>
            <FormControl fullWidth>
              <Box mb={2}>
                <Autocomplete
                  fullWidth
                  id="State_departments"
                  name="State_departments"
                  options={department.sort()}
                  onChange={(e, newValue) => {
                    handleStateChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State Departments"
                      variant="outlined"
                    />
                  )}
                />
              </Box>
            </FormControl>
          </Box>

          <Div sx={{ mb: 2, mt: 1, display: "flex" }}>
            <Div sx={{ mb: 2, mt: 1, display: "flex" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ mr: 2 }}
                  fullWidth
                  id="date_from"
                  name="date_from"
                  label="Date From"
                  value={date_from}
                  onChange={(newValue) => setDateFrom(newValue)}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ mr: 2 }}
                  fullWidth
                  id="date_to"
                  name="date_to"
                  label="Date To"
                  value={date_to}
                  onChange={(newValue) => setDateTo(newValue)}
                />
              </LocalizationProvider>
            </Div>
          </Div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
          <Button
            onClick={() =>
              navigate(
                `/matrix?county=${selectedCounty}&ministry=${selectedMinistry}&lead_agency=${selectedState}`
              )
            }
            color="secondary"
            variant="contained"
          >
            Generate Matrix
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
