import React from "react";
import Typography from "@mui/material/Typography";
import { LinearProgress } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";

const ObjectCounty = ({ countyList, setCounties }) => {
  return (
    <JumboCardQuick
      sx={{ height: 222, paddingTop: 3, cursor: "pointer" }}
      onClick={() => setCounties(true)}
      title={
        <Div onClick={() => setCounties(true)}>
          <Typography variant={"h3"} sx={{ fontSize: "48px" }} color="black">
            {
              Object.keys(countyList).filter(
                (county) => county !== "null" && county !== "National"
              ).length
            }
          </Typography>
          <Typography variant={"h6"} sx={{ fontSize: "16px" }}>
            Counties with Directives
          </Typography>
          <LinearProgress
            variant={"determinate"}
            color={"warningLight"}
            value={
              Object.keys(countyList).filter(
                (county) => county !== "null" && county !== "National"
              ).length / 47
            }
            sx={{
              height: 6,
              borderRadius: 2,
              // backgroundColor: "#E9EEEF",
            }}
          />
        </Div>
      }
      action={<WhereToVoteIcon fontSize={"large"} />}
      wrapperSx={{ textAlign: "center" }}
    ></JumboCardQuick>
  );
};

export default ObjectCounty;
