import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const CustomPagination = ({
  currentPage,
  totalItems,
  onPageChange,
  setCurrentPage,
  limit,
}) => {
  const totalPages = Math.ceil(totalItems / limit);

  const calculateOffset = (page) => (page - 1) * limit;

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    onPageChange(calculateOffset(page));
  };

  return (
    <Stack
      spacing={2}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        siblingCount={1}
        boundaryCount={1}
      />
    </Stack>
  );
};

export default CustomPagination;
