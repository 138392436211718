import React from "react";
import Typography from "@mui/material/Typography";
import { Box, LinearProgress } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import { Link } from "react-router-dom";

const ObjectCountQueries = ({ completed, totalDirectives }) => {
  return (
    <JumboCardQuick
      sx={{ height: 222, paddingTop: 3 }}
      title={
        <Box
          component={Link}
          sx={{ cursor: "pointer", textDecoration: "none" }}
          // to={`/directives?implementation_status=Completed&category=main_directive`}
          to={`/directives?page=1&search=&plan=&ministry=&state_dep&county=&status=Completed&beta=&issue=&target=&cat=`}
        >
          <Typography variant={"h3"} sx={{ fontSize: "48px" }} color="green">
            {Math.round((completed / totalDirectives) * 100)}%
          </Typography>
          <Typography variant={"h6"} sx={{ fontSize: "16px" }}>
            {completed} Completed
          </Typography>
          <LinearProgress
            variant={"determinate"}
            color={"successLight"}
            value={(completed / totalDirectives) * 100}
            sx={{
              height: 6,
              borderRadius: 2,
              // backgroundColor: "#E9EEEF",
            }}
          />
        </Box>
      }
      action={<HourglassFullIcon fontSize={"small"} color="successLight" />}
      wrapperSx={{ textAlign: "center" }}
    ></JumboCardQuick>
  );
};

export default ObjectCountQueries;
