import React from "react";
import Div from "@jumbo/shared/Div";
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { alpha } from "@mui/material/styles";
import SignupForm from "./components/SignupForm";
import logo from "images/logo.png";
import state_house_image from "images/state_house.jpg";
import { Link } from "react-router-dom";

const Signup1 = () => {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const isLG = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Container component="main" maxWidth="xl">
      <Div
        sx={{
          flex: 1,
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: [(theme) => theme.spacing(0), (theme) => theme.spacing(4)],
          marginLeft: isSM || isMD || isLG ? "0px" : "-64px",
        }}
      >
        <Card
          sx={{
            maxWidth: "100%",
            mb: 4,
            marginTop: isSM || isMD || isLG ? 8 : 8,
            padding: isSM || isMD || isLG ? 0 : 4,
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: isSM || isMD || isLG ? 1 : 4,
              flexDirection: "column",
            }}
          >
            <Div
              sx={{
                // maxWidth: isSM ? "inherit" : 540,
                mb: 3,
                // display: "inline-flex",
              }}
            >
              <img
                style={{
                  width: isSM
                    ? "90vw"
                    : isMD
                    ? "95vw"
                    : isLG
                    ? "95vw"
                    : "540px",
                }}
                src={`${logo}`}
              />
            </Div>
            <Div sx={{ position: "relative", minHeight: "100px" }}>
              <Div
                sx={{
                  flex: 1,
                  inset: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  // backgroundColor: (theme) =>
                  //   alpha(theme.palette.common.black, 0.5),
                  p: (theme) => theme.spacing(2),
                }}
              >
                <Div>
                  <Typography
                    variant={"h2"}
                    sx={{
                      // color: "common.white",
                      fontSize: "2.5rem",
                      fontWeight: "bold",
                      mb: 0,
                    }}
                  >
                    Sign up
                  </Typography>
                </Div>
              </Div>
            </Div>
            <CardContent sx={{ pt: 0 }}>
              <SignupForm />
              <Typography textAlign={"left"} variant={"body1"} mb={1}>
                Have an account?
                <Link underline="none" to="/login">
                  <span style={{ marginLeft: "2px" }}>Sign in</span>
                </Link>
              </Typography>
            </CardContent>
          </Box>

          <Box
            sx={{
              position: "relative",
              display: "flex",
              flex: "auto",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100%",
              p: "64px",
              lg: { px: "112px" },
              overflow: "hidden",
              backgroundColor: "secondary.dark",
            }}
          >
            <svg
              sx={{
                position: "absolute",
                inset: 0,
                pointerEvents: "none",
              }}
              viewBox="0 0 960 540"
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMax slice"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Box
                component="g"
                sx={{
                  color: "secondary.light",
                  opacity: 0.2,
                  fill: "none",
                  stroke: "currentColor",
                  strokeWidth: "100",
                }}
              >
                <circle r="234" cx="196" cy="23" />
                <circle r="234" cx="790" cy="491" />
              </Box>
            </svg>
            <Box
              component="svg"
              sx={{
                position: "absolute",
                top: "-64px",
                right: "-64px",
                opacity: 0.2,
                color: "secondary.light",
              }}
              viewBox="0 0 220 192"
              width="220px"
              height="192px"
              fill="none"
            >
              <defs>
                <pattern
                  id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect x="0" y="0" width="4" height="4" fill="currentColor" />
                </pattern>
              </defs>
              <rect
                width="220"
                height="192"
                fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
              />
            </Box>

            <Box
              sx={{
                position: "relative",
                zIndex: 10,
                width: "100%",
                maxWidth: "200%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  lineHeight: "none",
                  color: "white",
                  textAlign: "left",
                  marginLeft: "-120px",
                }}
              >
                Presidential
              </Typography>
              <Typography
                sx={{
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  lineHeight: "none",
                  color: "white",
                  textAlign: "left",
                  marginLeft: "-120px",
                }}
              >
                Directives
              </Typography>
              <Typography
                sx={{
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  lineHeight: "none",
                  color: "white",
                  textAlign: "left",
                  marginLeft: "-120px",
                }}
              >
                Management
              </Typography>
              <Typography
                sx={{
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  lineHeight: "none",
                  color: "white",
                  textAlign: "left",
                  marginLeft: "-120px",
                }}
              >
                Information
              </Typography>
              <Typography
                sx={{
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  lineHeight: "none",
                  color: "white",
                  textAlign: "left",
                  marginLeft: "-120px",
                }}
              >
                System
              </Typography>
            </Box>
          </Box>
        </Card>
      </Div>
    </Container>
  );
};

export default Signup1;
