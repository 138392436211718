import React from "react";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import shadows from "@mui/material/styles/shadows";
import { alpha } from "@mui/material/styles";
import * as yup from "yup";
import { useFormik } from "formik";
import Div from "@jumbo/shared/Div";
import axios from "axios";
import { backend_url } from "app/services/constants";
import { useState } from "react";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import logo from "images/logo.png";
import state_house_image from "images/state_house.jpg";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const LoginPassword = () => {
  const Swal = useSwalWrapper();

  const [loading, setLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const email_saved = localStorage.getItem("email");

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .required("Password is required"),
  });

  const defaultValues = {
    email: email_saved !== null ? `${email_saved}` : "",
    password: "",
  };

  function submit({ password, email }) {
    const user = {
      email,
      password,
    };
    axios
      .post(
        `${backend_url}/api/token/`,
        user,
        {
          headers: { "Content-Type": "application/json" },
        },
        { withCredentials: true }
      )
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Signed In successfully.",
          showConfirmButton: false,
          timer: 4500,
        });
        localStorage.clear();
        localStorage.setItem("access_token", res.data.access);
        localStorage.setItem("refresh_token", res.data.refresh);
        // axios.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${res.data["access"]}`;
        // window.location.href = "/directives";
        window.location.replace("/profile");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: err.response.data?.detail,
          showConfirmButton: false,
          timer: 4500,
        });
      });
  }

  const formik = useFormik({
    initialValues: { defaultValues },
    validationSchema,
    onSubmit: submit,
  });

  const handleFieldErrors = () => {
    setShowErrors(true);
  };

  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: (theme) => theme.spacing(4),
        marginLeft: "-240px",
      }}
    >
      <Div sx={{ maxWidth: 540, mb: 3, display: "inline-flex" }}>
        <Link to="#" underline="none" sx={{ display: "inline-flex" }}>
          <img
            src={`${logo}`}
            sx={{ width: "100%", height: "auto" }}
            alt="Log in"
          />
        </Link>
      </Div>
      <Card sx={{ maxWidth: "100%", width: 580, mb: 4 }}>
        <Div sx={{ position: "relative", height: "200px" }}>
          <CardMedia
            sx={{ width: "100%" }}
            component="img"
            height="200"
            image={`${state_house_image}`}
          />
          <Div
            sx={{
              flex: 1,
              inset: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              backgroundColor: (theme) =>
                alpha(theme.palette.common.black, 0.5),
              p: (theme) => theme.spacing(3),
            }}
          >
            <Typography
              variant={"h2"}
              sx={{
                color: "common.white",
                fontSize: "1.5rem",
                mb: 0,
              }}
            >
              Sign In
            </Typography>
          </Div>
        </Div>
        <CardContent sx={{ pt: 0 }}>
          <form onSubmit={formik.handleSubmit}>
            <Avatar
              alt="Remy Sharp"
              // src={getAssetPath(`${ASSET_AVATARS}/avatar5.jpg`)}
              sx={{
                width: 56,
                height: 56,
                marginLeft: "auto",
                boxShadow: shadows[3],
                transform: "translateY(-50%)",
              }}
            />
            <Div sx={{ mb: 3, mt: 1 }}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                // defaultValue={formik.values.email}
                error={
                  (showErrors || formik.touched.email) &&
                  Boolean(formik.errors.email)
                }
                helperText={
                  (showErrors || formik.touched.email) && formik.errors.email
                }
              />
            </Div>
            <Div sx={{ mb: 3, mt: 1 }}>
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={
                  (showErrors || formik.touched.password) &&
                  Boolean(formik.errors.password)
                }
                helperText={
                  (showErrors || formik.touched.password) &&
                  formik.errors.password
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Div>
            {/* <Typography textAlign={"right"} variant={"body1"}>
              <Link underline="none" to="/login">
                Resend OTP
              </Link>
            </Typography> */}

            <Button
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              size="large"
              sx={{ mb: 3 }}
              loading={loading}
              onClick={() => {
                // navigate("/");
                handleFieldErrors();
                setLoading(true);
              }}
              // disabled={formik.isValidating || !formik.dirty || !formik.isValid}
            >
              Login
            </Button>

            <Typography textAlign={"left"} variant={"body1"} mb={1}>
              Forgot One Time Password?
              <Link underline="none" to="/login">
                <span style={{ marginLeft: "2px" }}> Resend OTP</span>
              </Link>
            </Typography>

            <Typography textAlign={"left"} variant={"body1"} mb={1}>
              Don't have an account?
              <Link underline="none" to="/signup">
                <span style={{ marginLeft: "2px" }}> Register now</span>
              </Link>
            </Typography>
          </form>
        </CardContent>
      </Card>
    </Div>
  );
};

export default LoginPassword;
