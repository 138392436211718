import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";

const Confirmation = ({}) => {
  return (
    <Container sx={{ py: 16, ml: 0 }}>
      <Card>
        <CardHeader
          title={
            <Typography variant={"h1"} component="h1" my={3} ml={2}>
              <b> Wait for approval.</b>
            </Typography>
          }
        />
        <CardContent sx={{ p: 0 }}>
          <Div
            sx={{
              px: 3,
              display: "flex",
              minHeight: "100%",
              flexDirection: "column",
              minWidth: 0,
            }}
          >
            <Div sx={{ mb: 1 }}>
              <Typography variant={"h2"} component="h2" my={3} ml={2}>
                Your account has been created successfully. Please wait for
                approval. A confirmation mail with instructions will be sent to
                your email address after approval.
              </Typography>
            </Div>
            <Div sx={{ mb: 1, display: "flex" }}>
              <Typography variant={"h2"} component="h2" my={3} ml={2}>
                Return to
              </Typography>
              <Link underline="none" to="/login">
                <Button
                  sx={{ ml: 2, mt: 2 }}
                  color="primary"
                  variant="contained"
                  size="medium"
                >
                  Sign In
                </Button>
              </Link>
            </Div>
            <Div sx={{ flex: 1 }}>
              <Div
                sx={{
                  position: "relative",
                  textAlign: "center",
                  mb: 2,
                  "&:after": {
                    display: "inline-block",
                    content: "''",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    height: "1px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "divider",
                  },
                }}
              >
                <Chip
                  label={"PDMIS"}
                  variant="outlined"
                  color={"info"}
                  sx={{
                    marginLeft: "1em",
                    position: "relative",
                    zIndex: 1,
                    bgcolor: (theme) => theme.palette.background.paper,
                    borderColor: "divider",
                    borderRadius: 2,
                  }}
                />
              </Div>
            </Div>
          </Div>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Confirmation;
