import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as yup from "yup";
import Div from "@jumbo/shared/Div";
// import Span from "@jumbo/shared/Span";
import { useState } from "react";
import { backend_url } from "app/services/constants";
import axios from "axios";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { format } from "date-fns";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import "../../../../styles/leaf.css";

const NewActivity = ({ token, remainingWeight, open, setOpen }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });
  const Swal = useSwalWrapper();

  const handleMapClick = (event) => {
    const { lat, lng } = event.latlng;
    setMarkerPosition({ lat, lng });
  };

  const handleTextFieldChange = (field, value) => {
    setMarkerPosition((prevPosition) => ({
      ...prevPosition,
      [field]: parseFloat(value) || 0, // Parse the value to a float or set to 0 if not valid
    }));
  };

  const MapClickHandler = () => {
    const map = useMapEvents({
      click: handleMapClick,
    });

    return null; // Return null as we don't need to render anything for the MapClickHandler
  };

  const NumberFormat = new Intl.NumberFormat("en-US");

  const validationSchema = yup.object({
    name: yup.string().required("Please enter the activity name."),
    description: yup.string().required("Please enter the dexcription."),
    weighting: yup
      .number()
      .min(0, "Weighting must be at least 0")
      .max(
        1,
        // remainingWeight > 0.05 ? Math.round(remainingWeight) : 0.0,
        "Weighting must be at most 1"
      )
      .required("Weighting is required"),
    budget_activities: yup.number().required("Please enter the cost"),
    status_activities: yup.string().required("Please enter the status"),
    start_date: yup
      .date()
      .required("You must enter the start date")
      .default(() => new Date()),
    end_date: yup
      .date()
      .required("You must enter end/target date")
      .default(() => new Date()),
    // location: yup.string().required("Please enter your first name."),
  });

  const defaultValues = {
    name: "",
    description: "",
    weighting: 0,
    budget_activities: "",
    status_activities: "",
    start_date: "",
    end_date: "",
    // location: "",
  };

  function submit({
    name,
    description,
    weighting,
    budget_activities,
    status_activities,
    start_date,
    end_date,
    // location,
  }) {
    const activity = {
      directive_no: id,
      name,
      description,
      weighting,
      budget_activities,
      status_activities,
      start_date: format(new Date(start_date), "yyyy-MM-dd"),
      end_date: format(new Date(end_date), "yyyy-MM-dd"),
      location: `POINT(${markerPosition.lng} ${markerPosition.lat})`,
    };
    axios
      .put(
        `${backend_url}/ppi/activity/`,
        activity,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        // activity.push(res.data);
        setOpen(false);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Activity added successfully.",
          showConfirmButton: false,
          timer: 4500,
        });
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setOpen(true);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: err.response.data?.details,
          showConfirmButton: false,
          timer: 4500,
        });
        setLoading(false);
      });
  }

  const formik = useFormik({
    initialValues: { defaultValues },
    validationSchema,
    onSubmit: submit,
  });

  const handleInputChange = (event) => {
    const inputValue = parseFloat(event.target.value);
    // const clampedValue = Math.min(remainingWeight, Math.max(0, inputValue)); // Clamp the value between 0 and 1
    const clampedValue = Math.min(1, Math.max(0, inputValue)); // Clamp the value between 0 and 1

    // const roundToCustomPrecision = (value) => {
    //   if (value < 0.01) return Math.round(value * 100) / 100;
    //   return value.toFixed(3);
    // };

    // const roundedValue = roundToCustomPrecision(clampedValue);

    formik.setFieldValue("weighting", clampedValue);
  };

  const handleFieldErrors = () => {
    setShowErrors(true);
    formik.validateForm().then(() => {
      // Log the fields with errors
      const fieldsWithErrors = Object.keys(formik.errors);
    });
  };

  return (
    <div>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Typography variant={"h2"} mb={3}>
            Add a new activity
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <Div sx={{ mb: 3, mt: 1 }}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  type="text"
                  label="Activity Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  error={
                    (showErrors || formik.touched.name) &&
                    Boolean(formik.errors.name)
                  }
                  helperText={
                    (showErrors || formik.touched.name) && formik.errors.name
                  }
                />
              </Div>
            </FormControl>

            <FormControl fullWidth>
              <Div sx={{ mb: 3, mt: 1 }}>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  type="text"
                  label="Description"
                  multiline
                  rows={2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  error={
                    (showErrors || formik.touched.description) &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    (showErrors || formik.touched.description) &&
                    formik.errors.description
                  }
                />
              </Div>
            </FormControl>

            <FormControl fullWidth>
              <Div sx={{ mb: 3, mt: 1 }}>
                <TextField
                  fullWidth
                  id="weighting"
                  name="weighting"
                  type="number"
                  // InputProps={{
                  //   inputProps: { min: 0, max: remainingWeight > 0.05 ? Math.round(remainingWeight) : 0.0, step: 0.01 },
                  // }}
                  label="Weighting"
                  onChange={handleInputChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.weighting}
                  error={
                    (showErrors || formik.touched.weighting) &&
                    Boolean(formik.errors.weighting)
                  }
                  helperText={
                    (showErrors || formik.touched.weighting) &&
                    formik.errors.weighting
                  }
                />
              </Div>
            </FormControl>

            <FormControl fullWidth>
              <Div sx={{ mb: 3, mt: 1 }}>
                {/* <TextField
                  fullWidth
                  id="budget_activities"
                  name="budget_activities"
                  type="number"
                  label="Cost"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.budget_activities}
                  error={
                    (showErrors || formik.touched.budget_activities) &&
                    Boolean(formik.errors.budget_activities)
                  }
                  helperText={
                    (showErrors || formik.touched.budget_activities) &&
                    formik.errors.budget_activities
                  }
                /> */}

                <TextField
                  sx={{ mr: 2 }}
                  fullWidth
                  id="budget_activities"
                  name="budget_activities"
                  label="Cost (KES)"
                  defaultValue={0}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/,/g, ""); // remove commas
                    if (!isNaN(rawValue)) {
                      formik.setFieldValue("budget_activities", rawValue); // update formik with the raw number
                    }
                  }}
                  onBlur={(e) => formik.handleBlur(e)}
                  value={NumberFormat.format(formik.values.budget_activities)} // format with commas
                  error={
                    (showErrors || formik.touched.budget_activities) &&
                    Boolean(formik.errors.budget_activities)
                  }
                  helperText={
                    (showErrors || formik.touched.budget_activities) &&
                    formik.errors.budget_activities
                  }
                />
              </Div>
            </FormControl>

            <Div sx={{ mb: 3, mt: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="status_activities">
                  Status of Activity
                </InputLabel>
                <Select
                  labelId="status_activities"
                  id="status_activities"
                  name="status_activities"
                  label="Status of Activity"
                  value={formik.values.status_activities}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    (showErrors || formik.touched.status_activities) &&
                    Boolean(formik.errors.status_activities)
                  }
                >
                  <MenuItem value={"Not_Started"}>Not Started</MenuItem>

                  <MenuItem value={"In_Progress"}>In Progress</MenuItem>
                  <MenuItem value={"Completed"}>Completed</MenuItem>
                  <MenuItem value={"Over_Due"}>Over Due</MenuItem>
                </Select>
                {(showErrors || formik.touched.status_activities) &&
                  formik.errors.status_activities && (
                    <FormHelperText>
                      {formik.errors.status_activities}
                    </FormHelperText>
                  )}
              </FormControl>
            </Div>

            <Div sx={{ mb: 3, mt: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ mr: 2 }}
                  fullWidth
                  id="start_date"
                  name="start_date"
                  label="Start Date"
                  value={formik.values.start_date}
                  onChange={(date) => formik.setFieldValue("start_date", date)}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        (showErrors || formik.touched.start_date) &&
                        Boolean(formik.errors.start_date)
                      }
                      helperText={
                        (showErrors || formik.touched.start_date) &&
                        formik.errors.start_date
                      }
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  fullWidth
                  id="end_date"
                  name="end_date"
                  label="End Date"
                  value={formik.values.end_date}
                  onChange={(date) => formik.setFieldValue("end_date", date)}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        (showErrors || formik.touched.end_date) &&
                        Boolean(formik.errors.end_date)
                      }
                      helperText={
                        (showErrors || formik.touched.end_date) &&
                        formik.errors.end_date
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Div>

            {/* <Div sx={{ mb: 2, mt: 1, display: "flex" }}>
              <TextField
                fullWidth
                sx={{ mr: 2 }}
                id="latitude"
                name="latitude"
                label="Latitude"
                onChange={(e) => handleTextFieldChange("lat", e.target.value)}
                value={markerPosition.lat}
                type="number"
                step="any" // Allow decimal values
              />

              <TextField
                fullWidth
                id="longitude"
                name="longitude"
                label="Longitude"
                onChange={(e) => handleTextFieldChange("lng", e.target.value)}
                value={markerPosition.lng}
                type="number"
                step="any" // Allow decimal values
              />
            </Div>
            <Div sx={{ mb: 2, mt: 1 }}>
              <MapContainer
                center={[0, 37]}
                zoom={6}
                style={{ height: "250px", width: "100%" }}
                onClick={handleMapClick}
                scrollWheelZoom={true}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />

                <MapClickHandler />

                <Marker position={[markerPosition.lat, markerPosition.lng]}>
                  <Popup>
                    Marker Position: ({markerPosition.lat}, {markerPosition.lng}
                    )
                  </Popup>
                </Marker>
              </MapContainer>
            </Div> */}

            <Grid container spacing={3} my={2}>
              <Grid item xs={6}>
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Div
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="large"
                    loading={loading}
                    onClick={() => {
                      // navigate("/");
                      handleFieldErrors();
                      setLoading(true);
                    }}
                    // disabled={formik.isValidating || !formik.dirty || !formik.isValid}
                  >
                    Submit Activity
                  </Button>
                </Div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default NewActivity;
