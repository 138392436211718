import React from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import styled from "@emotion/styled";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Link } from "react-router-dom/dist";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& .MuiTableCell-root": {
    borderBottom: "none",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  textDecoration: "none",

  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    textDecoration: "underline",
  },
}));

const BudgetDistributionByLeadList = ({ leadAgencyBudget }) => {
  const sortedNestedData = leadAgencyBudget.sort((a, b) => b[1] - a[1]);

  return (
    <JumboCardQuick
      title={"Cost distribution by lead agency"}
      action={
        <Tooltip
          title={"No data available"}
          arrow
          placement={"top"}
          sx={{
            "& .MuiTooltip-arrow": {
              marginTop: "-0.65em",
            },
          }}
        >
          <IconButton
          >
          </IconButton>
        </Tooltip>
      }
      wrapperSx={{ p: 0 }}
    >
      <JumboScrollbar
        autoHeight={400}
        autoHideTimeout={4000}
        autoHeightMin={450}
        autoHide={true}
        hideTracksWhenNotNeeded
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ pl: 3 }}>Lead Agency</StyledTableCell>
              <StyledTableCell sx={{ pr: 3 }} align={"right"}>
                Cost
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedNestedData.map((item, key) => (
              <StyledTableRow
                key={key}
                component={Link}
                to={`/directives?page=1&search=&plan=&ministry=&state_dep=${item.lead_agency}&county=&status=&beta=&issue=&target=&cat=`}
              >
                <TableCell sx={{ pl: (theme) => theme.spacing(3) }}>
                  {item.lead_agency
                    .toLowerCase()
                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                      letter.toUpperCase()
                    )}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ pr: (theme) => theme.spacing(3) }}
                >
                  KES {item.total_cost.toLocaleString()}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </JumboScrollbar>
    </JumboCardQuick>
  );
};

export default BudgetDistributionByLeadList;
