import { useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { backend_url } from "app/services/constants";
import Reports from "./Reports";

const ReportsMain = ({ token, user, isCS, isPS, leadAgency, ministryy }) => {
  const [resp, setResp] = useState(false);
  const [counties, setCounties] = useState(false);
  const [summary, setSummary] = useState(null);

  const [selectedMinistry, setSelectedMinistry] = useState(ministryy);
  const [selectedDepartment, setSelectedDepartment] = useState(leadAgency);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedCounty, setSelectedCounty] = useState("");
  const [performanceRange, setPerformanceRange] = useState("all");
  const [dateFilter, setDateFilter] = useState("");

  const url = `${backend_url}/ppi/summary/`;

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams();

      // Helper function to add parameters to URLSearchParams
      const addParam = (key, value) => {
        if (value) params.append(key, value);
      };

      addParam("county", selectedCounty);
      // addParam("ministry", selectedMinistry.toUpperCase());
      addParam("manifesto_tracking_category", selectedPlan);
      // addParam("lead_agency", selectedDepartment.toUpperCase());
      addParam("performanceRange", performanceRange);
      addParam("dateFilter", dateFilter);

      try {
        const response = await fetch(
          isPS
            ? `${url}?lead_agency=${leadAgency}`
            : isCS
            ? `${url}?ministry=${ministryy}`
            : `${url}?${params.toString()}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setSummary(data);
        setResp(true);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, [
    token,
    selectedCounty,
    selectedMinistry,
    selectedPlan,
    selectedDepartment,
    performanceRange,
    dateFilter,
    isCS,
    user.ministry,
  ]);

  return resp === false ? (
    <Box sx={{ display: "flex" }}>
      <CircularProgress />
    </Box>
  ) : (
    <Div>
      <Reports
        summary={summary}
        counties={counties}
        setCounties={setCounties}
        user={user}
        resp={resp}
        token={token}
        selectedMinistry={selectedMinistry}
        setSelectedMinistry={setSelectedMinistry}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        selectedCounty={selectedCounty}
        setSelectedCounty={setSelectedCounty}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        performanceRange={performanceRange}
        setPerformanceRange={setPerformanceRange}
        isCS={isCS}
        isPS={isPS}
      />
    </Div>
  );
};

export default ReportsMain;
