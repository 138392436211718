import React from "react";
import TabContext from "@mui/lab/TabContext";
import Div from "@jumbo/shared/Div";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  TextField,
  FormControl,
  Box,
  Avatar,
  IconButton,
  Typography,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useState } from "react";
import axios from "axios";
import { backend_url } from "app/services/constants";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const About = ({ user, token }) => {
  const [value, setValue] = useState("1");
  const Swal = useSwalWrapper();

  const [loading, setLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = yup.object({
    id_number: yup
      .string()
      .required("You must enter your ID number")
      .min(4, "Too short - should be 4 chars minimum")
      .nullable(true),
    profile_photo: yup.mixed().notRequired(),
    telephone_no: yup
      .number()
      .required("You must enter your telephone number")
      .min(4, "Number is too short - should be 4 chars minimum")
      .nullable(true),
    password: yup
      .string()
      .required("Please enter your password.")
      .min(8, "Password is too short - should be 8 chars minimum."),
    re_password: yup
      .string()
      .required("Passwords must match")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const defaultValues = {
    id_number: user !== undefined ? user.id_number : "",
    telephone_no: user !== undefined ? user.telephone_no : "",
    password: "",
    re_password: "",
    profile_photo: null,
  };

  function submit({
    telephone_no,
    id_number,
    password,
    re_password,
    profile_photo,
  }) {
    const userInfo = {
      telephone_no,
      id_number,
      password,
      re_password,
      profile_photo,
    };
    axios
      .patch(
        `${backend_url}/auth/user/update_info/`,
        userInfo,
        {
          headers: {
            // "content-type": "application/json",
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Profile updated successfully.",
          showConfirmButton: false,
          timer: 4500,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: err.response.data?.details,
          showConfirmButton: false,
          timer: 4500,
        });
        setLoading(false);
      });
  }

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: submit,
  });

  const handleFieldErrors = () => {
    setShowErrors(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    user.id_number !== undefined && (
      <JumboCardQuick
        title={"Update Profile"}
        action={
          <TabContext value={value}>
            <Div
              sx={{
                marginTop: -2.25,
                marginBottom: -2.5,

                "& .MuiTab-root": {
                  py: 2.5,
                },
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Overview" value="1" />
                {/* <Tab label="Work" value="2"/>
                            <Tab label="Education" value="3"/> */}
              </TabList>
            </Div>
          </TabContext>
        }
        headerSx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
        sx={{ mb: 3.75 }}
      >
        <List
          disablePadding
          sx={{
            display: "flex",
            flexWrap: "wrap",
            margin: (theme) => theme.spacing(0, -2),
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <Box
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[200]
                      : theme.palette.grey[800],
                  borderRadius: "50%",
                  width: 150,
                  height: 150,
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  cursor: "pointer",
                  boxShadow: 3,
                  "&:hover": {
                    boxShadow: 6,
                  },
                  mb: 3,
                  mt: 1,
                }}
                component="label"
                htmlFor="button_profile_photo"
              >
                <input
                  accept=".jpeg, .jpg, .png"
                  // className="hidden"
                  style={{ display: "none" }}
                  id="button_profile_photo"
                  type="file"
                  onChange={(e) => {
                    formik.setFieldValue("profile_photo", e.target.files[0]);
                  }}
                />
                {formik.values.profile_photo ? (
                  <Avatar
                    src={URL.createObjectURL(formik.values.profile_photo)}
                    sx={{ width: "100%", height: "100%" }}
                  />
                ) : (
                  <>
                    <IconButton>
                      <FileUploadIcon sx={{ color: "action" }} />
                    </IconButton>
                    <Typography
                      variant="caption"
                      component="span"
                      sx={{ mt: 1 }}
                    >
                      Update Profile Photo
                    </Typography>
                  </>
                )}
                <FormHelperText
                  sx={{
                    position: "absolute",
                    bottom: -20,
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <span className="flex flex-col text-red-500">
                    {formik.touched.profile_photo &&
                      formik.errors.profile_photo}
                  </span>
                </FormHelperText>
              </Box>

              <Div sx={{ mb: 3, mt: 1 }}>
                <TextField
                  fullWidth
                  id="id_number"
                  name="id_number"
                  label="ID Number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.id_number}
                  error={
                    (showErrors || formik.touched.id_number) &&
                    Boolean(formik.errors.id_number)
                  }
                  helperText={
                    (showErrors || formik.touched.id_number) &&
                    formik.errors.id_number
                  }
                />
              </Div>

              <Div sx={{ mb: 3, mt: 1 }}>
                <TextField
                  fullWidth
                  id="telephone_no"
                  name="telephone_no"
                  label="Telephone Number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.telephone_no}
                  error={
                    (showErrors || formik.touched.telephone_no) &&
                    Boolean(formik.errors.telephone_no)
                  }
                  helperText={
                    (showErrors || formik.touched.telephone_no) &&
                    formik.errors.telephone_no
                  }
                />
              </Div>

              <Div sx={{ mb: 3, mt: 1 }}>
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={
                    (showErrors || formik.touched.password) &&
                    Boolean(formik.errors.password)
                  }
                  helperText={
                    (showErrors || formik.touched.password) &&
                    formik.errors.password
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Div>

              <Div sx={{ mb: 3, mt: 1 }}>
                <TextField
                  fullWidth
                  id="re_password"
                  name="re_password"
                  label="Password Confirm"
                  type={showPassword ? "text" : "password"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.re_password}
                  error={
                    (showErrors || formik.touched.re_password) &&
                    Boolean(formik.errors.re_password)
                  }
                  helperText={
                    (showErrors || formik.touched.re_password) &&
                    formik.errors.re_password
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Div>

              <Button
                fullWidth
                type="submit"
                color="secondary"
                variant="contained"
                size="large"
                sx={{ mb: 3 }}
                onClick={() => {
                  // navigate("/");
                  handleFieldErrors();
                  setLoading(true);
                }}
                // disabled={formik.isValidating || !formik.dirty || !formik.isValid}
              >
                {loading === true ? "Loading..." : "Update Profile"}
              </Button>
            </FormControl>
          </form>
        </List>
      </JumboCardQuick>
    )
  );
};

export default About;
