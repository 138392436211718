import React, { useState } from "react";
import _ from "@lodash";
import { Box, CircularProgress } from "@mui/material";
import { backend_url } from "app/services/constants";
import { useParams } from "react-router-dom";
import "../../../../../styles/leaf.css";
import { useEffect } from "react";
import { EditDirectiveInfo } from "./EditDirectiveInfo";

const EditDirective = ({ token }) => {
  const [loading, setLoading] = useState(false);
  const [directive, setDirective] = useState(null);
  const { id } = useParams();

  const directiveInfo =
    directive !== undefined && directive?.features[0]?.properties;

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/ppi/details/?` +
          new URLSearchParams({
            directive_no: id,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setDirective(data));
    }

    fetchData();
  }, [token, id]);

  return directiveInfo !== undefined ? (
    <EditDirectiveInfo
      id={id}
      token={token}
      directive={directive}
      directiveInfo={directiveInfo}
    />
  ) : (
    <Box sx={{ display: "flex" }}>
      <CircularProgress />
    </Box>
  );
};

export default EditDirective;
