import { useEffect } from "react";
import L from "leaflet";
import {
  Card,
  CardContent,
  Grid,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { createRoot } from "react-dom/client";
import { useMap } from "react-leaflet";
import MapKey from "./MapKey";

const Legend = ({ spectralColors, quantileRanges }) => {
  const map = useMap();
  const theme = useTheme();

  useEffect(() => {
    if (map) {
      const legend = L.control({ position: "topright" });

      legend.onAdd = () => {
        const div = L.DomUtil.create("div", "info legend");
        return div;
      };

      legend.addTo(map);

      const legendDiv = document.querySelector(".info.legend");
      if (legendDiv) {
        const root = createRoot(legendDiv);

        root.render(
          <ThemeProvider theme={theme}>
            <Grid container spacing={0}>
              <Grid
                item
                style={{
                  maxHeight: "90vh",
                  width: "20vw",
                  overflowY: "auto",
                  paddingBottom: "240px",
                }}
              >
                <Card className="my-0">
                  <CardContent className="-m-8">
                    <MapKey
                      spectralColors={spectralColors}
                      quantileRanges={quantileRanges}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </ThemeProvider>,
          legendDiv
        );
      }
    }
  }, [map, theme, quantileRanges, spectralColors]);

  return null;
};

export default Legend;
