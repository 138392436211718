import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQPage = () => {
  return (
    <div>
      <Typography
        variant={"h1"}
        mb={0}
        sx={{
          minWidth: 245,
          marginBottom: 2,
        }}
      >
        FAQs
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>What is PDMIS?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Presidential Directives Management Information System (PDMIS) is a
            system designed to disseminate, monitor, track, and report on the
            implementation of presidential directives and pronouncements. The
            Public Policy and Presidential Priority Initiatives (PP&PPI) unit is
            mandated to follow up and report on the implementation of these
            directives including any challenges or constraints relating to it.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            What are presidential directives and pronouncements?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            These are instructions issued by H.E. The president to various
            government officials, government agencies, and state departments
            during his formal or informal public or private engagements.
            Conforming to Article 135 of the constitution of Kenya, 2010, The
            Chief of Staff and Head of Public Service reviews the instructions,
            transforms them into formal directives, and disseminates them to the
            appropriate state departments and agencies for action. Executive
            Order No. 2 of 2023 mandates The Chief of Staff and Head of Public
            Service to act on the crucial responsibility of coordinating and
            convening the Principal Secretaries Committees. In this capacity,
            they provide essential support to the Executive Office of the
            President to ensure the smooth organization and effective execution
            of government affairs. This includes streamlining and overseeing
            various government functions, fostering efficient communication
            among Principal Secretaries, and overseeing the overall coordination
            of government activities to optimize the delivery of public services
            and initiatives.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Why PDMIS?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            PDMIS has been developed as a single platform for managing
            presidential directives and pronouncements, tracking their
            implementations, and generating timely reports on their progress.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Why is PDMIS unique?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            • It is a single source of truth for information on the
            implementation status of presidential directives and pronouncements.
            <br />
            • The system is a statistics-based platform that empowers users to
            generate directives’ reports based on the ministries, and state
            departments in line with the BETA pillar and manifesto tracking
            categories.
            <br />
            • The system provides a robust tool for effective monitoring and
            progress measuring such that reports can be generated on a daily,
            weekly, monthly, quarterly, and annual basis.
            <br />
            • BRAG status reporting provides a quick and easy way to identify
            areas requiring attention and the progress of each presidential
            directive or pronouncement.
            <br />
            • It is optimized for web and mobile applications for enhanced user
            experience and wider accessibility.
            <br />• PDMIS is a robust platform that provides safety for data,
            databases, and reports ensuring seamless operations.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Who are the users of PDMIS?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            • H.E. The President: The head of state uses the system to monitor
            the status of the various directives and pronouncements he has
            issued.
            <br />
            • The Chief of Staff and Head of Public Service (COS-HOPS): The
            COS-HOPS uses the system to monitor the implementation and progress
            of the directives.
            <br />
            • Public Policy and President’s Priority Initiatives Unit (PP &PPI):
            These users are responsible for uploading, reviewing, monitoring,
            and reporting the implementation of the various directives.
            <br />
            • Cabinet Secretaries: The CSs use the system to track the progress
            of the various directives and pronouncements being implemented by
            state departments under their ministries.
            <br />• Principal Secretaries: The PSs use the system to report on
            the planning and progress of the execution of various presidential
            directives and pronouncements.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>How do you register on the PDMIS?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            For you to have access to the system you have to first sign up by
            keying in your details such as first name, last name, official email
            address, personal number, designation, ministry, state department,
            telephone number, ID number, and gender.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              How do you log into the system after registration?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              After successful registration and verification into the system,
              you'll be directed to the sign-in page where you will be required
              to enter your official email. A one-time password (OTP) will be
              sent to your official email address. Enter the OTP in the field
              provided then click sign in. You will then reset your password to
              a password of your choice.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              Which email addresses are accepted by PDMIS?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Only official email addresses will be accepted by the system e.g.,
              go.ke.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>What are the dashboard features?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              • **Search bar:** Enables users to search for a specific directive
              using keywords.
              <br />
              • **Notification icon:** Allows users to receive updates
              concerning the system.
              <br />
              • **Profile icon:** Allows a user to view their profile
              activities, edit, and update their profile.
              <br />
              • **Theme setting tab:** Allows users to change from dark theme to
              light theme according to preference.
              <br />
              • **Reports tab:** Displays all the reports and maps that can be
              filtered by ministry, BETA pillars, and manifesto tracking
              category.
              <br />
              • **BETA pillars:** Displays the priority areas of the Kenyan
              government.
              <br />
              • **Home icon:** Enables a user to navigate back to the landing
              page.
              <br />
              • **New directive icon:** Allows a PP&PPI user to add a new
              directive.
              <br />
              • **Sign-up icon:** Allows new users to register into the system
              by entering their personal details.
              <br />
              • **Sign-in icon:** Allows registered users to access the system.
              <br />
              • **Sign-out icon:** Enables a user to log out of the system.
              <br />
              • **Directives tab:** Displays a list of all the directives
              showing their BRAG status.
              <br />
              • **Sort icon:** Enables users to sort the directives according to
              the BETA pillar, manifesto tracking category, date of issue, lead
              agency, implementation status, BRAG status, and responsible PSs.
              <br />
              • **Maps tab and map link:** Provides a geographical link to
              existing directives for geospatial data visualization and the map
              link allows users to access the spatial location of the specific
              directive and view it on a map. The map layer shows the progress
              of various directives.
              <br />• **Filter directives icon:** Allows a user to view relevant
              and actionable information based on ministry, state department,
              BETA pillar, manifesto trackers, and implementation status.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>What is a BETA pillar?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              BETA is an acronym for Bottom-up Economic Transformation Agenda
              that has five key priorities.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>What are Manifesto trackers?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              These are the major components that help track and analyze
              political manifestos. They include:
              <br />
              • Agriculture
              <br />
              • Transforming MSMEs
              <br />
              • Affordable Housing
              <br />
              • Health Care
              <br />
              • Digital Superhighway and Creative Economy
              <br />
              • Infrastructure
              <br />
              • Manufacturing
              <br />
              • Service Economy
              <br />
              • Education
              <br />
              • Environment and Climate Change
              <br />
              • Women
              <br />
              • Social Protection
              <br />
              • Governance
              <br />
              • Devolution
              <br />
              • Security Services
              <br />
              • Diaspora
              <br />
              The Public Policy and President’s Priority Initiatives unit tracks
              directives and pronouncements through the state departments.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>

      <div>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>What is BRAG status reporting?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The PDMIS uses a Blue, Red, Amber, and Green color status system
              to indicate the progress of each presidential directive or
              pronouncement. The BRAG status is dependent on the weighting of
              the activities.
              <br />
              • The Blue status represents a directive in progress
              <br />
              • The Red status means that the directive is on hold
              <br />
              • The Amber status means that the directive has not been acted
              upon
              <br />• The Green status shows a completed directive.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              What dashboards are displayed through the PDMIS?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              • <strong>H.E. dashboard:</strong> allows the president to access
              reports, statistics, and analytics and give comments.
              <br />•{" "}
              <strong>
                The Chief of Staff and Head of Public Service dashboard:
              </strong>{" "}
              allows the COS-HOPS to view and comment on the progress reports,
              statistics, and analytics on all the directives.
              <br />• <strong>PP and PPI head of unit dashboard:</strong> This
              dashboard is accessible to the head of PP and PPI. The dashboard
              will provide viewing rights for all directives and reports.
              <br />• <strong>PP and PPI data managers’ dashboard:</strong> Data
              managers are responsible for entering presidential directives,
              assigning a beta pillar, and a manifesto tracker, and keying in
              the lead agency into the PDMIS. They have access right to view and
              write all the reports.
              <br />• <strong>PP and PPI reviewer dashboard:</strong> allows
              users to review the assigned directives.
              <br />• <strong>CS dashboard:</strong> is accessible to the CSs to
              view all the directives assigned to their ministries.
              <br />• <strong>PS dashboard:</strong> is accessible to users from
              the state department, they are able to state the various
              activities, assign weights to the activities, assign status,
              assign a budget, and generate reports. Progress photos.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              What is the timeline for completing a specific directive?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Some directives are issued with a timeline by the president while
              other directives are provided by the lead agency.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              How are changes or updates to directives communicated to the
              responsible agency?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Notifications are sent via email and through the system. This
              information can be accessed by clicking on the notification icon.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              What happens for overlapping priority directives?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              When there are multiple directives issued by the President, and
              some of them conflict with each other or overlap in their
              objectives, the first directive of these directives is designated
              as the main directive. This main directive sets the primary
              agenda, and the other conflicting directives are treated as
              sub-directives or secondary to the main one. The sub-directive is
              then tied to the main directive.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              What documentation or evidence is required to support a priority
              directive?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              When entering a presidential directive to PDMIS, the data manager
              at PP&PPI attaches a scanned copy of the directive signed by the
              Chief of staff and head of the public service.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default FAQPage;
