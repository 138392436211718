import { useEffect, useState } from "react";
import {
  // CircleMarker,
  MapContainer,
  Marker,
  // Polygon,
  Popup,
  // Rectangle,
  TileLayer,
  Tooltip,
} from "react-leaflet";
// import TooltipCircle from "./Tooltips";
import "../../../styles/leaf.css";

export const LeafLayers = ({ geom }) => {
  const [coords, setCoords] = useState([0, 37.49]);

  useEffect(() => {
    // Extract coordinates from the geom string
    const coordinatesMatch = geom !== null ? geom.match(/\(([^)]+)\)/) : `0 0`;
    if (coordinatesMatch) {
      const [lon, lat] = coordinatesMatch[1].split(" ").map(Number);
      setCoords([lat, lon]);
    } else {
      const [lon] = 0;
      const  [lat] = 0;
      setCoords([lat, lon])
    }
  }, [geom]);


  return (
    coords[0] !== 51.49 && (
      <MapContainer center={coords} zoom={8} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* <TooltipCircle />
        <CircleMarker
          center={[51.51, -0.12]}
          pathOptions={{ color: "red" }}
          radius={20}
        >
          <Tooltip>Tooltip for CircleMarker</Tooltip>
        </CircleMarker> */}
        <Marker position={coords}>
          <Popup>Location</Popup>
          <Tooltip>Location</Tooltip>
        </Marker>
        {/* <Polygon pathOptions={{ color: "purple" }} positions={multiPolygon}>
          <Tooltip sticky>sticky Tooltip for Polygon</Tooltip>
        </Polygon>
        <Rectangle bounds={rectangle} pathOptions={{ color: "black" }}>
          <Tooltip direction="bottom" offset={[0, 20]} opacity={1} permanent>
            permanent Tooltip for Rectangle
          </Tooltip>
        </Rectangle> */}
      </MapContainer>
    )
  );
};
