import React from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import shadows from "@mui/material/styles/shadows";
import { alpha, useTheme } from "@mui/material/styles";
import * as yup from "yup";
import { useFormik } from "formik";
// import { useNavigate } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import axios from "axios";
import { backend_url } from "app/services/constants";
import { useState } from "react";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import logo from "images/logo.png";
import state_house_image from "images/state_house.jpg";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const VerifyOTP = () => {
  // const navigate = useNavigate();
  const Swal = useSwalWrapper();

  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const isLG = useMediaQuery(theme.breakpoints.down("lg"));

  const [loading, setLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const email_saved = localStorage.getItem("email");

  const validationSchema = yup.object({
    password: yup
      .string()
      .required("Please enter your password.")
      .min(8, "Password is too short - must be at least 6 chars."),
    otp_code: yup.string().required("Please enter your OTP code."),
  });

  const defaultValues = {
    password: "",
    otp_code: "",
  };

  function submit({ password, otp_code }) {
    const user = {
      password,
      otp_code,
      email: email_saved,
    };
    axios
      .post(
        `${backend_url}/api/token-otp/`,
        user,
        {
          headers: { "Content-Type": "application/json" },
        },
        { withCredentials: true }
      )
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Signed In successfully.",
          showConfirmButton: false,
          timer: 4500,
        });
        localStorage.clear();
        localStorage.setItem("access_token", res.data.access);
        localStorage.setItem("refresh_token", res.data.refresh);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data["access"]}`;
        window.location.href = "/directives?page=1";
        // navigate("/");
      })
      .catch((err) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title:
            err.response.data?.details === "OTP verification failed."
              ? "OTP verification failed. Try resending OTP."
              : err.response.data?.details,
          showConfirmButton: false,
          timer: 4500,
        });
        console.log(err);
      });
  }

  const formik = useFormik({
    initialValues: { defaultValues },
    validationSchema,
    onSubmit: submit,
  });

  const handleFieldErrors = () => {
    setShowErrors(true);
  };

  return (
    <Container component="main" maxWidth="xl">
      <Div
        sx={{
          flex: 1,
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: [(theme) => theme.spacing(0), (theme) => theme.spacing(4)],
          marginLeft: isSM || isMD || isLG ? "0px" : "-64px",
        }}
      >
        <Card
          sx={{
            maxWidth: "100%",
            mb: 4,
            marginTop: isSM || isMD || isLG ? 8 : 8,
            padding: isSM || isMD || isLG ? 0 : 4,
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: isSM || isMD || isLG ? 1 : 4,
              flexDirection: "column",
            }}
          >
            <Div
              sx={{
                // maxWidth: isSM ? "inherit" : 540,
                mb: 3,
                // display: "inline-flex",
              }}
            >
              <img
                style={{
                  width: isSM
                    ? "90vw"
                    : isMD
                    ? "95vw"
                    : isLG
                    ? "95vw"
                    : "540px",
                }}
                src={`${logo}`}
              />
            </Div>

            <Div sx={{ position: "relative", height: "100px" }}>
              <Div
                sx={{
                  flex: 1,
                  inset: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  // backgroundColor: (theme) =>
                  //   alpha(theme.palette.common.black, 0.5),
                  p: (theme) => theme.spacing(3),
                }}
              >
                <Typography
                  variant={"h2"}
                  sx={{
                    // color: "common.white",
                    fontSize: "2.5rem",
                    fontWeight: "bold",
                    mb: 0,
                  }}
                >
                  Sign In
                </Typography>
              </Div>
            </Div>
            <CardContent sx={{ pt: 0 }}>
              <form onSubmit={formik.handleSubmit}>
                <Div sx={{ mb: 3 }}>
                  <TextField
                    fullWidth
                    id="otp_code"
                    name="otp_code"
                    label="OTP Code"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.otp_code}
                    error={
                      (showErrors || formik.touched.otp_code) &&
                      Boolean(formik.errors.otp_code)
                    }
                    helperText={
                      (showErrors || formik.touched.otp_code) &&
                      formik.errors.otp_code
                    }
                  />
                </Div>
                <Div sx={{ mb: 3, mt: 1 }}>
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    error={
                      (showErrors || formik.touched.password) &&
                      Boolean(formik.errors.password)
                    }
                    helperText={
                      (showErrors || formik.touched.password) &&
                      formik.errors.password
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Div>

                <Button
                  fullWidth
                  type="submit"
                  color="secondary"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={loading}
                  onClick={() => {
                    // navigate("/");
                    handleFieldErrors();
                    setLoading(true);
                  }}
                  // disabled={formik.isValidating || !formik.dirty || !formik.isValid}
                >
                  Login
                </Button>

                <Typography
                  textAlign={"right"}
                  variant={"p"}
                  style={{ marginBottom: "6px" }}
                >
                  Did not receive an OTP?
                  <Link underline="none" to="/login">
                    <span style={{ marginLeft: "2px" }}>Resend OTP</span>
                  </Link>
                </Typography>

                <br />
                <br />

                <Typography textAlign={"left"} variant={"p"} mb={1}>
                  Don't have an account?
                  <Link underline="none" to="/signup">
                    <span style={{ marginLeft: "2px" }}>Sign up now</span>
                  </Link>
                </Typography>
              </form>
            </CardContent>
          </Box>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              flex: "auto",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100%",
              p: "64px",
              lg: { px: "112px" },
              overflow: "hidden",
              backgroundColor: "secondary.dark",
            }}
          >
            <svg
              sx={{
                position: "absolute",
                inset: 0,
                pointerEvents: "none",
              }}
              viewBox="0 0 960 540"
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMax slice"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Box
                component="g"
                sx={{
                  color: "secondary.light",
                  opacity: 0.2,
                  fill: "none",
                  stroke: "currentColor",
                  strokeWidth: "100",
                }}
              >
                <circle r="234" cx="196" cy="23" />
                <circle r="234" cx="790" cy="491" />
              </Box>
            </svg>
            <Box
              component="svg"
              sx={{
                position: "absolute",
                top: "-64px",
                right: "-64px",
                opacity: 0.2,
                color: "secondary.light",
              }}
              viewBox="0 0 220 192"
              width="220px"
              height="192px"
              fill="none"
            >
              <defs>
                <pattern
                  id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect x="0" y="0" width="4" height="4" fill="currentColor" />
                </pattern>
              </defs>
              <rect
                width="220"
                height="192"
                fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
              />
            </Box>

            <Box
              sx={{
                position: "relative",
                zIndex: 10,
                width: "100%",
                maxWidth: "200%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  lineHeight: "none",
                  color: "white",
                  textAlign: "left",
                  marginLeft: "-120px",
                }}
              >
                Presidential
              </Typography>
              <Typography
                sx={{
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  lineHeight: "none",
                  color: "white",
                  textAlign: "left",
                  marginLeft: "-120px",
                }}
              >
                Directives
              </Typography>
              <Typography
                sx={{
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  lineHeight: "none",
                  color: "white",
                  textAlign: "left",
                  marginLeft: "-120px",
                }}
              >
                Management
              </Typography>
              <Typography
                sx={{
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  lineHeight: "none",
                  color: "white",
                  textAlign: "left",
                  marginLeft: "-120px",
                }}
              >
                Information
              </Typography>
              <Typography
                sx={{
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  lineHeight: "none",
                  color: "white",
                  textAlign: "left",
                  marginLeft: "-120px",
                }}
              >
                System
              </Typography>
            </Box>
          </Box>
        </Card>
      </Div>
    </Container>
  );
};

export default VerifyOTP;
