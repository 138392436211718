import React, { useState } from "react";
import Div from "@jumbo/shared/Div";
import { Paper, Button, Typography } from "@mui/material";
import axios from "axios";
import { backend_url } from "app/services/constants";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const NewDirUpload = ({ user, token }) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedFileId, setUploadedFileId] = useState(null);
  const Swal = useSwalWrapper();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", 6);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        // withCredentials: true,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress);
        },
      };

      const response = await axios.put(
        `${backend_url}/ppi/upload/`,
        formData,
        config
      );

      setUploadedFileId(response.data.id); // Set the ID received from the server
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Directive uploaded successfully.",
        showConfirmButton: false,
        timer: 4500,
      });
      setLoading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.response.data?.details,
        showConfirmButton: false,
        timer: 4500,
      });
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ p: 4 }}>
      <Typography variant={"h2"} mb={3}>
        Upload a new Directive
      </Typography>

      <div>
        <h2>File Upload</h2>
        <input type="file" onChange={handleFileChange} />
        <button onClick={handleUpload}>Upload</button>

        {uploadProgress > 0 && (
          <div>
            <p>Upload Progress: {uploadProgress}%</p>
          </div>
        )}

        {uploadedFileId && (
          <div>
            <p>File ID: {uploadedFileId}</p>
          </div>
        )}
        <Div sx={{ mb: 3, mt: 1 }}></Div>

        {/* <Button
          fullWidth
          variant="contained"
          size="large"
          sx={{ my: 3 }}
          onClick={() => {
            setLoading(true);
            handleUpload();
          }}
        >
          {loading === true ? "Loading..." : "Upload"}
        </Button> */}
      </div>

      {/* <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleChange} />

        <Div sx={{ mb: 3, mt: 1 }}></Div>

        <Button
          fullWidth
          type="submit"
              color="secondary"
          variant="contained"
          size="large"
          sx={{ my: 3 }}
          onClick={() => {
            setLoading(true);
          }}
          // disabled={formik.isValidating || !formik.dirty || !formik.isValid}
        >
          {loading === true ? "Loading..." : "Submit"}
        </Button>
      </form> */}
    </Paper>
  );
};

export default NewDirUpload;
