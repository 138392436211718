import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import {
  Accordion,
  AccordionDetails,
  AvatarGroup,
  Button,
  Card,
  LinearProgress,
  Typography,
} from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Chip from "@mui/material/Chip";
import { alpha } from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import { Link } from "react-router-dom";
import Comments from "./comments/Comments";

const ProjectItem = ({ percentage, dir, dir_id, sub, token, user }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const truncateText = (text, maxWords) => {
    const words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + "...";
    }
    return text;
  };

  const toSentenceCase = (text) => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  return (
    dir !== undefined && (
      <Card
        sx={{
          mb: 1,
          ml: sub === true ? 2 : 0,
          my: sub === true ? 1 : 1,
        }}
      >
        <Accordion
          expanded={expanded}
          onChange={handleClick}
          square
          sx={{
            borderRadius: 2,
            backgroundColor:
              sub === true
                ? (theme) => alpha(theme.palette.common.black, 0.05)
                : "inherit",
          }}
        >
          <AccordionSummary
            expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              px: 3,
              flexDirection: "row-reverse",

              "& .MuiAccordionSummary-content": {
                alignItems: "center",

                "&.Mui-expanded": {
                  margin: "12px 0",
                },
              },
              ".MuiAccordionSummary-expandIconWrapper": {
                borderRadius: 1,
                border: 1,
                color: "text.secondary",
                borderColor: "divider",
                transform: "none",
                height: 28,
                width: 28,
                alignItems: "center",
                justifyContent: "center",
                mr: 1,

                "&.Mui-expanded": {
                  transform: "none",
                  color: "primary.main",
                  borderColor: "primary.main",
                },

                "& svg": {
                  fontSize: "1.25rem",
                },
              },
            }}
          >
            <Div
              sx={{
                width: { xs: "auto", lg: "26%" },
                flexShrink: 0,
                px: 1,
                flex: { xs: "1", lg: "0 1 auto" },
              }}
            >
              <Typography variant={"h5"} mb={0.5} fontSize={14}>
                {truncateText(dir.directives[0].summary_name, 30)}
              </Typography>

              <Typography
                fontSize={"12px"}
                variant={"body1"}
                color={"text.secondary"}
              >
                <CalendarTodayOutlinedIcon
                  sx={{
                    verticalAlign: "middle",
                    fontSize: "1rem",
                    mt: -0.25,
                    mr: 1,
                  }}
                />
                {dir.date_issue}
              </Typography>
            </Div>
            <Div
              sx={{
                display: { xs: "none", lg: "block" },
                width: "20%",
                flexShrink: 0,
                px: 2,
              }}
            >
              <LinearProgress
                variant={"determinate"}
                // color={
                //   percentage * 100 === 0
                //     ? "errorLight"
                //     : percentage * 100 === 100
                //     ? "successLight"
                //     : percentage * 100 >= 1 && percentage * 100 <= 49
                //     ? "primaryLight"
                //     : percentage * 100 >= 50 && percentage * 100 <= 99
                //     ? "secondaryLight"
                //     : "primary"
                // }
                color={
                  dir.directives[0].implementation_status === "Not_Started"
                    ? "errorLight"
                    : dir.directives[0].implementation_status === "In_Progress"
                    ? "secondaryLight"
                    : dir.directives[0].implementation_status === "Over_Due"
                    ? "primaryLight"
                    : "successLight"
                }
                value={percentage === 0 ? 100 : percentage * 100}
                sx={{
                  height: 6,
                  borderRadius: 2,
                  backgroundColor: "#E9EEEF",
                }}
              />
              <Typography
                // fontSize={"12px"}
                variant={"h6"}
                // color={"text.secondary"}
                mt={0.25}
              >
                {`${Math.round(percentage * 100)}%`}
              </Typography>
            </Div>
            <Div
              sx={{
                display: { xs: "none", lg: "block" },
                width: "16%",
                flexShrink: 0,
                px: 1,
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Target date
              </Typography>
              <Typography variant={"body1"}>
                {dir.directives[0].target_date}
              </Typography>
            </Div>

            <Div
              sx={{
                display: { xs: "none", lg: "block" },
                width: "16%",
                flexShrink: 0,
                px: 1,
              }}
            >
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                The Plan
              </Typography>
              <Typography variant={"body1"}>
                {dir.directives[0].manifesto_tracking_category}
              </Typography>
            </Div>
            <Div sx={{ flex: { xs: "0 1 auto", lg: 1 }, flexShrink: 0, px: 1 }}>
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
                sx={{
                  display: { xs: "none", lg: "block" },
                }}
              >
                Status
              </Typography>
              <Chip
                color={
                  dir.directives[0].implementation_status === "Not_Started"
                    ? "error"
                    : dir.directives[0].implementation_status === "In_Progress"
                    ? "info"
                    : dir.directives[0].implementation_status === "Over_Due"
                    ? "warning"
                    : "success"
                }
                size={"small"}
                label={
                  dir.directives[0].implementation_status === "Not_Started"
                    ? "Not Started"
                    : dir.directives[0].implementation_status === "In_Progress"
                    ? "In Progress"
                    : dir.directives[0].implementation_status === "Over_Due"
                    ? "Over Due"
                    : "Completed"
                }
              />
            </Div>

            {/* <AvatarGroup max={3} sx={{ display: { xs: "none", lg: "flex" } }}>
              <Avatar />
            </AvatarGroup> */}
          </AccordionSummary>

          <AccordionDetails
            sx={{
              borderTop: 1,
              borderColor: "divider",
              p: (theme) => theme.spacing(2, 2, 2, 8.25),
            }}
          >
            {/* <Typography variant={"h5"}>Comments</Typography> */}
            <Div
              sx={{
                display: { xs: "flex", lg: "none" },
                minWidth: 0,
                flexDirection: "column",
              }}
            >
              <Div
                sx={{
                  display: "flex",
                  minWidth: 0,
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 1,
                  mb: 2,
                }}
              >
                <Div>
                  <Typography
                    fontSize={"12px"}
                    variant={"h6"}
                    color={"text.secondary"}
                    mb={0.25}
                  >
                    Target date
                  </Typography>
                  <Typography variant={"body1"}>
                    {dir.directives[0].target_date}
                  </Typography>
                </Div>
              </Div>
              <Div sx={{ mb: 3, maxWidth: 280 }}>
                <Typography
                  fontSize={"12px"}
                  variant={"h6"}
                  color={"text.secondary"}
                  mb={1}
                >
                  Progress
                </Typography>
                <LinearProgress
                  variant={"determinate"}
                  color={
                    percentage * 100 === 0
                      ? "errorLight"
                      : percentage * 100 === 100
                      ? "successLight"
                      : percentage * 100 >= 1 && percentage * 100 <= 49
                      ? "primaryLight"
                      : percentage * 100 >= 50 && percentage * 100 <= 99
                      ? "secondaryLight"
                      : "primary"
                  }
                  value={percentage === 0 ? 100 : percentage * 100}
                  sx={{
                    height: 6,
                    borderRadius: 2,
                    backgroundColor: "#E9EEEF",
                  }}
                />
              </Div>
            </Div>
            <Comments
              user={user}
              listView={true}
              token={token}
              scrollHeight={100}
              directive_no={dir_id}
            />

            <Link to={`/directive/${dir_id}#${percentage}`}>
              <Button sx={{ my: 2 }} variant="contained">
                Read More
              </Button>
            </Link>
          </AccordionDetails>
        </Accordion>
      </Card>
    )
  );
};
/* Todo item prop define */
export default ProjectItem;
