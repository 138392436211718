import React from "react";
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import logo from 'images/coat.png';

const Logo = ({ mini, mode, sx }) => {
  return (
    <Div sx={{ display: "inline-flex", ...sx }}>
      <Link href={"/directives?page=1"}>
        {!mini ? (
          <img
            style={{ height: "56px" }}
            src={
              mode === "light"
                ? `${logo}`
                : `${logo}`
              // : `${ASSET_IMAGES}/logo-white.png`
            }
            alt="Jumbo React"
          />
        ) : (
          <img
            style={{ height: "32px" }}
            src={
              mode === "light"
                ? `${logo}`
                : `${logo}`
              // ? `${ASSET_IMAGES}/logo-short.png`
              // : `${ASSET_IMAGES}/logo-short-white.png`
            }
            alt="Jumbo React"
          />
        )}
      </Link>
    </Div>
  );
};

Logo.defaultProps = {
  mode: "light",
};

export default Logo;
