import React from "react";
import {
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Tooltip,
  Typography,
  Avatar,
  Stack,
  Checkbox,
  Pagination,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "@emotion/styled";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Span from "@jumbo/shared/Span";
import JumboListItem from "@jumbo/components/JumboList/components/JumboListItem";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import PSActivityDetail from "./PSActivityDetails";
import PSUpdateActivity from "./PSUpdateActivity";
import axios from "axios";
import { backend_url } from "app/services/constants";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import PSDeleteActivity from "./PSDeleteActivity";

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const PSActivityList = ({
  dir_id,
  contact,
  token,
  activity,
  remainingWeight,
  currentPage,
  totalPages,
  handlePageChange,
  links,
  user,
}) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const [checked, setChecked] = React.useState(
    activity !== undefined &&
      activity.properties.status_activities === "Completed"
      ? true
      : false
  );
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const Swal = useSwalWrapper();

  const handleChange = () => {
    setChecked(!checked);
    handleSubmit();
  };

  const showPSActivityDetail = React.useCallback(() => {
    showDialog({
      content: <PSActivityDetail activity={activity} onClose={hideDialog} />,
    });
  }, [showDialog, hideDialog, activity]);

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        // default:
        showDialog({
          // title: "Update activity detail",
          content: (
            <PSUpdateActivity
              dir_id={dir_id}
              onClose={hideDialog}
              remainingWeight={remainingWeight}
              token={token}
              activity={activity}
              // onSave={hideDialogAndRefreshContactsList}
            />
          ),
        });
        break;
      case "delete":
        hideDialog();
      //   showDialog({
      //     content: (
      //       <PSDeleteActivity
      //         dir_id={dir_id}
      //         token={token}
      //         handleOpenDelete={handleOpenDelete}
      //         handleCloseDelete={handleCloseDelete}
      //         openDelete={openDelete}
      //       />
      //     ),
      //   });
    }
  };

  const handleSubmit = () => {
    const activityUpdate = {
      id: activity.id,
      name: activity.properties.name,
      description: activity.properties.description,
      weighting: activity.properties.weighting,
      budget_activities: activity.properties.budget_activities,
      status_activities: checked === false ? "Completed" : "In_Progress",
      start_date: activity.properties.start_date,
      end_date: activity.properties.end_date,
      location: activity.properties.location,
    };
    axios
      .put(
        `${backend_url}/ppi/activity/`,
        activityUpdate,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(res);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Activity updated successfully.",
          showConfirmButton: false,
          timer: 4500,
        });
        window.location.reload();
        // activity.push(res.data);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: err.response.data?.details,
          showConfirmButton: false,
          timer: 4500,
        });
      });
  };

  return (
    <React.Fragment>
      <JumboListItem
        componentElement={"div"}
        itemData={contact}
        secondaryAction={
          <JumboDdMenu
            icon={<MoreHorizIcon />}
            menuItems={[
              { icon: <EditIcon />, title: "Edit", action: "edit" },
              {
                icon: <DeleteIcon onClick={handleOpenDelete} />,
                title: "Delete",
                action: "delete",
              },
            ]}
            onClickCallback={handleItemAction}
          />
        }
        sx={{
          cursor: "pointer",
          borderTop: 1,
          borderColor: "divider",
          "&:hover": {
            bgcolor: "action.hover",
          },
        }}
      >
        <PSDeleteActivity
          activity_id={activity.id}
          token={token}
          handleOpenDelete={handleOpenDelete}
          handleCloseDelete={handleCloseDelete}
          openDelete={openDelete}
        />

        {user.designation === "PS" && (
          <ListItemIcon sx={{ minWidth: 40 }}>
            {checked === true ? (
              <Tooltip title={"In_Progress"}>
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={"Completed"}>
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Tooltip>
            )}
          </ListItemIcon>
        )}
        <ListItemAvatar onClick={showPSActivityDetail}>
          <Avatar alt={contact.name} src={contact.profile_pic} />
        </ListItemAvatar>
        <ListItemText
          onClick={showPSActivityDetail}
          primary={
            <Typography variant={"body1"} component={"div"}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                sx={{ minWidth: 0 }}
              >
                <Item
                  sx={{
                    flexBasis: { xs: "100%", sm: "50%", md: "25%" },
                  }}
                >
                  <Typography
                    variant={"h5"}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap
                  >
                    {activity.properties.name}
                  </Typography>
                  <Typography
                    variant={"body1"}
                    noWrap
                    color={"text.secondary"}
                    sx={{
                      display: { sm: "none" },
                    }}
                  >
                    {activity.properties.end_date}
                  </Typography>
                </Item>
                <Item
                  sx={{
                    flexBasis: { sm: "50%", md: "28%" },
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  <Typography variant={"body1"} noWrap>
                    Target Date: {activity.properties.end_date}
                  </Typography>
                </Item>
                {activity.properties.budget_activities !== null && (
                  <Item
                    sx={{
                      flexBasis: { md: "25%" },
                      display: { xs: "none", md: "block" },
                    }}
                  >
                    <Typography variant={"body1"} noWrap>
                      KES{" "}
                      {activity.properties.budget_activities.toLocaleString()}
                    </Typography>
                  </Item>
                )}
                <Item
                  sx={{
                    flexBasis: { md: "22%" },
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <JumboChipsGroup
                    chips={contact.labels}
                    mapKeys={{ label: "name" }}
                    spacing={1}
                    size={"small"}
                    max={1}
                  />
                  <Typography variant={"body1"} noWrap>
                    Weight:{" "}
                    {activity.properties.weighting
                      .toFixed(3)
                      .replace(/\.?0+$/, "")}
                  </Typography>
                </Item>
              </Stack>
            </Typography>
          }
        />
        {activity !== undefined && (
          <div className="my-16 mx-8">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              previousPage={links.previous}
              nextPage={links.next}
            />
          </div>
        )}
      </JumboListItem>
    </React.Fragment>
  );
};
/* Todo contact, view prop define */
export default PSActivityList;
