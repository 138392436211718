import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Sector } from "recharts";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { List, ListItemButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const RadialPieChart = ({ inProgress, completed, notStarted, overDue }) => {
  const [chartData, setChartData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const COLORS = {
    Not_Started: "#E73145",
    In_Progress: "#3E5ED6",
    Over_Due: "#F39711",
    Completed: "#00A073",
  };

  useEffect(() => {
    setChartData([
      { name: "Not_Started", value: notStarted, color: COLORS.Not_Started },
      { name: "In_Progress", value: inProgress, color: COLORS.In_Progress },
      { name: "Over_Due", value: overDue, color: COLORS.Over_Due },
      { name: "Completed", value: completed, color: COLORS.Completed },
    ]);
  }, [
    inProgress,
    completed,
    notStarted,
    overDue,
    COLORS.Not_Started,
    COLORS.In_Progress,
    COLORS.Over_Due,
    COLORS.Completed,
  ]);

  const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
      props;
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 8}
          outerRadius={outerRadius + 11}
          fill={fill}
        />
      </g>
    );
  };

  const onPieHover = (_, index) => setActiveIndex(index);
  const onMouseLeave = () => setActiveIndex(0);

  return (
    <div>
      <JumboCardQuick reverse wrapperSx={{ pb: 0 }}>
        <Typography fontWeight="500" variant="h3" sx={{ mb: 0 }}>
          Directives per status implementation
        </Typography>
        <div>
          <PieChart width={300} height={280}>
            <Pie
              activeIndex={activeIndex}
              data={chartData}
              dataKey="value"
              nameKey="name"
              outerRadius={100}
              innerRadius={80}
              activeShape={renderActiveShape}
              onMouseOver={onPieHover}
              onMouseLeave={onMouseLeave}
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </div>
        <List disablePadding>
          {chartData.map((entry, index) => (
            <ListItemButton
              key={index}
              className={activeIndex === index ? "active" : ""}
              onMouseOver={() => onPieHover(null, index)}
              onMouseLeave={onMouseLeave}
            >
              <Typography
                variant="body1"
                sx={{
                  color: entry.color,
                  fontSize: "16px",
                  textDecoration: "none",
                }}
                component={Link}
                to={`/directives?page=1&status=${entry.name}&cat=`}
              >
                {entry.name.replace("_", " ")}:{" "}
                <span>{entry.value} Directives</span>
              </Typography>
            </ListItemButton>
          ))}
        </List>
      </JumboCardQuick>
    </div>
  );
};

export default RadialPieChart;
