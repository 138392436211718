import { marked } from "marked";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const Terms = () => {
  const [state, setState] = useState("");
  useEffect(() => {
    const readmePath = require("./terms_and_conditions.md");

    fetch(readmePath)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        setState(marked.parse(text));
      });
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        padding: "24px",
        paddingSm: "40px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginRight: "64px"
        //   maxWidth: "64rem",
        }}
      >
        <div style={{ marginTop: "16px", marginTopSm: "32px" }}>
          <Button
            component={Link}
            to={-1}
            color="secondary"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
        </div>
        <div
          style={{
            marginTop: "8px",
            fontSize: "1.25rem",
            fontSizeSm: "1.5rem",
            fontWeight: "800",
            letterSpacing: "-0.025em",
            lineHeight: "1.25",
          }}
        >
          Terms and conditions
        </div>
        <div
          style={{
            marginTop: "6px",
            marginTopSm: "24px",
            lineHeight: "1.25",
            letterSpacing: "-0.025em",
          }}
          dangerouslySetInnerHTML={{ __html: state }}
        />
      </div>
    </div>
  );
};

export default Terms;
