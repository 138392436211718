import { Stack, Card, CardMedia, Typography, Box } from "@mui/material";
import React from "react";
import { backend_url } from "app/services/constants";
import { useEffect } from "react";
import { alpha } from "@mui/material/styles";
import agriculture from "images/agriculture.jpg";
import affordable_housing from "images/affordable_housing.jpg";
import msmes from "images/msmes.jpg";
import digital_superhighway from "images/digital_superhighway.jpeg";
import uhc from "images/uhc.jpeg";

export const ThePETs = ({ token, setWeights, currentPage, betaPillar, setBetaPillar }) => {

  useEffect(() => {
    async function fetchData() {
      const params = new URLSearchParams({ ppi: currentPage });
      if (betaPillar) {
        params.append("beta_pillar", betaPillar);
      }

      const url = `${backend_url}/ppi/weights/?${params}`;

      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setWeights(data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    }

    fetchData();
  }, [setWeights, currentPage, betaPillar, token]);


  return (
    <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
      {[
        {
          label: "Agriculture",
          image: agriculture,
          filter_name: "Agriculture",
        },
        {
          label: "MSME's",
          image: msmes,
          filter_name: "Micro Small and Medium Sized Enterprise Economy",
        },
        {
          label: "Affordable Housing",
          image: affordable_housing,
          filter_name: "Affordable Housing",
        },
        {
          label: "UHC",
          image: uhc,
          filter_name: "Universal Healthcare Coverage",
        },
        {
          label: "Digital Superhighway and Creative Economy",
          image: digital_superhighway,
          filter_name: "Digital Superhighway and Creative Economy",
        },
      ].map((item, index) => (
        <Card
          key={index}
          sx={{ flex: 1, mb: 4, maxWidth: "100%" }}
          onClick={() => setBetaPillar(item.filter_name)}
        >
          <Box
            sx={{ position: "relative", height: { xs: "150px", sm: "200px" }, cursor: "pointer"}}
          >
            <CardMedia
              component="img"
              alt={item.label}
              height="100%"
              image={item.image}
            />
            <Box
              sx={{
                flex: 1,
                inset: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: (theme) =>
                  alpha(theme.palette.common.black, 0.5),
                p: 2,
                textAlign: "center",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "common.white",
                  fontSize: { xs: "1rem", sm: "1.5rem" },
                }}
              >
                {item.label}
              </Typography>
            </Box>
          </Box>
        </Card>
      ))}
    </Stack>
  );
};
