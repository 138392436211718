import { useState, useEffect } from "react";
import { backend_url } from "app/services/constants";
import {
  useTheme,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import fileDownload from "js-file-download";
import {
  Document,
  Packer,
  Paragraph,
  AlignmentType,
  Table as DocxTable,
  TableRow as DocxTableRow,
  TableCell as DocxTableCell,
  WidthType,
} from "docx";

export default function Matrix({ token, user }) {
  const [matrixData, setMatrixData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  const queryParams = new URLSearchParams(window.location.search);

  // Decode the URL parameters to handle cases where spaces are encoded as %20
  const county = decodeURIComponent(queryParams.get("county") || "");
  const ministry = decodeURIComponent(queryParams.get("ministry") || "");
  const lead_agency = decodeURIComponent(queryParams.get("lead_agency") || "");

  const url = lead_agency // Check if both ministry and lead_agency exist
    ? `${backend_url}/ppi/weights/?limit=2000&offset=0&county=${county}&ministry=${ministry}&lead_agency=${lead_agency}`
    : ministry !== "" && ministry !== "null"
    ? `${backend_url}/ppi/weights/?limit=2000&offset=0&county=${county}&ministry=${ministry}`
    : lead_agency
    ? `${backend_url}/ppi/weights/?limit=2000&offset=0&county=${county}&lead_agency=${lead_agency}`
    : county && ministry === "" && ministry === "null"
    ? `${backend_url}/ppi/weights/?limit=2000&offset=0&county=${county}`
    : `${backend_url}/ppi/weights/?limit=2000&offset=0&county=${county}`;

  useEffect(() => {
    async function fetchData() {
      setLoading(true); // Start loading state
      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setMatrixData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [token, url]);

  const downloadCSV = () => {
    let csvContent =
      "S/No,Date,County,Directive,Cost,State Department,Progress\n";
    matrixData?.results.forEach((row, index) => {
      const csvRow = [
        index + 1,
        row.date_issue || "N/A",
        `${row.county} County`,
        row.directives[0].summary_name || "N/A",
        row.directives[0].budget.toLocaleString() || "0",
        row.state_department
          .toLowerCase()
          .replace(
            /\w\S*/g,
            (text) =>
              text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
          ) || "N/A",
        row.directives[0].implementation_status === "Not_Started"
          ? "Not Started"
          : row.directives[0].implementation_status === "In_Progress"
          ? "In Progress"
          : row.directives[0].implementation_status === "Over_Due"
          ? "Over Due"
          : "Completed",
      ];
      csvContent += csvRow.join(",") + "\n";
    });
    fileDownload(csvContent, `${county}_County_Directives.csv`);
  };

  const downloadDocx = async () => {
    const doc = new Document({
      creator: "Your Application Name", // Set creator metadata
      title: `Directives for ${county} County`, // Set title
      description: `List of presidential directives for ${county} county.`,
      sections: [
        {
          properties: {}, // Optional section properties
          children: [
            new Paragraph({
              text: "PRESIDENTIAL DIRECTIVES AND PRONOUNCEMENTS", // First line
              alignment: AlignmentType.CENTER, // Center-align this line
            }),
            new Paragraph({
              text: `${county.toUpperCase()} COUNTY`, // Second line (County name in uppercase)
              alignment: AlignmentType.CENTER, // Center-align this line
            }),
            new DocxTable({
              rows: [
                new DocxTableRow({
                  children: [
                    new DocxTableCell({ children: [new Paragraph("S/No")] }),
                    new DocxTableCell({ children: [new Paragraph("Date")] }),
                    new DocxTableCell({ children: [new Paragraph("County")] }),
                    new DocxTableCell({
                      children: [new Paragraph("Directive")],
                    }),
                    new DocxTableCell({
                      children: [new Paragraph("Cost")],
                    }),
                    new DocxTableCell({
                      children: [new Paragraph("State Department")],
                    }),
                    new DocxTableCell({
                      children: [new Paragraph("Progress")],
                    }),
                  ],
                }),
                ...matrixData?.results
                  ?.slice() // Create a copy of the array to avoid mutating the original data
                  .sort((a, b) => {
                    const dateA = new Date(a.date_issue);
                    const dateB = new Date(b.date_issue);

                    // If the date_issue is missing, treat it as the oldest
                    if (!a.date_issue) return 1;
                    if (!b.date_issue) return -1;

                    return dateB - dateA; // Sort in descending order (most recent first)
                  })
                  .map((row, index) => {
                    return new DocxTableRow({
                      children: [
                        new DocxTableCell({
                          width: { size: 10, type: WidthType.PERCENTAGE },
                          children: [new Paragraph((index + 1).toString())],
                        }),
                        new DocxTableCell({
                          width: { size: 20, type: WidthType.PERCENTAGE },
                          children: [new Paragraph(row.date_issue || "N/A")],
                        }),
                        new DocxTableCell({
                          width: { size: 20, type: WidthType.PERCENTAGE },
                          children: [new Paragraph(`${row.county} County`)],
                        }),
                        new DocxTableCell({
                          width: { size: 30, type: WidthType.PERCENTAGE },
                          children: [
                            new Paragraph(
                              row.directives[0].summary_name || "N/A"
                            ),
                          ],
                        }),
                        new DocxTableCell({
                          width: { size: 20, type: WidthType.PERCENTAGE },
                          children: [
                            new Paragraph(
                              row.directives[0].budget.toLocaleString() || 0
                            ),
                          ],
                        }),
                        new DocxTableCell({
                          width: { size: 30, type: WidthType.PERCENTAGE },
                          children: [
                            new Paragraph(
                              row.state_department
                                .toLowerCase()
                                .replace(
                                  /\w\S*/g,
                                  (text) =>
                                    text.charAt(0).toUpperCase() +
                                    text.substring(1).toLowerCase()
                                ) || "N/A"
                            ),
                          ],
                        }),
                        new DocxTableCell({
                          width: { size: 20, type: WidthType.PERCENTAGE },
                          children: [
                            new Paragraph(
                              row.directives[0].implementation_status ===
                              "Not_Started"
                                ? "Not Started"
                                : row.directives[0].implementation_status ===
                                  "In_Progress"
                                ? "In Progress"
                                : row.directives[0].implementation_status ===
                                  "Over_Due"
                                ? "Over Due"
                                : "Completed"
                            ),
                          ],
                        }),
                      ],
                    });
                  }),
              ],
            }),
          ],
        },
      ],
    });

    const buffer = await Packer.toBlob(doc);
    fileDownload(buffer, `${county}_Directives.docx`);
  };

  if (loading) {
    return <CircularProgress />; // Show loading indicator
  }

  if (error) {
    return <div>Error: {error}</div>; // Display error if occurred
  }

  return (
    <Div>
      <JumboCardQuick
        title={
          <Typography
            component={"div"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                flexWrap: "wrap",
              },
            }}
          >
            <Typography
              variant={"h3"}
              mb={0}
              sx={{
                minWidth: 245,
                [theme.breakpoints.down("md")]: {
                  minWidth: "100%",
                  marginBottom: 2,
                },
              }}
            >
              PRESIDENTIAL DIRECTIVES AND PRONOUNCEMENTS
            </Typography>
            <Typography
              variant={"h3"}
              mb={0}
              sx={{
                minWidth: 245,
                [theme.breakpoints.down("md")]: {
                  minWidth: "100%",
                  marginBottom: 2,
                },
              }}
            >
              {county} County
            </Typography>
          </Typography>
        }
      >
        {/* <Button variant="contained" onClick={downloadCSV} sx={{ mb: 2, mr: 2 }}>
          Download CSV
        </Button> */}

        {/* Tahoma 13 or 12 */}
        {(user.designation === "PPI_1" ||
          user.designation === "PPI_1" ||
          user.designation === "PPI_3") && (
          <Button
            color="secondary"
            variant="contained"
            onClick={downloadDocx}
            sx={{ mb: 2 }}
          >
            Download as DOCX
          </Button>
        )}

        {/* <Button
          color="secondary"
          variant="contained"
          onClick={downloadDocx}
          sx={{ mb: 2 }}
        >
          Download as DOCX
        </Button> */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S/No</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>County</TableCell>
                <TableCell>Directive</TableCell>
                <TableCell>Cost</TableCell>
                <TableCell>State Department</TableCell>
                <TableCell>Progress</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {matrixData?.results
                ?.slice() // Create a copy of the array to avoid mutating the original data
                .sort((a, b) => {
                  const dateA = new Date(a.date_issue);
                  const dateB = new Date(b.date_issue);

                  // If the date_issue is missing, treat it as the oldest
                  if (!a.date_issue) return 1;
                  if (!b.date_issue) return -1;

                  return dateB - dateA; // Sort in descending order (most recent first)
                })
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.date_issue || "N/A"}</TableCell>
                    <TableCell>{`${row.county} County`}</TableCell>
                    <TableCell>
                      {row.directives[0].summary_name || "N/A"}
                    </TableCell>
                    <TableCell>
                      {row.directives[0].budget.toLocaleString() || 0}
                    </TableCell>
                    <TableCell>
                      {row.state_department
                        .toLowerCase()
                        .replace(
                          /\w\S*/g,
                          (text) =>
                            text.charAt(0).toUpperCase() +
                            text.substring(1).toLowerCase()
                        ) || "N/A"}
                    </TableCell>
                    <TableCell>
                      {row.directives[0].implementation_status === "Not_Started"
                        ? "Not Started"
                        : row.directives[0].implementation_status ===
                          "In_Progress"
                        ? "In Progress"
                        : row.directives[0].implementation_status === "Over_Due"
                        ? "Over Due"
                        : "Completed"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </JumboCardQuick>
    </Div>
  );
}
