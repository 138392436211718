import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MapKey = ({ spectralColors, quantileRanges }) => {
  return (
    <div>
      <Accordion elevation={0} defaultExpanded={false} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          disableRipple
        >
          <Typography variant="h3" className="text-sm font-bold">
            Map Key
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {quantileRanges.map((range, index) => (
            <Button
              key={index}
              style={{
                backgroundColor: spectralColors[index],
                color: "#FFF",
                marginBottom: "8px",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography variant="body2">
                    {`Range: ${range.min} - ${range.max}`}
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default MapKey;
