import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { backend_url } from "app/services/constants";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const DeleteActivityDialog = ({ openDelete, handleClose, activity_id, token }) => {
  const [loading, setLoading] = useState(false);
  const Swal = useSwalWrapper();

  const submitTrip = () => {
    setLoading(true);
    axios
      .delete(
        `${backend_url}/ppi/activity/?` +
          new URLSearchParams({
            activity_id: activity_id,
          }),
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        console.log(res);
        setLoading(false);
        handleClose(); // Close the dialog
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Activity deleted successfully.",
          showConfirmButton: false,
          timer: 4500,
        });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: err.response.data?.details,
          showConfirmButton: false,
          timer: 4500,
        });
      });
  };

  return (
    <Dialog open={openDelete} onClose={handleClose}>
      <DialogTitle>Delete Activity</DialogTitle>
      <DialogContent>
            <Typography variant="p">
            Are you sure about deleting this activity? You won't be able to
            recover this activity later.
            </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={submitTrip} color="error" variant="contained" disabled={loading}>
          {loading ? "Deleting..." : "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PSDeleteActivity = ({ activity_id, token, handleCloseDelete, handleOpenDelete, openDelete }) => {

  return (
    <div>
      {/* <Button variant="outlined" color="secondary" onClick={handleOpenDelete}>
        Delete Activity
      </Button> */}
      <DeleteActivityDialog
        openDelete={openDelete}
        handleClose={handleCloseDelete}
        activity_id={activity_id}
        token={token}
      />
    </div>
  );
};

export default PSDeleteActivity;
