import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Chip from "@mui/material/Chip";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { backend_url } from "app/services/constants";

const ConfirmRedirect = ({}) => {
  const [openMessage, setOpenMessage] = useState(false);
  // const [severity, setSeverity] = useState("")
  const [message, setMessage] = useState(null);
  const search = useLocation().search;
  const slug = new URLSearchParams(search).get("trcjid");

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/auth/user/verify_email/?` +
          new URLSearchParams({
            trcjid: slug,
          })
      ).then((data) => {
        setOpenMessage(true);
        setMessage(data);
        localStorage.clear();
        // window.location.href = '/sign-in';
      });
    }

    fetchData();
  }, [slug]);

  return (
    <Container sx={{ py: 16, ml: 0 }}>
      <Card>
        <CardHeader
          title={
            message[0] === null ? (
              <Typography variant={"h1"} component="h1" my={3} ml={2}>
                <b> Account is being confirmed.</b>
              </Typography>
            ) : (
              <Typography variant={"h1"} component="h1" my={3} ml={2}>
                <b> Resend confirmation email.</b>
              </Typography>
            )
          }
        />

        {/* {openMessage === true && (
          <TravelSnackbar
            openMessage={openMessage}
            severity={
              message[0] === "The token was tampared with"
                ? "error"
                : message[0] === "Your account was succesfully activated"
                ? "success"
                : "error"
            }
            message={message}
          />
        )} */}

        <CardContent sx={{ p: 0 }}>
          <Div
            sx={{
              px: 3,
              display: "flex",
              minHeight: "100%",
              flexDirection: "column",
              minWidth: 0,
            }}
          >
            <Div sx={{ mb: 1 }}>
              {message[0] === "Your account was succesfully activated" ? (
                <Typography variant={"h2"} component="h2" my={3} ml={2}>
                  Account activated succesfully.
                </Typography>
              ) : (
                <Typography variant={"h2"} component="h2" my={3} ml={2}>
                  An error occured! Could not activate account.
                </Typography>
              )}
            </Div>
            <Div sx={{ mb: 1, display: "flex" }}>
              <Typography variant={"h2"} component="h2" my={3} ml={2}>
                Return to
              </Typography>
              <Link underline="none" to="/login">
                <Button
                  sx={{ ml: 2, mt: 2 }}
                  color="primary"
                  variant="contained"
                  size="medium"
                >
                  Sign In
                </Button>
              </Link>
            </Div>
            <Div sx={{ flex: 1 }}>
              <Div
                sx={{
                  position: "relative",
                  textAlign: "center",
                  mb: 2,
                  "&:after": {
                    display: "inline-block",
                    content: "''",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    height: "1px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "divider",
                  },
                }}
              >
                <Chip
                  label={"PDMIS"}
                  variant="outlined"
                  color={"info"}
                  sx={{
                    marginLeft: "1em",
                    position: "relative",
                    zIndex: 1,
                    bgcolor: (theme) => theme.palette.background.paper,
                    borderColor: "divider",
                    borderRadius: 2,
                  }}
                />
              </Div>
            </Div>
          </Div>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ConfirmRedirect;
