import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";

// Directive performance per plan

const CustomBarChart = ({ planCount, planImplStatus }) => {
  // const [selectedCategory, setSelectedCategory] = useState("All");
  const [activeIndex, setActiveIndex] = useState(null);
  const theme = useTheme();

  const sortedAverages1 = Object.keys(planCount)
    .map((plan) => {
      const totalInPlan = planCount[plan];
      // const inProgress = planImplStatus?.In_Progress?.[plan] || 0;
      const inProgress = planImplStatus?.In_Progress?.[plan] || 0;
      const completed = planImplStatus?.Completed?.[plan] || 0;

      // Calculate the total completed and in-progress items
      const implementedCount = inProgress + completed;

      // Calculate the average percentage for this plan
      const implementationAverage = (implementedCount / totalInPlan) * 100;

      return {
        plan,
        average: implementationAverage,
      };
    })
    // Sort by highest average implementation percentage
    .sort((a, b) => b.average - a.average);

  const handleBarMouseEnter = (data, index) => {
    setActiveIndex(index);
  };

  const handleBarMouseLeave = () => {
    setActiveIndex(null);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (!active) {
      return <div></div>;
    }

    return (
      <div className="tooltip">
        <Card sx={{ m: 2 }}>
          <CardHeader title={label} />
          <CardContent sx={{ pt: 2 }}>
            <Typography variant="p">Completed Percentage</Typography>
            <Typography variant="p">
              {" "}
              {` ${Math.round(payload[0].payload.average)}`}%
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  };

  function yAxis(value) {
    let per = Math.round(value);

    if (per > 0) {
      return per + "%";
    }

    return 0;
  }

  const CustomBar = (props) => {
    const { x, y, width, height, fill, payload } = props;
    const isActive = props.index === activeIndex;

    // Check if payload is defined and has elements
    const expValue =
      payload && payload.length > 0 ? payload[0].payload.exp : "";

    return (
      <g>
        {isActive && (
          <rect
            x={x}
            y={y - 20}
            rx="5"
            width={width}
            height={height}
            fill={theme.palette.primary.main}
          />
        )}

        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={isActive ? theme.palette.primary.main : fill}
        />

        {isActive && (
          <text
            x={x + width / 2}
            y={y + height + 17}
            textAnchor="middle"
            fill="#191818"
            fontSize="12"
            fontWeight="700"
          >
            {expValue}
          </text>
        )}
      </g>
    );
  };

  return (
    <JumboCardQuick reverse wrapperSx={{ pb: 0 }}>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "auto",
        }}
      >
        <Typography fontWeight={"500"} variant={"h3"}>
          Overall implementation per plan
        </Typography>
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={sortedAverages1}
            margin={{ top: 20, right: 20, bottom: 120, left: 60 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="plan"
              angle={-45}
              textAnchor="end"
              tickLine={false}
              tick={{ fontSize: 14, fill: "#999999" }}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickFormatter={yAxis}
              tick={{ fontSize: 14, fill: "#999999" }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="average"
              fill={theme.palette.primary.main}
              onMouseEnter={handleBarMouseEnter}
              onMouseLeave={handleBarMouseLeave}
              shape={<CustomBar />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </JumboCardQuick>
  );
};

export default CustomBarChart;
