import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Box } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { min_state_inst } from "app/shared/Components/institutions";
import * as yup from "yup";
import _ from "@lodash";
import axios from "axios";
import { backend_url } from "app/services/constants";
import { useFormik } from "formik";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { useNavigate } from "react-router-dom";

export default function ActualBudget({ token, dir_id }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedMinistry, setSelectedMinistry] = React.useState("");
  const [filteredMinistry, setFilteredMinistry] = React.useState([]);
  const [showErrors, setShowErrors] = React.useState(false);

  const Swal = useSwalWrapper();
  const navigate = useNavigate();

  const handleMinistryChange = (newValue) => {
    const selectedMinistry = newValue;

    const filteredMinistries = min_state_inst.filter(
      (item) => item.Ministry === selectedMinistry
    );

    setFilteredMinistry(filteredMinistries);
    setSelectedMinistry(selectedMinistry);
  };

  const ministries = _.uniqWith(
    min_state_inst.map((min) => min.Ministry),
    _.isEqual
  );

  const department =
    filteredMinistry &&
    _.uniqWith(
      filteredMinistry.map((department) => department.State_Departments),
      _.isEqual
    );

  const validationSchema = yup.object({
    source: yup.string().required("You must enter the source"),
    budget: yup.number().required("Please enter the budget."),
  });

  const defaultValues = {
    budget: 0,
    source: "",
  };

  function submit({ budget, source }) {
    const dirDetails = {
      id: dir_id,
      budget,
      source,
    };

    axios
      .put(
        `${backend_url}/ppi/budget/`,
        dirDetails,
        {
          headers: {
            // "Content-Type": "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Cost Updated successfully.",
          showConfirmButton: false,
          timer: 4500,
        });
        setLoading(false);
        handleClose();
        window.location.reload();
        // navigate("/directives");
        // trip_id === undefined ? window.location.reload() : handleNext();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: err.response.data?.details,
          showConfirmButton: false,
          timer: 4500,
        });
        setLoading(false);
      });
  }

  const formik = useFormik({
    initialValues: { defaultValues },
    validationSchema,
    onSubmit: submit,
  });

  const handleFieldErrors = () => {
    setShowErrors(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        sx={{ ml: 2, my: 2 }}
        variant="contained"
        onClick={handleClickOpen}
      >
        Add actual cost available
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Add actual cost available</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Div sx={{ mb: 2, mt: 1 }}>
              <TextField
                sx={{ mr: 2 }}
                fullWidth
                id="budget"
                name="budget"
                label="Actual Cost (KES)"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.budget}
                error={
                  (showErrors || formik.touched.budget) &&
                  Boolean(formik.errors.budget)
                }
                helperText={
                  (showErrors || formik.touched.budget) && formik.errors.budget
                }
              />
            </Div>

            <Div sx={{ mb: 2, mt: 1 }}>
              <TextField
                fullWidth
                id="source"
                name="source"
                label="Source of funds"
                multiline={3}
                minRows={2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.source}
                error={
                  (showErrors || formik.touched.source) &&
                  Boolean(formik.errors.source)
                }
                helperText={
                  (showErrors || formik.touched.source) && formik.errors.source
                }
              />
            </Div>

            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button color="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" color="secondary" variant="outlined">
                Submit
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
