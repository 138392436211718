import React from "react";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { useMediaQuery } from "@mui/material";
import ProjectsList from "app/pages/directives/ProjectsList";

const PSDashboard = ({ user, token }) => {
  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );
  return (
    // <ContactsAppProvider>
    <JumboContentLayout
      //   sidebar={<div />}
      // sidebar={<ContactsAppSidebar />}
      // header={
      //   <PageHeader
      //     title={"Directives"}
      //     subheader={"Directives assigned to you."}
      //   />
      // }
      layoutOptions={layoutOptions}
    >
      {/* {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}>
          <FolderDropdown />
          <LabelDropdown />
          <ContactFab />

          {projects.map((project, key) => (
            <ProjectItem item={project} />
          ))}
        </Stack>
      )} */}

      <ProjectsList user={user} token={token} />

      {/* {projects.map((project, key) => (
        <ProjectItem item={project} />
      ))} */}

      {/* <ContactsList /> */}
    </JumboContentLayout>
    // </ContactsAppProvider>
  );
};
export default PSDashboard;
